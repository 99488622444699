import { ChangeEvent, FC, FocusEvent, forwardRef, HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Grid, MenuItem, TextField, Typography, AlertColor, Modal, Divider, IconButton, Backdrop, Checkbox, FormControlLabel, Tooltip, CircularProgress, Paper, AutocompleteRenderInputParams } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import styles from './styles';
import clientSchema from '../../../../schemas/clientSchema';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import { API_DOMAIN, CONTAINS_CHARACTER_REGEX, CONTAINS_NUMBER_REGEX, DEFAULT_CHILD_MODAL_TEXT, DELETE, GET, PERMISSIONS, PUT, REPORT_FREQ_QUARTERLY, REPORT_FREQ_WEEKLY,} from '../../../../utility/constants';
import Toaster from '../../../toaster';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { ClientContext } from '../../../../context/clientContext';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import { ClientSettingsProps, IClientInfo, IClientSettingsFormikValues, IDues, INaicsCodes } from '../../../../interfaces/clientListInterface';
import ConfirmModal from '../../../modals/confirm-modal';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { getResponseMessage, checkUserPermissions, getLocalStorageItem, getParentClientHasDefault, getUserOptionLabel } from '../../../../utility/helper';
import { ICurrency } from '../../../../interfaces/multiCurrencyInterface';
import axiosInstance from '../../../../service/axiosInstance';
import DisabledComponentsContainer from '../../../common/disabled-components-container';
import GoToFileImport from '../../../../assets/images/GoToFileImport.svg'
import GoToIneligibleSettings from '../../../../assets/images/GoToIneligibleSettings.svg'
import WarningModal from '../warning-modal';
import GoToIneligibleSettingsDisabled from '../../../../assets/images/GoToIneligibleSettingsDisabled.svg'
import { AuthContext } from '../../../../context/authContext';
import { groupsAPI } from '../../../../service/api';
import { ISubGroup } from '../../../../interfaces/groupsInterface';

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

/**
 * This reference formats a text field to a phone format.
 */
const PhoneFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      type='tel'
      allowEmptyFormatting
    />
  );
});

/**
 * This reference formats a text field to a currency format.
 */
const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

/**
 * This reference formats a text field to a whole number format.
 */
const WholeNumberFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.value, name: other.name, }, });
      }}
      type='tel'
      decimalScale={0}
      allowNegative={false}
      isAllowed={values => values.floatValue === undefined || values.floatValue <= 9999999999}
    />
  );
});

/**
 * This reference formats a text field to the external FCN format.
 */
const ExternalFCNFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.value, name: other.name, }, });
      }}
      type='tel'
      decimalScale={0}
      allowNegative={false}
    />
  );
});

/**
 * Component for showing the contents of the clients details in the clients page.
 * @param props The props for the ClientSettings component in the clients page. See the ClientSettingsProps interface for more information.
 */
const ClientSettings: FC<ClientSettingsProps> = (props) => {
  const {
    type, 
    edit, 
    setEdit, 
    open, 
    setOpen, 
    client, 
    accountingSystems, 
    lineOfBusinesses, 
    reportFrequencies, 
    naicsCodes, 
    refreshRow,
    currencies, 
    setRefreshRow,
    updateClient
  } = props;
  const navigate                                            = useNavigate();
  const {
    dirty, setDirty,
    canUpdateClient,
    canArchiveDeleteClient,
    canViewClientSettings,
    canViewAssignedClients,
    canViewClients,
    clientInfo,
    setModalProps,
    users
  }                                                         = useContext(ClientContext);
  const { firstName, lastName }                             = useContext(AuthContext).state;
  const selectedClientContext                               = useContext(SelectedClientContext);
  const [hasUpdated, setHasUpdated]                         = useState<boolean>(false);
  const [toasterOpen, setToasterOpen]                       = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                 = useState<string>('');
  const [toasterSeverity, setToasterSeverity]               = useState<AlertColor>('success');
  const [dues, setDues]                                     = useState<IDues[]>([]);
  const [enableDues, setEnableDues]                         = useState<boolean>(true);
  const [formDirty, setFormDirty]                           = useState<boolean>(false);
  const [showPrompt, setShowPrompt]                         = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen]                       = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal]                   = useState<boolean>(false);
  const [modalTitle, setModalTitle]                         = useState<string>('');
  const [modalDescription, setModalDescription]             = useState<string>('');
  const [modalConfirmText, setModalConfirmText]             = useState<string>('');
  const [elementId, setElementId]                           = useState<string>();
  const [warningOpen, setWarningOpen]                       = useState<boolean>(false);
  const [warningDescription, setWarningDescription]         = useState<string>('');
  const [archiveWarningOpen, setArchiveWarningOpen]         = useState<boolean>(false);
  const [archiveDescription, setArchiveDescription]         = useState<string>('');
  const [archiveDeleting, setArchiveDeleting]               = useState<boolean>(false);
  const [deleting, setDeleting]                             = useState<boolean>(false);
  const [archiveDelete, setArchiveDelete]                   = useState<'archive' | 'delete'>('archive');
  const [isGroupsLoading, setIsGroupsLoading]               = useState<boolean>(false);
  const [subgroups, setSubgroups]                           = useState<ISubGroup[]>([]);
  const [selectedSubgroups, setSelectedSubgroups]           = useState<ISubGroup[]>([]);
  const [groupInput, setGroupInput]                         = useState<string>('');

  /**
   * This useEffect hook is called whenever the reporting frequency is changed in order to call the handleReportFreqChange() and getAllGroups() function.
   */
  useEffect(() => {
    if (subgroups.length < 1) getAllGroups();

    if (!client?.reportingFrequency) return;
    handleReportFreqChange(client?.reportingFrequency);
  }, []);

  /**
   * This useEffect hook is called when the component is unmounted. It sets the dirty status of the form to false.
   */
  useEffect(() => {
    return () => {setDirty(false)}
  }, []);

  /**
   * This useEffect hook is called whenever the form dirty status changes.
   */
  useEffect(() => {
    setDirty(formDirty)
  }, [formDirty]);

  /**
   * This useEffect hook retrieves and sets the element ID.
   */
  useEffect(() => {
    if(elementId){
      document.getElementById(`${elementId}`)?.focus()
      setElementId(undefined)
    }
  }, [elementId]);

  /**
   * This function returns the intial values of the client.
   * @param client The client to be displayed.
   * @returns The initial values for the client.
   */
  const getInitialValues: (client: IClientInfo) => IClientSettingsFormikValues = (client: IClientInfo) => {
    return ({
      showDueDate: !(client.reportingFrequency === 'Daily Borrowing Base' || client.reportingFrequency === 'No Borrowing Base'),
      borrowerName: client.borrowerName,
      lineLimit: client.lineLimit,
      lineOfBusiness: client.lineOfBusiness,
      reportingFrequency: client.reportingFrequency,
      naicsCode: client.naicsCode,
      clientLoanNumber: client.clientLoanNumber,
      crmName: client.crmName,
      currencyId: client.currencyId,
      dueDate: client.dueDate,
      address1: client.address1,
      address2: client.address2,
      city: client.city,
      state: client.state,
      zipCode: client.zipCode,
      country: client.country,
      clientContactName: client.clientContactName,
      clientContactEmail: client.clientContactEmail,
      clientContactPhoneNumber: client.clientContactPhoneNumber,
      accountingSystem: client?.accountingSystem?.recordId,
      parentClientFk: client?.parentClientFk,
      parentClient: client?.parentClient,
      default: client?.default,
      externalClientId: client?.externalClientId,
      externalParentId: client?.externalParentId,
      externalFCN: client?.externalFCN,
      ecid: client?.ecid
    })
  };

  /**
   * This function sets the dirty status of the form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node : any) => {
    if (node !== null) {
      if (!props.edit) {
        setFormDirty(false)
      } else {
        setFormDirty(node.dirty)
      }
    }
  };

  /**
   * This function calls the API endpoint for deleting a client.
   */
  const deleteClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/deleteParentClient`,
            method: DELETE,
            params: {parentClientFk: client.recordId}
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}`,
            method: DELETE,
          });
        }
        setRefreshRow(!refreshRow)
        setToasterMessage(`${client.borrowerName} has been deleted.`);
        setToasterSeverity('success');
        setToasterOpen(true);
        
        // if client is selected client then remove set selected client to null
        handleDeleteSelectedClient();
      } catch (e) {
        console.log(e);
        setToasterMessage(`Failed to delete ${client.borrowerName}. Please check your inputs!`);
        setToasterSeverity('error');
        setToasterOpen(true);
      }
      closeModal();
    } else {
      setToasterMessage('You do not have the necessary permissions for this action.');
      setToasterSeverity('error');
      setToasterOpen(true);
    }
  };

  /**
   * This function calls the API endpoint for archiving a client.
   */
  const archiveClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    setArchiveDeleting(true);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/updateArchiveStatusOfParent`,
            method: PUT,
            params: {parentClientFk: client.recordId, isArchive: true}
          }) 
        } else if (client.parentClientFk !== null && client.parentClientFk !== undefined) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archiveChild`,
            method: PUT
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archive`, 
            method: PUT
          })
        }
        setArchiveDeleting(false);
        setArchiveWarningOpen(false);
        setRefreshRow(!refreshRow)
        setToasterMessage(`${client.borrowerName} has been archived.`);
        setToasterSeverity('success');
        setToasterOpen(true);
        
        // if client is selected client then remove set selected client to null
        handleDeleteSelectedClient();
      } catch (e) {
        console.log(e);
        setArchiveDeleting(false);
        setArchiveWarningOpen(false);
        setToasterMessage(`Failed to archive ${client.borrowerName}. Please check your inputs!`);
        setToasterSeverity('error');
        setToasterOpen(true);
      }
      closeModal();
    } else {
      setArchiveDeleting(false);
      setArchiveWarningOpen(false);
      setToasterMessage('You do not have the necessary permissions for this action.');
      setToasterSeverity('error');
      setToasterOpen(true);
    }
  };

  /**
   * This function calls the API endpoint for restoring a client.
   */
  const restoreClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/updateArchiveStatusOfParent`,
            method: PUT,
            params: {parentClientFk: client.recordId, isArchive: false}
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archive?isArchived=false`,
            method: PUT
          });
        }
        setRefreshRow(!refreshRow)
        setToasterMessage(`${client.borrowerName} has been restored.`);
        setToasterSeverity('success');
        setToasterOpen(true);
      } catch (e) {
        console.log(e);
        setToasterMessage(`Failed to restore ${client.borrowerName}. Please check your inputs!`);
        setToasterSeverity('error');
        setToasterOpen(true);
      }
      closeModal();
    } else {
      setToasterMessage('You do not have the necessary permissions for this action.');
      setToasterSeverity('error');
      setToasterOpen(true);
    }
  };

  /**
   * This function sets the selected client to null if its the same as the deleted client of the modal
   */
  const handleDeleteSelectedClient = () => {
    const childClientIds = selectedClientContext.clients.filter(contextClients => contextClients.parentClientFk === client.recordId).map(client => client.recordId);
    (selectedClientContext.selectedClient?.recordId === client.recordId || childClientIds.includes(selectedClientContext.selectedClient?.recordId)) && selectedClientContext.setSelectedClient(null);
  }

  /**
   * This function is called whenever the reporting frequency is changed. 
   * The Due dropdown is shown whenever the reporting frequency is weekly, quarterly, or monthly.
   * @param value the new reporting frequency.
   */
  const handleReportFreqChange = (value: string) => {
    if (value === 'Weekly Borrowing Base') {
      setDues(REPORT_FREQ_WEEKLY);
      setEnableDues(true);
    } else if (value === 'Quarterly Borrowing Base') {
      setDues(REPORT_FREQ_QUARTERLY);
      setEnableDues(true);
    } else if (value === 'Monthly Borrowing Base') {
      const monthlyDues: IDues[] = [];
      for (let i = 1; i <= 31; i++) {
        monthlyDues.push({
          id: i,
          name: i.toString(),
        });
      }
      setDues(monthlyDues);
      setEnableDues(true);
    } else {
      setDues([]);
      setEnableDues(false);
    }
  };

  /**
   * This function closes the client details modal.
   */
  const handleClose = () => {
    setOpen(false);
    if (hasUpdated) {
      setRefreshRow(!refreshRow);
      setHasUpdated(false); 
    }
  };

  /**
   * This function closes the confirm navigation modal.
   */
  const handleCloseModal = () => {
    if (dirty) {
      setShowPrompt(true)
    } else {
      setOpen(false);
      setEdit(false);
    }
  };

  /**
   * This function closes the client details modal and discards all changes.
   */
  const handleDiscardChanges = () => {
    setOpen(false);
    setEdit(false);
    setShowPrompt(false);
    setFormDirty(false);
  };

  const handleSubmit = async (values: IClientSettingsFormikValues, action: FormikHelpers<IClientSettingsFormikValues>) => {
    if(((values.parentClientFk === null || values.parentClientFk === undefined ) && typeof client.parentClientFk === 'number') || ((client.parentClientFk === null || client.parentClientFk === undefined) && typeof values.parentClientFk === 'number')) {
      if ((values.parentClientFk === null || values.parentClientFk === undefined) && typeof client.parentClientFk === 'number') setWarningDescription('You are about to remove a child client, and this action will result in the loss of all interconnected data. Are you sure you want to proceed?');
      if ((client.parentClientFk === null || client.parentClientFk === undefined) && typeof values.parentClientFk === 'number') setWarningDescription('You are about to associate this client to a parent client, and this action will result in the loss of all existing transactions. Are you sure you want to proceed?');
      setWarningOpen(true)
    } else if (values.parentClient === true && client.parentClient === false){
      setWarningDescription('You are about to convert this client into a Parent Client, and this action will result in the lost of all interconnected data. Are you sure you want to proceed?.');
      setWarningOpen(true);
    } else {
      await saveClient(values, action);
    }
  }

  /**
   * This function handles the saving of the updated details of the client.
   * @param values The updated values of the client.
   * @param action The variable containing the formik helpers for the form. 
   */
  const saveClient = async (values: IClientSettingsFormikValues, action: FormikHelpers<IClientSettingsFormikValues>) => {
    const { setSubmitting } = action;
    const dropdownAcctSys = document.getElementById(
      'cboAccountingSystem'
    );

    let updatedInfo = {
      recordId: client.recordId,
      borrowerName: values.borrowerName.trim(),
      lineLimit: values.lineLimit,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      country: values.country,
      clientContactName: values.clientContactName,
      clientContactEmail: values.clientContactEmail,
      clientContactPhoneNumber: values.clientContactPhoneNumber,
      crmName: values.crmName,
      currencyId: values.currencyId, 
      dueDate: values.dueDate,
      lineOfBusiness: values.lineOfBusiness,
      reportingFrequency: values.reportingFrequency,
      naicsCode: values.naicsCode,
      clientLoanNumber: values.clientLoanNumber,
      parentClient: values.parentClient,
      parentClientFk: values.parentClientFk,
      visible: true,
      default: values.parentClientFk ? values.default : false,
      externalClientId: values.externalClientId,
      externalParentId: values.externalParentId,
      externalFCN: values.externalFCN,
      ecid: values.ecid,
      groupIds: selectedSubgroups.map(group => group.id).toString()
    };

    //default payload is no accountingSystem, if user supply it, it will add to the payload
    if(!isNaN(values.accountingSystem)) {
      Object.assign(updatedInfo,updatedInfo,{accountingSystem :{
        recordId: values.accountingSystem,
        accountingSystemName: dropdownAcctSys?.innerHTML
      }})
    }
    //end of modifying payload

    const canUpdate = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_CLIENT);

    setDeleting(true);

    if (canUpdate) {
      try {
        const response = await axiosInstance.request({
          url: `${API_DOMAIN}/borrowers/${client.recordId}`,
          method: PUT,
          data: updatedInfo
        });

        setHasUpdated(true);
        setToasterOpen(true);
        updateClient(updatedInfo);
        setToasterSeverity('success');
        setEdit(false);
        setSubmitting(false);
        setDeleting(false);
        setWarningOpen(false);
        setToasterMessage(getResponseMessage(response.status, 'Client', response.data.borrowerName));
      } catch (error : any) {
        setHasUpdated(false);
        setToasterOpen(true);
        if (error.response.status === 409) {
          setToasterMessage(`Client name already exists, try a different one.`);
        } else {
          setToasterMessage(`Failed to save ${client.borrowerName}. Please check your inputs.`);
        }
        setToasterSeverity('error');
        setSubmitting(false);
        setDeleting(false);
        setWarningOpen(false);
      }
    } else {
      setToasterOpen(true);
      setToasterMessage(
        'You do not have the necessary permissions for this action.'
      );
      setToasterSeverity('error');
      setSubmitting(false);
      setDeleting(false);
      setWarningOpen(false);
    }
    
  }

  /**
   * This function opens the confirm archive modal when the archive button is clicked.
   */
  const onArchiveClick = () => {
    if (client.parentClientFk !== null && client.parentClientFk !== undefined) {
      setArchiveDescription('You are about to archive a child client, and this action will result in the loss of all interconnected data. Are you sure you want to proceed?');
      setArchiveDelete('archive');
      setArchiveWarningOpen(true);
    } else if (client.parentClient === true) {
      setArchiveDescription(`You are about to archive ${client.borrowerName}, and this action will result in the archiving of all child clients associated with it and the loss of interconnected data of each client. Are you sure you want to proceed?`);
      setArchiveDelete('archive');
      setArchiveWarningOpen(true);
    } else {
      setModalTitle(`Archive ${client.borrowerName}`);
      setModalDescription(`Are you sure you want to archive the ${client.borrowerName} Client? This action will result in archiving all collateral under this Client.`);
      setModalConfirmText('Archive')
      setIsDeleteModal(false)
      setIsModalOpen(true)
    }
  }

  /**
   * This function opens the confirm restore modal when the restore button is clicked.
   */
  const onRestoreClick = () => {
    setModalTitle(`Restore ${client.borrowerName}`);
    if (client.parentClient === true) {
      setModalDescription(`You are about to restore the ${client.borrowerName} Client, which will result in the restoring of all clients and their collateral under this Parent. Are you sure about this action?`)
    } else {
      setModalDescription(`Are you sure you want to restore the ${client.borrowerName} Client? This action will result in restoring all collateral under this Client.`);
    }
    setModalConfirmText('Restore')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  /**
   * This function opens the confirm delete modal when the delete button is clicked.
   */
  const onDeleteClick = () => {
    if (client.parentClient === true) {
      setArchiveDescription(`You are about to delete ${client.borrowerName}, and this action will result in the deletion of all child clients that are associated with it. Are you sure you want to proceed?`);
      setArchiveDelete('delete');
      setArchiveWarningOpen(true);
    } else {
      setModalTitle(`Delete ${client.borrowerName}`);
      setModalDescription(`Are you sure you want to delete the ${client.borrowerName} Client? This action will result in deleting all collateral under this Client.`);
      setModalConfirmText('Delete')
      setIsDeleteModal(true)
      setIsModalOpen(true)
    }
  }

  /**
   * This function closes the confirm delete/archive/restore modal.
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  /**
   * This function is called whenever the confirm delete/archive/restore is clicked.
   * @returns The delete, archive, or restore function.
   */
  const handleConfirm = () => {
    if (modalConfirmText === 'Archive') {
      return archiveClient();
    } else if (modalConfirmText === 'Restore') {
      return restoreClient();
    } else {
      return deleteClient();
    }
  }

  /**
   * This function is called whenever the confirm delete/archive/restore for the warning modal is clicked.
   * @returns The delete, or archive function.
   */
  const handleConfirmWarning = () => {
    if (archiveDelete === 'archive') {
      return archiveClient();
    }  else {
      return deleteClient();
    }
  }

  /**
   * This function navigates the user to the client settings page of the selected client.
   */
  const selectClient = () => {
    selectedClientContext?.setSelectedClient(client);
    navigate(`/clients/${client.recordId}/settings`);
  }
  /**
   * This function sets the editing status of the form to true.
   * @param id the ID of the element.
   */

  const isEditing = (id: string) => {
    if (!edit && type === 'active') {
      setEdit(true);
      setElementId(id);
    }
  }

  /**
   * This function is called whenever the reporting frequency dropdown value is changed.
   * @param formik The formik object of the form.
   */
  const handleFormikReportFreqChange = (formik: FormikProps<IClientSettingsFormikValues>) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { values, setFieldValue, handleChange } = formik;
    
    const reportingFrequency: string = e.target.value;
    reportingFrequency !== values.reportingFrequency && setFieldValue('dueDate', '');

    if ( reportingFrequency === 'Daily Borrowing Base' || reportingFrequency === 'No Borrowing Base') {
      setFieldValue('showDueDate', false);
    } else {
      setFieldValue('showDueDate', true);
    }

    handleReportFreqChange(reportingFrequency);
    handleChange(e);
  }

  /**
   * This function is valled whenever the values of the fields are called and integrates the regex filter for the new value inputted.
   * @param formik The formik object of the form.
   * @param name The name of the field.
   */
  const handleChangeFormikWithRegex = (formik: FormikProps<IClientSettingsFormikValues>, name: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { handleChange } = formik;

    const stringFields = ['city', 'state', 'country', 'clientContactName'];
    const regexToBeUsed = stringFields.includes(name) ? CONTAINS_CHARACTER_REGEX : CONTAINS_NUMBER_REGEX;

    (regexToBeUsed.test(e?.target?.value) || e?.target?.value === '') && handleChange(e);
  }

  const HelperTextComponent = ({ text }) => {
    const helperTextRef = useRef<HTMLParagraphElement>(null);
  
    useEffect(() => {
      if (text && helperTextRef.current) {
        helperTextRef.current.focus();
      }
    }, [text]);
  
    return <Box tabIndex={0} ref={helperTextRef}>{text}</Box>;
  };

  /**
   * This function retrieves the formik props for the field.
   * @param formik The formik object of the form.
   * @param name The name of the field.
   * @returns The props for the formik field.
   */
  const getFormikProps = (formik: FormikProps<IClientSettingsFormikValues>, name: string) => {
    const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = formik;

    const fieldsToTrim = ['borrowerName', 'address1', 'address2', 'city', 'state', 'country', 'clientContactName', 'clientContactEmail'];
    const fieldsWithCustomValues = ['naicsCode', 'currencyId'];
    const fieldsWithCustomChange = ['naicsCode', 'reportingFrequency', 'clientLoanNumber', 'currencyId', 'city', 'state', 'zipCode', 'country', 'clientContactName'];
    
    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if (fieldsToTrim.includes(name)) {
        setFieldValue(name, values[name].trim());
        setTimeout(() => {
          setFieldTouched(name, true);
        }, 0);
      }
      handleBlur(e);
    }

    return ({
      name,
      value: !fieldsWithCustomValues.includes(name) && values[name],
      onChange: fieldsWithCustomChange.includes(name) ? undefined : handleChange,
      error: touched[name] && Boolean(errors[name]),
      helperText: touched[name] ? (
        <HelperTextComponent text={errors[name]} />
      ) : ( ''),
      onBlur: onBlur,
    })
  }
  
  /**
   * This function retrieves the default dropdown value.
   * @param formik The formik object of the form.
   * @param name The name of the field.
   * @param items The values for the field.
   * @param field The field of the dropdown.
   * @returns The default value for the dropdown.
   */
  const getDropdownDefaultValue = (formik: FormikProps<IClientSettingsFormikValues>, name: string, items: any[], field?: string) => {
    const targetValue = String(formik.values[name]);
    let item;
    if (name === 'naicsCode' || name === 'parentClientFk') {
      item = items.find(item => (name === 'naicsCode' ? item.code === parseInt(targetValue) : item.recordId === parseInt(targetValue)));
    } else if (name === 'crmName') {
      item = targetValue;
    } else {
      item = field ? items.find(item => String(item[field]) === targetValue) : items.find(item => item === targetValue);
    }
    return item ?? null;
  }

  /**
   * This function renders the archive or delete button depending on the client.
   * @returns The archive or delete button.
   */
  const getArchiveOrDeleteButton = () => {
    if (canArchiveDeleteClient) {
      return (
        <Button
          aria-label={client.canDelete ? 'Delete icon' : 'Archive icon'}
          data-testid={client.canDelete ? 'delete-icon' : 'archive-icon'}
          onClick={client.canDelete ? onDeleteClick : onArchiveClick}
          sx={styles.button}
        >
          {client.canDelete ? 
            <DeleteOutlineOutlinedIcon sx={{...styles.iconColor}} /> : 
            <Inventory2OutlinedIcon sx={{...styles.iconColor}} />
          }
          <Typography sx={{...styles.buttonText}}>
            {client.canDelete ? 'Delete' : 'Archive'}
          </Typography>
        </Button>
      )
    }
  }
  
  /**
   * This function handles the changing of the value of the checkbox that dictates whether the client is a parent or not.
   * @param checked The value of the checkbox
   * @param formik The formik entity
   */
  const handleParentClientCheckBox = (checked: boolean, formik: FormikProps<IClientSettingsFormikValues>) => {
    formik.setFieldValue('parentClient', checked);
  }

  /**
   * This function handles the changing of the parent client field
   * @param value The value of the parent client field
   * @param formik The formik entity
   */
  const handleChangeParentClientField = (value: any, formik: FormikProps<IClientSettingsFormikValues>) => {
    formik.setFieldValue('default', value?.parentHasDefault ? false : true);
    formik.setFieldValue('parentClientFk', value?.recordId ?? null)
  }

  /**
   * This funciton checks if the current parent client has children.
   * @returns A boolean value which is true if the parent has children
   */
  const checkIfHasChildren = () => {
    const children = clientInfo.some(clientInfo => clientInfo.parentClientFk === client.recordId);
    return children;
  }

  /**
   * This function navigates the user to the file import page of the selected client.
   */
  const navigateFileImport = () => {
    selectedClientContext?.setSelectedClient(client);
    setTimeout(()=>{navigate(`/file-import`)}, 1);
  }

  /** 
   * This function navigates the user to the ineligible settings page of the selected client.
   */
  const navigateIneligibleSettings = () => {
    selectedClientContext?.setSelectedClient(client);
    navigate(`/ineligible-settings`);
  }

  /**
   * This function defines the paper component used for rendering dropdowns.
   *
   * @param props The props for the paper component.
   * @returns The paper component wrapped around the provided children.
   */
  const paperComponent = useCallback((props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdown}>{props.children}</Paper>), []);

  const getAllGroups = async () => {
    setIsGroupsLoading(true);
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: groupsAPI.ALL_GROUPS,
        method: GET,
        headers: {
          token: token !== undefined ? token : ''
        },
        params: { pageNo: 0, pageSize: 9999 }
      })
      const subgroupList: ISubGroup[] = response.data.content;
      
      // set selected groups from client object groupIds
      const filteredSubgroups = subgroupList.filter(group => client.groupIds?.includes(group.id));
      setSelectedSubgroups([...filteredSubgroups]);

      setSubgroups(subgroupList);
    } catch (e) {
      console.log(e)
      return [];
    } finally {
      setIsGroupsLoading(false);
    }
  }

  /**
    * This function defines the rendering of the dropdown input component.
    *
    * @param type The type of the dropdown.
    * @returns Object with renderInput function.
    */
  const getRenderInput = () => {
    return {
      renderInput: (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          placeholder={users.length > 0 ? undefined : 'Please Select'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isGroupsLoading ?
                  <CircularProgress color='inherit' size={15} sx={styles.loadingIcon} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )
    }
  };

  /**
   * This function constructs event handlers for change and input events in multiple-select dropdown components.
   *
   * @returns Object with event handler functions.
   */
  const getMultipleChange = () => {
    return {
      onChange: (_: SyntheticEvent<Element, Event>, newVal: readonly ISubGroup[]) => setSelectedSubgroups([...newVal]),
      onInputChange: (_: SyntheticEvent<Element, Event>, newInput: string) => setGroupInput(newInput)
    }
  };

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <WarningModal
        open={archiveWarningOpen}
        onClose={() => {setArchiveWarningOpen(false)}}
        onConfirm={() => {handleConfirmWarning()}}
        description={archiveDescription}
        isDeleting={archiveDeleting}
      />
      <ConfirmModal
        title={modalTitle}
        description={modalDescription}
        open={isModalOpen}
        alignment="left"
        onClose={closeModal}
        onConfirm={handleConfirm}
        errorButton={isDeleteModal}
        noButtonText={'Cancel'}
        yesButtonText={modalConfirmText}
      />
      <ConfirmModal
        open={showPrompt}
        onConfirm={() => setShowPrompt(false)}
        onClose={handleDiscardChanges}
        onButtonClose={() => setShowPrompt(false)}
        promptChecker={true}
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText="Keep Editing"
        noButtonText="Discard Changes"
        confirmOnly
      />
      <Backdrop
        open={open}
        onClick={handleCloseModal} 
      />
      <Draggable
        handle='#draggable-dialog-title'
        cancel={'[class*="MuiDialogContent-root"]'}
        bounds={{ top: -100, left: -400, right: 400, bottom: 100 }}
      >
        <Modal
          sx={{display: !open ?  'none' : undefined }}
          open={open}
          onClose={handleCloseModal}
          aria-label='modal-modal-title'
          disableEscapeKeyDown
          BackdropProps={{invisible:true}}
        >
          <Grid container sx={styles.modal} xs={12}>
            <Grid item xs={12} sx={styles.modalTitleContainer} id='draggable-dialog-title'>
              <Typography tabIndex={0} id='modal-modal-title' variant='h6' component='h2' sx={styles.modalTitle}>
                {edit ? 'Edit Client' : 'View Client'}
              </Typography>
             <Box sx={{display: 'flex'}}>
                {
                  (type === 'active') ?
                  (
                    canUpdateClient &&
                    <Box sx={{display: 'flex'}}>
                      <Tooltip title={'Go To File Import'}>
                        <span>
                          <Button
                            aria-label='Go To File Import icon'
                            data-testid='go-to-file-import-icon'
                            onClick={navigateFileImport}
                            sx={styles.button}
                          >
                            <img
                              height={20}
                              src={GoToFileImport}
                              alt="go-to-file-import-icon"
                              aria-label='Go To File Import'
                            />
                          </Button>
                        </span>
                      </Tooltip>
                      <Divider variant="middle" sx={{...styles.divider}}/>
                      <Tooltip title={(client.parentClientFk !== null && client.parentClientFk !== undefined) ? 'Not Applicable for Child Clients' : 'Go To Ineligible Settings'}>
                        <span>
                          <Button
                            aria-label='Go To Ineligible Settings icon'
                            data-testid='go-to-ineligible-settings-icon'
                            onClick={navigateIneligibleSettings}
                            disabled={client.parentClientFk !== null && client.parentClientFk !== undefined}
                            sx={styles.button}
                          >
                            <img
                              height={20}
                              src={(client.parentClientFk !== null && client.parentClientFk !== undefined) ? GoToIneligibleSettingsDisabled : GoToIneligibleSettings}
                              alt="go-to-ineligible-settings-icon"
                              aria-label='Go To Ineligible Settings'
                            />
                          </Button>
                        </span>
                      </Tooltip>
                      <Divider variant="middle" sx={{...styles.divider}}/>
                      {
                        canViewClientSettings &&
                        <Tooltip title='Go To Client Settings'>
                          <Button
                            aria-label='Client Settings icon'
                            data-testid='client-settings-icon'
                            onClick={selectClient}
                            sx={styles.button}
                          >
                            <SettingsIcon sx={styles.iconColor} />
                          </Button>
                        </Tooltip>
                      }
                      <Divider variant="middle" sx={{...styles.divider}}/>
                      <DisabledComponentsContainer isDisabled={edit}>
                        <Button
                          data-testid='edit-icon'
                          onClick={()=>{setEdit(true)}}
                          disabled={edit}
                          aria-label={edit ? 'Edit icon button disabled' : 'Edit icon'}
                          sx={styles.button}
                        >
                          <EditOutlinedIcon sx={{...styles.iconColor,...(edit && styles.disabledColor)}} />
                          <Typography sx={{...styles.buttonText,...(edit && styles.disabledColor)}} >
                            Edit
                          </Typography>
                        </Button>
                      </DisabledComponentsContainer>
                      <Divider variant="middle" sx={{...(!canViewClientSettings && styles.hidden), ...styles.divider}}/>
                      {getArchiveOrDeleteButton()}
                    </Box>
                  ) : (
                    <Box sx={{display: 'flex'}}>
                      {canArchiveDeleteClient && (
                        <Button
                          aria-label='Restore icon'
                          data-testid='restore-icon'
                          onClick={onRestoreClick}
                        >
                          <RestoreOutlinedIcon sx={{...styles.iconColor}} />
                          <Typography sx={{...styles.buttonText}}>
                            Restore
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  )
                }
                <IconButton onClick={handleCloseModal}  sx={styles.closeIcon} aria-label='Close icon' data-testid='close-modal-button'>
                  <CloseIcon/>
                </IconButton>
             </Box>
            </Grid>
            <Grid xs={12}>
              <Formik
                enableReinitialize
                initialValues={getInitialValues(client)}
                validationSchema={clientSchema}
                innerRef={formikRef}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Grid container sx={styles.mainSetting}>
                      <Grid container xs={12} md={5} sx={{justifyContent: 'center'}}>
                        <Grid item direction='column' sx={styles.columnContainer}>
                          {/* Parent Client Checkbox */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={{...styles.label}}>
                                Parent Client
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Checkbox 
                                checked={formik.values.parentClient}
                                inputProps={{'aria-label': 'parent-client-checkbox'}}
                                onChange={(event, checked) => handleParentClientCheckBox(checked, formik)} 
                                disabled={(formik.values.parentClientFk !== null && formik.values.parentClientFk !== undefined) || !edit || checkIfHasChildren()} 
                                style={styles.checkBox}
                              />
                            </Grid>
                          </Grid>
                          {/*Parent Client*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={{...styles.label}}>
                                Parent Name
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                disabled={formik.values.parentClient || !edit}
                                size='small'
                                options={clientInfo.filter(singleClient => (singleClient.parentClient && singleClient.borrowerName !== client.borrowerName))}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                getOptionLabel={(client) => `${client.borrowerName}`}
                                noOptionsText={'No available Client'}
                                value={getDropdownDefaultValue(formik, 'parentClientFk', clientInfo, 'recordId')}
                                onChange={(e, value) => {handleChangeParentClientField(value, formik);}}
                                onBlur={formik.handleBlur}
                                renderOption={(prop, option: IClientInfo) => {
                                  return (
                                    <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownOptionsFontSize}>
                                      {option.borrowerName}
                                    </Box>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name='parentClientFk'
                                    inputProps={{...params.inputProps, 'data-testid': 'parent-client', 'aria-label':'Parent Client Field'}}
                                    onBlur={formik.handleBlur}
                                    placeholder={formik.values.parentClient ? '' : 'Please Select'}
                                    sx={{...styles.autocompleteField}}
                                    variant="outlined"
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* Client Name */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Client Name<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='client-name-field'
                                variant='outlined'
                                size='small'
                                sx={styles.field}
                                disabled={!edit}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'borrowerName')}
                                inputProps={{'aria-label' : 'Client Name Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* Default Client CheckBox */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeightClients, ...styles.paddingBottom}}
                          >
                            <Grid item xs={12} sx={styles.checkBoxGrid}>
                                <FormControlLabel 
                                  control={
                                    <Checkbox 
                                      size='small'
                                      inputProps={{'aria-label': 'default-client-checkbox'}}
                                      onChange={(event, checked) => {
                                        // check if parent client already has default child
                                        if (getParentClientHasDefault(clientInfo, formik) && checked) {
                                          setModalProps({
                                            open: true,
                                            description: DEFAULT_CHILD_MODAL_TEXT.description,
                                            title: DEFAULT_CHILD_MODAL_TEXT.title,
                                            confirmText: DEFAULT_CHILD_MODAL_TEXT.confirmText,
                                            handleConfirm: () => {
                                              formik.setFieldValue('default', checked);
                                            }
                                          });
                                          return;
                                        }
                                        formik.setFieldValue('default', checked);
                                      }}
                                      // disable checkbox when client being edited is default and when parent has no default children, 
                                      disabled={!edit || !getParentClientHasDefault(clientInfo, formik) || (client.default && formik.values.default) || formik.values.parentClient || (formik.values.parentClientFk === null || formik.values.parentClientFk === undefined)}
                                      checked={formik.values.default}
                                      style={styles.checkBox}
                                    />
                                    } 
                                  label="Set as default" 
                                  sx={styles.checkBoxLabel}
                                />
                              </Grid>
                          </Grid>
                          {/* Accounting System */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label} id='AccountingSystemField'>
                                Accounting System
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                select
                                tabIndex={edit ? undefined : 0}
                                id='accounting-system-dropdown'
                                inputProps={{ 'aria-labelledby' : 'AccountingSystemField', displayEmpty: true, 'aria-label' : 'AccountingSystemField' }}
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...(formik.values.accountingSystem === undefined && styles.placeholder)
                                }}
                                {...getFormikProps(formik, 'accountingSystem')}
                              >
                                {formik.values.accountingSystem === undefined &&
                                  <MenuItem value={undefined} sx={styles.hidden}>
                                    Please Select
                                  </MenuItem>
                                }
                                {accountingSystems.map((act, index) => (
                                  <MenuItem key={act.recordId} value={act.recordId}>
                                    {act.accountingSystemName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          {/* Line Limit */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Line Limit<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='cross-aging-percent'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                fullWidth
                                {...getFormikProps(formik, 'lineLimit')}
                                sx={{ ...styles.field, ...styles.textfieldCurrency }}
                                InputProps={{ 
                                  inputComponent: CurrencyFormat as any,
                                }}
                                inputProps={{
                                  'aria-label': 'Line Limit',
                                }}
                              />
                            </Grid>
                          </Grid>
                          {/* Line of Business */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} id='LineBusinessField' sx={styles.label}>
                                Line of Business
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                select
                                tabIndex={edit ? undefined : 0}
                                id='ineligible-description'
                                inputProps={{ 'aria-labelledby' : 'LineBusinessField', displayEmpty:true, 'aria-label' : 'LineBusinessField'  }}
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...(formik.values.lineOfBusiness === '' && styles.placeholder)
                                }}
                                {...getFormikProps(formik, 'lineOfBusiness')}
                              >
                                {formik.values.lineOfBusiness === '' &&
                                  <MenuItem value='' sx={styles.hidden}>
                                    Please Select
                                  </MenuItem>
                                }
                                {lineOfBusinesses.map((biz) => (
                                  <MenuItem key={biz.id} value={biz.lineOfBusinessName} sx={styles.dropdownOptionsFontSize}>
                                    {biz.lineOfBusinessName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>     
                          </Grid>
                          {/* Report Frequency */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label} id='ReportFrequencyField'>
                                Report Frequency
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                select
                                tabIndex={edit ? undefined : 0}
                                id='ineligible-description-frequency'
                                inputProps={{ 'data-testid': 'reporting-frequency', 'aria-labelledby': 'ReportFrequencyField', displayEmpty: true, 'aria-label' : 'ReportFrequencyField'  }}
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...(formik.values.reportingFrequency === '' && styles.placeholder)
                                }}
                                {...getFormikProps(formik, 'reportingFrequency')}
                                onChange={handleFormikReportFreqChange(formik)}
                              >
                                {formik.values.reportingFrequency === '' &&
                                  <MenuItem value='' sx={styles.hidden}>
                                    Please Select
                                  </MenuItem>
                                }
                                {reportFrequencies.map((freq) => (
                                  <MenuItem key={freq.id} value={freq.reportFrequencyName} sx={styles.dropdownOptionsFontSize} aria-label={freq.reportFrequencyName}>
                                    {freq.reportFrequencyName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          {/* NAICS Code  */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                NAICS Code
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                disablePortal
                                tabIndex={edit ? undefined : 0}
                                size='small'
                                disabled={!edit}
                                options={naicsCodes}
                                sx={styles.field}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                value={getDropdownDefaultValue(formik, 'naicsCode', naicsCodes, 'recordId')}
                                getOptionLabel={(naics) => `${naics.code} - ${naics.title}`}
                                noOptionsText={'No available NAICS Code'}
                                renderOption={(prop, option: INaicsCodes) => {
                                  return (
                                    <Box component='li' {...prop} key={option.code} sx={styles.dropdownOptionsFontSize}>
                                      {option.title}
                                    </Box>
                                  );
                                }}
                                onChange={(e, value) => formik.setFieldValue('naicsCode', value?.code ?? null)}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{...params.inputProps, 'data-testid': 'naics-code', 'aria-label':'NAICS Code Field'}} 
                                    name='naicsCode'
                                    error={
                                      formik.touched.naicsCode &&
                                      Boolean(formik.errors.naicsCode)
                                    }
                                    helperText={
                                      formik.touched.naicsCode && formik.errors.naicsCode
                                    }
                                    placeholder={'Please Select'}
                                    sx={{...styles.autocompleteField}}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* Client Loan Number */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Client Loan Number
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                type="number"
                                id='txtLoanNumber'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.textfieldNumber}}
                                {...getFormikProps(formik, 'clientLoanNumber')}
                                onChange={handleChangeFormikWithRegex(formik, 'clientLoanNumber')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                InputProps={{inputComponent: WholeNumberFormat as any}}
                                inputProps={{'aria-label' : 'Client Loan Number Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* User Name */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                User Name
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                disablePortal
                                tabIndex={edit ? undefined : 0}
                                size='small'
                                disabled={!edit || (canViewAssignedClients && !canViewClients)}
                                options={users}
                                sx={styles.field}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                defaultValue={getDropdownDefaultValue(formik, 'crmName', users)}
                                getOptionLabel={(user) => `${user}`}
                                noOptionsText={'User Not Found'}
                                renderOption={(prop, option: string) => {
                                  return (
                                    <Box component='li' {...prop} key={option} sx={styles.dropdownOptionsFontSize}>
                                      {option}
                                    </Box>
                                  );
                                }}
                                onChange={(_e, value) => formik.setFieldValue('crmName', value ?? '')}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{...params.inputProps, 'aria-label':'CRM Name Field', 'data-testid': 'user-name'}} 
                                    onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                    name='crmName'
                                    error={
                                      formik.touched.crmName &&
                                      Boolean(formik.errors.crmName)
                                    }
                                    value={formik.values.crmName}
                                    helperText={
                                      formik.touched.crmName && formik.errors.crmName
                                    }
                                    placeholder={'Please Select'}
                                    sx={{...styles.autocompleteField}}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          {(enableDues && formik.values.reportingFrequency !== '') && (
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                              <Grid item xs={4}>
                                <Typography tabIndex={0} sx={styles.label} id="DueDateField">
                                  Due
                                </Typography>
                              </Grid>
                              <TextField
                                select
                                tabIndex={edit ? undefined : 0}
                                id='overdue-days'
                                inputProps={{ 'aria-labelledby': 'DueDateField', displayEmpty: true, 'aria-label' : 'DueDateField'  }}
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...styles.paddingLeft,
                                  ...(formik.values.dueDate === '' && styles.placeholder)
                                }}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'dueDate')}
                              >
                                {formik.values.dueDate === '' &&
                                <MenuItem value='' sx={styles.hidden}>
                                  Please Select
                                </MenuItem>
                              }
                                {dues.map((due) => (
                                  <MenuItem key={due.id} value={due.name}>
                                    {due.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          )}
                          {/*External Client ID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                External Client ID<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='externalClientId'
                                variant='outlined'
                                size='small'
                                sx={styles.field}
                                disabled={!edit}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'externalClientId')}
                                inputProps={{ 'data-testid': 'external-client-id',
                                              'aria-label' : 'External Client ID'}}
                              />
                            </Grid>
                          </Grid>
                          {/*External Parent ID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                External Parent ID
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='externalParentId'
                                variant='outlined'
                                size='small'
                                sx={styles.field}
                                disabled={!edit}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'externalParentId')}
                                inputProps={{ 'data-testid': 'external-parent-id',
                                              'aria-label' : 'External Parent ID'}}
                              />
                            </Grid>
                          </Grid>
                          {/*External FCN*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                External FCN
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='externalFCN'
                                variant='outlined'
                                size='small'
                                sx={styles.field}
                                disabled={!edit}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'externalFCN')}
                                inputProps={{ 'data-testid': 'external-fcn',
                                              'aria-label' : 'External FCN'}}
                                InputProps={{inputComponent: ExternalFCNFormat as any}}
                              />
                            </Grid>
                          </Grid>
                          {/*ECID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                ECID
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='ecid'
                                variant='outlined'
                                size='small'
                                sx={styles.field}
                                disabled={!edit}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'ecid')}
                                inputProps={{ 'data-testid': 'ecid',
                                              'aria-label' : 'ECID'}}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={7} sx={{justifyContent: 'center'}}>
                        <Grid item sx={styles.columnContainer}>
                        <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                        </Grid>
                          {/* Currency */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Currency<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                tabIndex={edit ? undefined : 0}
                                size='small'
                                disabled={!edit}
                                options={currencies}
                                sx={styles.field}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                value={getDropdownDefaultValue(formik, 'currencyId', currencies, 'recordId')}
                                getOptionLabel={(option) => `${option.currencyCode} - ${option.currencyName}`}
                                noOptionsText={'No available currencies'}
                                renderOption={(prop, option: ICurrency) => {
                                  return (
                                    <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownOptionsFontSize}>
                                      {option.currencyCode} - {option.currencyName}
                                    </Box>
                                  );
                                }}
                                onChange={(e, value) => formik.setFieldValue('currencyId', value !== null ? value.recordId.toString() : '')}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{...params.inputProps, 'aria-label':'Currency Field'}}
                                    name='currencyId'
                                    error={
                                      formik.touched.currencyId &&
                                      Boolean(formik.errors.currencyId)
                                    }
                                    helperText={
                                      formik.touched.currencyId && formik.errors.currencyId
                                    }
                                    placeholder={'Please Select'}
                                    sx={{...styles.autocompleteField, ...styles.field2}}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* Address 1 */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Address 1
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='address1'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                inputProps={{'aria-label' : 'Address 1 Field'}}
                                {...getFormikProps(formik, 'address1')}
                              />
                            </Grid>
                          </Grid>
                          {/* Address 2 */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Address 2
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='address2'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'address2')}
                                inputProps={{'aria-label' : 'Address 2 Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* City */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                City
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='city'
                                variant='outlined'
                                size='small'
                                fullWidth
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                {...getFormikProps(formik, 'city')}
                                onChange={handleChangeFormikWithRegex(formik, 'city')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                inputProps={{'aria-label' : 'City Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* State & Zip Code */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction='row'
                                alignItems='center'
                                columnSpacing={1}
                                sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                              >
                                <Grid item xs={12}>
                                  <Typography tabIndex={0} sx={styles.label}>
                                    State
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={8} sx={styles.stateFields}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='state'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...styles.fieldWidthState,
                                }}
                                {...getFormikProps(formik, 'state')}
                                onChange={handleChangeFormikWithRegex(formik, 'state')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                inputProps={{'aria-label' : 'State Field'}}
                              />
                              <Typography tabIndex={0} sx={{...styles.label, ...{paddingLeft: '14px', paddingRight: '14px'}}}>
                                Zip Code
                              </Typography>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='zip-code'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{
                                  ...styles.field,
                                  ...styles.fieldWidthZipCode,
                                }}
                                {...getFormikProps(formik, 'zipCode')}
                                onChange={handleChangeFormikWithRegex(formik, 'zipCode')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                inputProps={{'aria-label' : 'Zip Code Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* Country */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Country
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='country'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                {...getFormikProps(formik, 'country')}
                                onChange={handleChangeFormikWithRegex(formik, 'country')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                inputProps={{'aria-label' : 'Country Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* Contact Name */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Contact Name
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            <TextField
                              tabIndex={edit ? undefined : 0}
                              id='contactName'
                              variant='outlined'
                              size='small'
                              disabled={!edit}
                              sx={{...styles.field, ...styles.field2}}
                              {...getFormikProps(formik, 'clientContactName')}
                              onChange={handleChangeFormikWithRegex(formik, 'clientContactName')}
                              onClick={(e) => isEditing((e.target as HTMLElement).id)}
                              inputProps={{'aria-label' : 'Contact Name Field'}}
                            />
                            </Grid>
                          </Grid>
                          {/* Email */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='email'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                {...getFormikProps(formik, 'clientContactEmail')}
                                inputProps={{'aria-label' : 'Email Field'}}
                              />
                            </Grid>
                          </Grid>
                          {/* Phone */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Phone
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                tabIndex={edit ? undefined : 0}
                                id='phone'
                                variant='outlined'
                                size='small'
                                disabled={!edit}
                                sx={{...styles.field, ...styles.field2}}
                                {...getFormikProps(formik, 'clientContactPhoneNumber')}
                                onClick={(e) => isEditing((e.target as HTMLElement).id)}
                                InputProps={{inputComponent: PhoneFormat as any}}
                                inputProps={{'aria-label' : 'Phone Field'}}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.label}>
                                Groups
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                multiple
                                id={`group-dropdown`}
                                disablePortal
                                disabled={!edit}
                                getOptionLabel={(option: ISubGroup) => option.name}
                                disableCloseOnSelect
                                isOptionEqualToValue={(option: ISubGroup, value: ISubGroup) => option.id === value.id}
                                options={subgroups}
                                value={selectedSubgroups}
                                loading={isGroupsLoading}
                                inputValue={groupInput}
                                {...getRenderInput()}
                                PaperComponent={paperComponent}
                                size='small'
                                componentsProps={{
                                  popupIndicator: { 'aria-label': 'Dropdown icon', tabIndex: 0 },
                                  clearIndicator: { 'aria-label': 'Clear icon', tabIndex: 0 }
                                }
                                }
                                {...getMultipleChange()}
                                sx={styles.dropdown}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid> 
                      
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                      {edit && open ? (
                        <Box sx={styles.buttonGroup}>
                          <Button variant='outlined' type='reset' sx={styles.cancelButton}
                            disableElevation
                            aria-label='cancel'
                            onClick={() => {
                              formik.resetForm();
                              setEdit(false);
                              setDirty(false);
                            }}
                            data-testid='cancel-button'
                          >
                            Cancel
                          </Button>
                          <DisabledComponentsContainer isDisabled={!(formik.isValid)}>
                            <Button 
                              variant='contained' 
                              type='submit' 
                              disableElevation 
                              disabled={!(formik.isValid) || (formik.isSubmitting || deleting)} 
                              aria-label={!(formik.isValid) ? 'Save button disabled' : 'Save'}
                              sx={styles.saveButton}
                              data-testid='save-button'
                            >
                              {(formik.isSubmitting || deleting) ? <CircularProgress size={20} /> : `Save Changes`}
                            </Button>
                          </DisabledComponentsContainer>
                        </Box>
                      ) : (
                        <Box sx={styles.buttonGroup}>
                          <Button variant='contained' disableElevation onClick={handleClose} sx={styles.backButton} aria-label='back' data-testid='back-button'>
                            Back
                          </Button>
                        </Box>
                      )}
                    </Grid>
                    <WarningModal
                      open={warningOpen}
                      onClose={() => {setWarningOpen(false)}}
                      onConfirm={() => {saveClient(formik.values, {setSubmitting: formik.setSubmitting} as unknown as FormikHelpers<IClientSettingsFormikValues>)}}
                      description={warningDescription}
                      isDeleting={deleting}
                    />
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Modal>
      </Draggable>
      
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => {
          setToasterOpen(false);
        }}
      />
    </Box>
  );
};

export default ClientSettings;