const styles = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1118px',
    height: '100vh',
    maxHeight: '970px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '0.3rem',
    boxShadow: 24,
    p: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  checkBox: { 
    width: "20px", 
    padding:0 
  },
  paddingLeft: {
    paddingLeft: '8px'
  },
  modalTitleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'move'
  },
  closeIcon: {
    color: '#7C7D7D',
    fontSize: '1em',
  },
  divider: {
    borderColor: '#154A8A',
    borderLeftWidth: '1px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    marginRight: '0px'
  },
  iconColor: {
    color: '#154A8A',
    height: '20px'
  },
  disabledColor: {
    color: '#00000042',
  },
  buttonText: {
    color: '#010953',
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '10px'
  },
  mainSetting: {
    border: '1px solid #EBEBEB',
    display: 'flex',
    justifyContent: 'center',
    marginY: '0.75rem',
    padding: '1.8rem',
    width: '100%'
  },
  labelAndTextField: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '1rem',
  },
  textfieldCurrency: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      textAlign: 'right',
      height: '14px',
      borderRadius: '5px',
    },
  },
  fieldWidth: {
    width: '16rem',
  },
  fieldWidthState: {
    width: '124px',
    minWidth: '124px',
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  fieldWidthZipCode: {
    width: '124px',
    minWidth: '124px',
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  buttonGroup: {
    alignSelf: 'flex-end',
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '1rem',
  },
  saveButton: {
    textWrap: 'nowrap',
    textTransform: 'none',
    height: '2rem',
    width: '150px',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  cancelButton: {
    textTransform: 'none',
    height: '2rem',
    width: '82px',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  backButton: {
    textTransform: 'none',
    height: '2rem',
    width: '112px',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  rowMaxHeight: {
    height: '52px',
    maxHeight: '52px',
    alignItems: 'flex-start'
  },

  rowMaxHeightClients: {
    height: '40px',
    maxHeight: '40px',
    marginTop: "-20px"
  },

  checkBoxGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBotton: '10px'
  },

  checkBoxLabel: {
    '.MuiTypography-root': {
      fontSize: '14px',
      marginLeft: '5px',
      color: '#707070'
    },
  },

  paddingBottom: {
    paddingBottom: '16px'
  },
  stateFields: {
    // minWidth: '336px',
    // width:'336px',
    // flexWrap: 'nowrap',
    display: 'flex', 
    flexDirection:'row', 
    alignItems: 'flex-start',
    height: '52px',
    maxHeight: '52px',
  },
  label: {
    fontSize: '0.875rem',
    textAlign: 'right',
    marginRight: '4px',
    whiteSpace: 'nowrap',
    paddingTop: '4px',
  },
  modalFieldLabelRequired: {
    '&::after' : {
      content: '"*"',
      color:'red'
    },
  },

  asterisk:{
    color: '#AE2633'
  },
  
  textfieldNumber: {
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
      borderRadius: '5px',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
  field: {
    width: '17rem',
    // height: '30px', temporary disabled since there is no design yet for when the error message is too long
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      // backgroundColor: 'white',
      height: '30px',
      // borderRadius: '5px',   
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
    // '& .MuiFormHelperText-root.Mui-error':{
    //   minWidth: 'max-content'
    // }
  },
  field2: {
    width: '21rem'
  },
  placeholder: {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
      color: '#707070'
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': '#949494',
    },
  },
  hidden: {
    display: 'none',
  },
  autocompleteField: {
    width: '17rem',
    height: '30px',
    fontSize: '14px',
    '&&&& .MuiAutocomplete-input': {
      padding: '0px 60px 0px 10px',
      height: 'inherit'
    },
    '& .Mui-disabled': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
      margin: '0px',
    },
    '& .MuiInputLabel-root': {
      color: '#949494',
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#707070',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '7px'
    },
    '&&& .MuiOutlinedInput-root': {
      padding: 0
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#707070',
      opacity: '100%'
    },
  },

  columnContainer: {
    width: '450px'
  },

  autocompleteHeight: {
    maxHeight: 200
  },

  dropdownOptionsFontSize: {
    fontSize: '14px'
  },
  
  invisible: {
    visibility: 'hidden'
  },

  button: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '30px'
  },
  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  loadingIcon: {
    marginRight: '2rem'
  }
};

export default styles;
