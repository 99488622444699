import { Button, Typography, Modal, Grid, Box, Divider, Tooltip, IconButton, FormLabel, MenuItem, TextField, Select } from '@mui/material';
import { FormikProps, FormikValues, getIn, Formik, Form, Field } from 'formik';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useState } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { IInventorySetting } from '..';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../../context/clientSettingsContext';
import inventorySettingsSchema from '../../../../../../schemas/inventorySettingsSchema';
import { NON_EXISTING_PERCENT } from '../../../../../../utility/constants';
import ConfirmModal from '../../../../../modals/confirm-modal';
import Prompt from '../../../../../modals/prompt';
import styles from '../styles';
import HelperTextComponent from '../../../../../common/helper-text-component';
import DisabledComponentsContainer from '../../../../../common/disabled-components-container';

interface IInventorySettingModal {
  existingInvCollateralNames: string[];
  isModalOpen: boolean;
  onCloseModal: () => void;
  inventorySetting: IInventorySetting;
  isEditable: 'always' | 'editable' | 'not editable';
  onInventorySettingDelete?: () => void;
  onInventorySettingArchive?: () => void;
  onInventorySettingRestore?: () => void;
  onInventorySettingSave: (inventorySetting: IInventorySetting) => void;
  archivedInvNames: string[];
}

export interface IInventorySettingForFormik extends IInventorySetting{
  existingInvCollateralNames: string[]
}

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name    : string;
}

const CurrencyFormat = React.forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        allowNegative={false}
        type='tel'
        decimalScale={2}
        fixedDecimalScale={true}
        prefix='$'
      />
    );
  }
);

const PercentFormat = React.forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        decimalScale={2}
        fixedDecimalScale={false}
        allowNegative
        suffix='%'
        isAllowed={values => values.floatValue === undefined || (values.floatValue >= -100.00 && values.floatValue <= 100.00)}
      />
    );
  }
);

const InventorySettingModal: React.FC<IInventorySettingModal> = (props) => {
  const { showPrompt, setShowPrompt, isDirty, setIsDirty, canArchiveDeleteInv, canUpdateInv } = useContext(ClientSettingsContext) as IClientSettingsContext;

  const [isDetailsEnabled, setIsDetailsEnabled] = useState(props.isEditable === 'always');

  const handleCancel = (formik: FormikProps<IInventorySettingForFormik>) => {
    formik.resetForm();
    setIsDirty(false);
    if (props.isEditable === 'always') { props.onCloseModal(); return; }
    if (props.isEditable !== 'editable') { return; }
    setIsDetailsEnabled(false);
  };

  const handleClose = () => {
    if (!isDirty) {
      setIsDetailsEnabled(props.isEditable === 'always');
      props.onCloseModal();
      return;
    }
    setShowPrompt(true);
  };

  const getHeaderText = () => {
    if (props.isEditable === 'always') { return 'Add New Inventory'; }
    if (isDetailsEnabled) { return 'Edit Inventory'; }
    return 'View Inventory';
  };

  const getValueForCalculatedNOLV = (formik: FormikProps<FormikValues & IInventorySettingForFormik>) => {
    const { nolvAppraisal, nolvAdvanceRate } = formik.values.inventoryInput;
    if (nolvAppraisal === NON_EXISTING_PERCENT && nolvAdvanceRate === NON_EXISTING_PERCENT) { return ''; }
    if (nolvAppraisal === NON_EXISTING_PERCENT || nolvAdvanceRate === NON_EXISTING_PERCENT) { return 0; }
    const decimalNOLVAppraisal = parseFloat((formik.values.inventoryInput.nolvAppraisal / 100).toFixed(5));
    const decimalNOLVAdvanceRate = parseFloat((formik.values.inventoryInput.nolvAdvanceRate / 100).toFixed(5));
    const decimalCalculatedNOLV = decimalNOLVAppraisal * decimalNOLVAdvanceRate;
    const calculatedNOLV = (decimalCalculatedNOLV * 100).toFixed(5);
    return parseFloat(calculatedNOLV);
  };

  const getFormikValues = (formik: FormikProps<FormikValues & IInventorySettingForFormik>, name: string) => {
    const nameSplitted = name.split('.');
    const nameConvertedToSnakeCase = nameSplitted[nameSplitted.length - 1].replace(/[A-Z]./g, letter => `-${letter.toLowerCase()}`);
    const error = getIn(formik.errors, name);
    const isTouched = getIn(formik.touched, name);
    const value = getIn(formik.values, name);
    return {
      name: name,
      id: nameConvertedToSnakeCase,
      value: value !== NON_EXISTING_PERCENT ? value : '',
      error: (isTouched && Boolean(error)),
      helperText: (isTouched && error) ? <HelperTextComponent text={error} /> : null,
    };
  };

  const renderActionButton = () => {
    if (props.isEditable === 'always') { return null; }
    if (props.isEditable === 'not editable') {
      const restoreInventorySetting = props.onInventorySettingRestore as () => void;
      return (
        <Button
          startIcon={ <HistoryIcon sx={styles.actionIcon} /> }
          aria-label='Restore'
          onClick={() => restoreInventorySetting()}
          sx={styles.actionIconButton}
        >
          <Typography sx={styles.actionIconButtonText}>
            Restore
          </Typography>
        </Button>
      );
    }
    if (props.inventorySetting.inventoryCollateral.canDelete === 0) {
      const archiveInventorySetting = props.onInventorySettingArchive as () => void;
      return (
        <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
          <Button
            startIcon={<Inventory2OutlinedIcon sx={{ ...styles.actionIcon, ...(isDetailsEnabled && styles.disabledElement) }} />}
            aria-label={isDetailsEnabled ? 'Archive icon button disabled' : 'Archive icon'}
            onClick={() => archiveInventorySetting()}
            disabled={isDetailsEnabled}
            sx={styles.actionIconButton}
          >
            <Typography sx={{ ...styles.actionIconButtonText, ...(isDetailsEnabled && styles.disabledElement) }}>
              Archive
            </Typography>
          </Button>
        </DisabledComponentsContainer>
      );
    }
    const deleteInventorySetting = props.onInventorySettingDelete as () => void;
    return (
      <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
        <Button
          startIcon={<DeleteOutlineOutlinedIcon sx={{ ...styles.actionIcon, ...(isDetailsEnabled && styles.disabledElement) }}/>}
          onClick={() => deleteInventorySetting()}
          disabled={isDetailsEnabled}
          aria-label={isDetailsEnabled ? 'Delete icon button disabled' : 'Delete icon'}
        >
          <Typography sx={{ ...styles.actionIconButtonText, ...(isDetailsEnabled && styles.disabledElement) }}>
            Delete
          </Typography>
        </Button>
      </DisabledComponentsContainer>
    )
  };

  const getSublimitFieldCustomProps = (formik: FormikProps<IInventorySettingForFormik>) => {
    const disabled = !isDetailsEnabled
      || formik.values.inventoryInput.sublimitType === ''
      || (formik.values.inventoryInput.sublimitType === 'Amount Limit' && formik.values.inventoryInput.amountLimitType === '')

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = 'inventoryInput.sublimit';
      if (event.target.value === '') { formik.setFieldValue(name, NON_EXISTING_PERCENT); return; }
      formik.setFieldValue(name, parseFloat(event.target.value));
    };

    const InputProps = {
      inputComponent:
        (formik.values.inventoryInput.sublimitType === 'Amount Limit' && formik.values.inventoryInput.amountLimitType === 'Currency')
          ? (CurrencyFormat as any)
          : (PercentFormat as any),
    }

    return { disabled, onChange, InputProps };
  };

  const formikRef = (node: FormikProps<IInventorySettingForFormik>) => {
    if (node === null) { return; }
    setIsDirty(node.dirty);
  };

  const getTabIndex = (enabled: boolean, componentDefault: 'null' | 'undefined') => {
    if (componentDefault === 'null') return !enabled ? 0 : null;
    else return !enabled ? 0 : undefined;
  };

  const getFieldOnChangeForPercents = (formik: FormikProps<IInventorySettingForFormik>, name: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') { formik.setFieldValue(name, NON_EXISTING_PERCENT); return; }
      formik.setFieldValue(name, parseFloat(event.target.value));
    }

  return (
    <Modal open={props.isModalOpen} onClose={handleClose}>
      <Grid container sx={styles.modal}>
        <Grid item xs={12}>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Typography tabIndex={0} variant='h6' component='h2' sx={styles.modalHeaderText}>
              { getHeaderText() }
            </Typography>
            <Box sx={{ display: 'flex', ...((props.isEditable !== 'editable' || !canUpdateInv) && styles.hidden) }}>
              <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
                <Button
                  startIcon={<EditOutlinedIcon sx={{ ...styles.actionIcon, ...(isDetailsEnabled && styles.disabledElement), }} />}
                  disabled={isDetailsEnabled}
                  data-testid='edit-button'
                  aria-label={isDetailsEnabled ? 'Edit icon button disabled' : 'Edit icon'}
                  sx={styles.actionIconButton}
                  onClick={() => setIsDetailsEnabled(true)}
                >
                  <Typography sx={{ ...styles.actionIconButtonText, ...(isDetailsEnabled && styles.disabledElement) }}>
                      Edit
                  </Typography>
                </Button>
              </DisabledComponentsContainer>
              <Divider orientation='vertical' flexItem sx={{ ...styles.divider, ...(isDetailsEnabled && styles.disabledDivider) }}/>
            </Box>
            { canArchiveDeleteInv && renderActionButton() }
            <Grid>
              <Tooltip title='Close the modal'>
                <IconButton onClick={handleClose} aria-label='Close icon' data-testid='close-button'>
                  <CloseIcon fontSize='inherit'/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={{ ...props.inventorySetting, existingInvCollateralNames: props.existingInvCollateralNames.filter(name => name !== props.inventorySetting.inventoryInput.invCollateralName) }}
          validationSchema={inventorySettingsSchema(props.archivedInvNames)}
          onSubmit={(values) => { props.onInventorySettingSave(values); setIsDirty(false); props.onCloseModal(); }}>
          {(formik) => {
          const isSublimitFieldEnabled = isDetailsEnabled && formik.values.inventoryInput.sublimitType !== '';
          return (
            <Form>
              <Grid container justifyContent='space-around'>
                <Prompt when={(formik.dirty)} isEditing={() => {}}/>
                <Grid container sx={styles.modalFieldsContainer} >
                  <Grid item xs={12} lg={7}>
                    <Grid
                      container
                      rowSpacing={2.5}
                      columnSpacing={1}
                      sx={{...styles.modalGridContainer, ...styles.marginTop}}>
                      <Grid item xs={5} sx={{...styles.rightAlignedText}}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='inv-collateral-name'
                          sx={styles.formLabel}>
                          Inventory Collateral Name<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'null')}
                          sx={styles.modalField}
                          onChange={formik.handleChange}
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue('inventoryInput.invCollateralName', formik.values.inventoryInput.invCollateralName.trim());
                            formik.handleBlur(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ 'data-testid' : 'inv-collateral-name'}}
                          {...getFormikValues(formik, 'inventoryInput.invCollateralName')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={{ ...styles.rightAlignedText }}>
                        <Select
                          name='inventoryInput.sublimitType'
                          id='sublimit-type'
                          aria-label='Sublimit Type Field'
                          aria-labelledby='sublimit'
                          inputProps={{ 'aria-label': 'Sublimit Type', 'aria-labelledby': 'subLimit', 'data-testid' : 'sublimit-type' }}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'undefined') as number | undefined}
                          displayEmpty
                          onChange={(event) => {
                            formik.handleChange(event);
                            formik.setFieldValue('inventoryInput.amountLimitType', event.target.value === 'Amount Limit' ? 'Currency' : 'Percentage');
                            formik.setFieldValue('inventoryInput.sublimit', NON_EXISTING_PERCENT, false);
                            formik.setFieldTouched('inventoryInput.sublimit', true, false);
                          }}
                          sx={{
                            ...styles.modalField,
                            ...styles.modalFieldSelect,
                            ...styles.modalFieldSelectForLimit,
                            ...(formik.values.inventoryInput.sublimitType === '' && styles.modalFieldSelectForEmptyLimit)
                          }}
                          value={formik.values.inventoryInput.sublimitType}>
                          {
                            formik.values.inventoryInput.sublimitType === '' &&
                            <MenuItem value='' sx={{ ...styles.modalFieldMenuItem, ...styles.hidden }} disabled>
                              Please Select
                            </MenuItem>
                          }
                          <MenuItem value='Amount Limit' sx={styles.modalFieldMenuItem}>
                            Amount Limit
                          </MenuItem>
                          <MenuItem value='Eligible Collateral Limit' sx={styles.modalFieldMenuItem}>
                            Eligible Collateral % Limit
                          </MenuItem>
                          <MenuItem value='Facility Limit' sx={styles.modalFieldMenuItem}>
                            Facility % Limit
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          aria-label='Limit Field'
                          tabIndex={getTabIndex(isSublimitFieldEnabled, 'null')}
                          fullWidth
                          inputProps={{ 'aria-label': 'Sublimit', sx: styles.rightAlignedText}}
                          sx={{...styles.modalField}}
                          InputLabelProps={{ shrink: true }}
                          {...getSublimitFieldCustomProps(formik)}
                          {...getFormikValues(formik, 'inventoryInput.sublimit')}
                        />
                        <Select
                          name='inventoryInput.amountLimitType'
                          id='amount-limit-type'
                          aria-label='Amount Limit Type Field'
                          aria-labelledby='sublimit'
                          inputProps={{ 'aria-label': 'Amount Limit Type', 'aria-labelledby': 'sublimit', 'data-testid' : 'sublimit'  }}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'undefined') as number | undefined}
                          displayEmpty
                          onChange={(event) => {
                            formik.handleChange(event);
                            formik.setFieldValue('inventoryInput.sublimit', NON_EXISTING_PERCENT, false);
                            formik.setFieldTouched('inventoryInput.sublimit', true, false);
                          }}
                          sx={{
                            ...styles.modalField,
                            ...styles.modalFieldSelect,
                            ...styles.modalFieldSelectForAmount,
                            ...(formik.values.inventoryInput.sublimitType !== 'Amount Limit' && styles.hidden),
                            ...(formik.values.inventoryInput.amountLimitType === '' && styles.modalFieldSelectForEmptyAmount),
                          }}
                          value={formik.values.inventoryInput.amountLimitType}>
                          {
                            formik.values.inventoryInput.amountLimitType === '' &&
                            <MenuItem value='' sx={{ ...styles.modalFieldMenuItem, ...styles.hidden }} disabled>
                              Please Select
                            </MenuItem>
                          }
                          <MenuItem
                            value='Currency'
                            sx={styles.modalFieldMenuItem}>
                            Currency
                          </MenuItem>
                          <MenuItem
                            value='Percentage'
                            sx={styles.modalFieldMenuItem}>
                            Percentage
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='advance-rate'
                          sx={styles.formLabel}>
                          Inventory Advance Rate<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'null')}
                          inputProps={{ sx: styles.rightAlignedText }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const name = 'inventoryInput.advanceRate';
                            if (event.target.value === '' || event.target.value === '-') {
                              formik.setFieldValue(name, NON_EXISTING_PERCENT);
                              formik.setFieldError(name, `Inventory Advance Rate is required`);
                              return;
                            }
                            formik.setFieldValue(name, parseFloat(event.target.value));
                            formik.setFieldError(name, undefined);
                          }}
                          sx={styles.modalField}
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: PercentFormat as any }}
                          {...getFormikValues(formik, 'inventoryInput.advanceRate')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* second column */}
                  <Grid item xs={12} lg={5}>
                    <Grid
                      container
                      rowSpacing={2.5}
                      columnSpacing={1}
                      sx={{...styles.modalGridContainer, ...styles.marginTop}}>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='nolv-advance-rate'
                          sx={styles.formLabel}>
                          NOLV Advance Rate
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'null')}
                          inputProps={{ sx: styles.rightAlignedText }}
                          onChange={getFieldOnChangeForPercents(formik, 'inventoryInput.nolvAdvanceRate')}
                          sx={styles.modalField}
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: PercentFormat as any }}
                          {...getFormikValues(formik, 'inventoryInput.nolvAdvanceRate')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='nolv-appraisal'
                          sx={styles.formLabel}>
                          Applicable NOLV
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'null')}
                          inputProps={{ sx: styles.rightAlignedText }}
                          onChange={getFieldOnChangeForPercents(formik, 'inventoryInput.nolvAppraisal')}
                          sx={styles.modalField}
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: PercentFormat as any }}
                          {...getFormikValues(formik, 'inventoryInput.nolvAppraisal')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='nolv-and-advance-rate'
                          sx={styles.formLabel}>
                          (NOLV%)*(Advance Rate%)
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <TextField
                          disabled
                          tabIndex={0}
                          id='nolv-and-advance-rate'
                          value={getValueForCalculatedNOLV(formik)}
                          inputProps={{ sx: styles.rightAlignedText }}
                          sx={styles.modalField}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: PercentFormat as any,
                            'aria-label': `(NOLV%)*(Advance Rate%)${getValueForCalculatedNOLV(formik)}%`
                          }}
                        />
                      </Grid>
                      <Grid item xs={5} sx={{ ...styles.rightAlignedText, ...styles.marginBottom }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='nolv-ineligible-amount'
                          sx={styles.formLabel}>
                          NOLV Ineligible Amount
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7} sx={{ ...styles.marginBottom }}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          tabIndex={getTabIndex(isDetailsEnabled, 'null')}
                          inputProps={{ sx: styles.rightAlignedText }}
                          onChange={getFieldOnChangeForPercents(formik, 'inventoryInput.nolvIneligibleAmount')}
                          sx={styles.modalField}
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          {...getFormikValues(formik, 'inventoryInput.nolvIneligibleAmount')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {
                    isDetailsEnabled ? (
                      <Box sx={styles.modalBottomButtonContainer}>
                        <Button
                          variant='outlined'
                          sx={styles.cancelButton}
                          type='reset'
                          onClick={() => handleCancel(formik)}>
                          Cancel
                        </Button>
                        <DisabledComponentsContainer isDisabled={!formik.dirty || !formik.isValid}>
                          <Button
                            disabled={!formik.dirty || !formik.isValid}
                            aria-label={!formik.dirty || !formik.isValid ? 'Save button disabled' : 'Save'}
                            variant='contained'
                            data-testid='save-button'
                            type='submit'
                            sx={styles.saveOrBackButton}>
                            Save
                          </Button>
                        </DisabledComponentsContainer>
                      </Box>
                    ) : (
                      <Box sx={styles.modalBottomButtonContainer}>
                        <Button
                          onClick={() => props.onCloseModal()}
                          variant='contained'
                          sx={styles.saveOrBackButton}>
                          Back
                        </Button>
                      </Box>
                    )}
                </Grid>
              </Grid>
              <ConfirmModal
                open={showPrompt}
                onClose={() => {
                  formik.resetForm();
                  setIsDirty(false);
                  setShowPrompt(false);
                  setIsDetailsEnabled(props.isEditable === 'always');
                  props.onCloseModal();
                }}
                onConfirm={() => setShowPrompt(false)}
                onButtonClose={() => setShowPrompt(false)}
                promptChecker
                title='Confirm Navigation'
                description='You have unsaved changes. Are you sure you want to leave this page?'
                yesButtonText='Keep Editing'
                noButtonText='Discard Changes'
                confirmOnly
              />
            </Form>
            );
          }}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default InventorySettingModal;
