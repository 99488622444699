import { AlertColor, Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import ConfirmModal from "../../../../modals/confirm-modal";
import { useState } from "react";
import { ISubGroup } from "../../../../../interfaces/groupsInterface";
import styles from "../../../styles";
import { getLocalStorageItem } from "../../../../../utility/helper";
import { DELETE } from "../../../../../utility/constants";
import axiosInstance from "../../../../../service/axiosInstance";
import { groupsAPI } from "../../../../../service/api";

interface GroupProps {
  group: ISubGroup
  idx: number,
  setSelectedGroup: React.Dispatch<React.SetStateAction<ISubGroup | null>>,
  setShowAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>,
  getAllGroups: () => void,
  setToasterMessage: (value: string) => void,
  setToasterSeverity: (value: AlertColor) => void,
  setToasterOpen: (value: boolean) => void,
}

const GroupsTableRow = (props: GroupProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  /**
   * This function is called when the delete icon is clicked. 
   * It shows the modal for confirming the delete action.
   */
  const onDeleteClick = () => {
    setModalTitle(`Delete ${props.group.name}?`);
    setIsModalOpen(true);
  };

   /**
   * This function closes the delete modal.
   */
   const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: groupsAPI.ALL_GROUPS,
        method: DELETE,
        params: {
          groupId: props.group.id,
          parentGroupId: props.group.parentGroupId
        },
        headers: { token: token !== undefined ? token : '' }
      })

      await props.getAllGroups()

      props.setToasterMessage(`Group has been succesfully deleted!`);
      props.setToasterSeverity('success');
      props.setToasterOpen(true);
    } catch (error) {
      console.log('GET ALL ROLES ERROR:', error);
      props.setToasterMessage(`An error occured`);
      props.setToasterSeverity('error');
      props.setToasterOpen(true);
    } finally {
      closeModal();
    }
  }

  const selectGroup = () => {
    props.setSelectedGroup(props.group);
    props.setShowAddGroupModal(true);
  }


  return (
    <TableRow sx={styles.tableRow}>
      <ConfirmModal
        title={modalTitle}
        description={'Are you sure you want to delete this group?'}
        open={isModalOpen}
        alignment="left"
        onClose={closeModal}
        onConfirm={handleDeleteConfirm}
        errorButton={true}
        noButtonText={'Cancel'}
        yesButtonText={'Delete'}
      />
      <TableCell
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'left'
        }}
      >
        <Box sx={styles.spaceX}>
          {props.group.name}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        {props.group.users.length}
      </TableCell>

      <TableCell
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        {/* role */}
        {props.group.parentGroupName} 
      </TableCell>
      <TableCell
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        <Box>
          <Tooltip title='View the Group Information'>
            <IconButton
              onClick={selectGroup}
              sx={styles.actionsIconButton}
            >
              <PreviewIcon sx={{ ...styles.actionsIcon }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete the Group'>
            <IconButton
              onClick={onDeleteClick}
              sx={{ ...styles.actionsIconButton }}
            >
              <DeleteOutlineIcon sx={{ ...styles.actionsIcon }} />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default GroupsTableRow;