import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Grid, TextField, Autocomplete, Chip } from '@mui/material';
import { API_DOMAIN, GET, PERMISSIONS } from '../../../utility/constants';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { InventoryIneligibleContext as InvIneContext } from '../../../context/inventoryIneligibleContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { IInventoryIneligibleContext as IInvIneContextInterface, IClientName, IInventory } from '../../../interfaces/inventoryIneligibleInterface';
import GeneralBreadcrumbs from '../../breadcrumb';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import axiosInstance from '../../../service/axiosInstance';
import { checkUserPermissions, getLocalStorageItem } from '../../../utility/helper';
import { borrowerAPI } from '../../../service/api';
import { ClientContext } from '../../../context/clientContext';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const currencyFormat = (amountString: string) => {
  const amount = parseFloat(amountString);
  return formatter.format(amount);
};

const InventoryIneligibleHeader: React.FC = () => {
  const {
    borrowerId: borrowerIdParam,
    invCollateralFk: collateralIdParam
  }                                                       = useParams()
  const navigate                                          = useNavigate();
  const {
    selectedClient: selectedClientContext,
    setSelectedClient: setSelectedClientContext
  }                                                       = useContext(SelectedClientContext);
  const {
    clients, setClients,
    selectedClient, setSelectedClient,
    selectedInventory, setSelectedInventory,
    lastSelectedClient, setLastSelectedClient,
    totalAmount, showPrompt, setShowPrompt
  }                                                       = useContext(InvIneContext) as IInvIneContextInterface;
  const { isDirty, setIsDirty }                           = useContext(NavigationWarningContext) as INavigationWarningContext;
  const [inventories, setInventories]                     = useState<IInventory[]>([]);
  const [lastSelectedInventory, setLastSelectedInventory] = useState(selectedInventory);  // used for storing the last selected inventory for confirm navigation modal
  const [activeDescendantID, setActiveDescendantID] = useState('');


  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    const canViewClientsRequest = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_CLIENT);
    if (canViewAssignedClientsOnly && !canViewClientsRequest) {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC', crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC'}
    }
    const token = getLocalStorageItem('token');
    await axiosInstance.request({
      url: borrowerAPI.FIND_BY_CRITERIA,
      method: GET,
      params: requestParams,
      headers: { token: token !== undefined ? token : '' }
    })
    .then((response) => {
      let selectedClientIndex = 0;
      const mappedClients: Array<IClientName> = response.data.content.map((client: IClientName, index: number) => {
        const currentClient = { recordId: client.recordId, borrowerName: client.borrowerName, parentClient: client.parentClient, parentClientFk: client.parentClientFk };
        if (borrowerIdParam && client.recordId === parseInt(borrowerIdParam)){ selectedClientIndex = index }
        return currentClient;
      });
      setClients(mappedClients);
      const selectedMappedClient = mappedClients[selectedClientIndex];
      setSelectedClientContext(selectedMappedClient);
      setLastSelectedClient(selectedMappedClient)
    })
    .catch(error => console.log('INVENTORY INELIGIBLES GET CLIENT ERROR: ', error));
  };

  const getInventories = async () => {
    const currentBorrowerId = selectedClient.recordId === -1 ? borrowerIdParam : selectedClient.recordId;
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/inv/inputs/search/findIsArchived`,
        method: GET,
        params: {
          borrowerId: currentBorrowerId,
          isArchived: false,
          sortBy: 'invCollateralName,ASC'
        }
      });
      
      console.log(response.data.content,'response')

      const mappedInventories: Array<IInventory> = response.data.content.map((inventory: IInventory, index: number) => {
        const currentInventory = {recordId: inventory.recordId, invCollateralFk: inventory?.invCollateralFk, invCollateralName: inventory.invCollateralName};
        return currentInventory;
      });
      setInventories(mappedInventories);
      const result = await axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals/search/findByBorrowerId`,
        method: GET,
        params: {
          borrowerId: selectedClient.recordId,
          isArchived: true,
          isDefault: true,
          sortBy: 'recordId,ASC'
        }
      });
        if(result.status === 200) {

          const defaultInventory = result.data.content.find((item:any) =>  !item.archive && item.default && mappedInventories.some((inventories:any) => inventories.invCollateralFk === item.recordId))
          // find the corresponding inv input object related to the default inventory collateral

          if(defaultInventory){
            const defaultInvInput = mappedInventories.find((inventory: any) => inventory.invCollateralFk === defaultInventory.recordId);

            setSelectedInventory(defaultInvInput!)
            setLastSelectedInventory(defaultInvInput!)

            return
          }

            const inventoryDefault = result.data.content.length > 0 ? mappedInventories[0] : { recordId: -1, invCollateralFk: -1, invCollateralName: '' };

            setSelectedInventory({ recordId: -1, invCollateralFk: -1, invCollateralName: '' });
            setLastSelectedInventory(inventoryDefault)
            
        } 
      } catch(error) {
        console.log("GET INVENTORIES ERROR: ",error);
      }
    };

  const handleClientChange = (_event: any, newValue: IClientName | null) => {
    if (newValue === null) { return; }
    setLastSelectedClient(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedClientContext(newValue);
    setSelectedClient(newValue);
  };

  const handleInventoryChange = (_event: any, newValue: IInventory | null) => {
    if (newValue === null) { return; }
    setLastSelectedInventory(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedInventory(newValue);
  };

  const handleDiscardChanges = () => {
    setSelectedClientContext(lastSelectedClient);
    setSelectedInventory(lastSelectedInventory);
    setIsDirty(false);
    setShowPrompt(false);
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getInventories();
  }, [selectedClient]);

  useEffect(() => {
    const isClientUninitialized = selectedClient.recordId === -1 || selectedClient.recordId === undefined;
    const isInventoryUninitialized = selectedInventory.invCollateralFk === -1 || selectedInventory.invCollateralFk === undefined;
    if(isClientUninitialized || isInventoryUninitialized){ return; }
    const isClientAlreadySetAsParam = borrowerIdParam === selectedClient.recordId.toString();
    const isInventoryAlreadySetAsParam = collateralIdParam === selectedInventory.invCollateralFk.toString();
    if (isClientAlreadySetAsParam && isInventoryAlreadySetAsParam) { return; }

    navigate(`/clients/${selectedClient.recordId}/settings/${selectedInventory.invCollateralFk}/ineligibles-inventory`);
  }, [selectedClient, selectedInventory]);

  useEffect(() => {
    if (selectedClientContext === null || selectedClientContext === undefined || selectedClientContext.recordId === undefined || selectedClientContext.borrowerName === undefined) { return; }
    setSelectedClient({ recordId: selectedClientContext.recordId, borrowerName: selectedClientContext.borrowerName, parentClient: selectedClientContext.parentClient, parentClientFk: selectedClientContext.parentClientFk });
  }, [selectedClientContext]);

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    const parentClients = clients.filter(client => client.parentClient).map(client => client.borrowerName);
    return parentClients.includes(option);
  };

 
  return (
    <>
      <Box component='span' sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer} spacing={0}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <GeneralBreadcrumbs
              selectedText='Ineligible Inventory Setting'
              breadcrumbLinks={[
                { linkText: 'Clients', route: '/clients' },
                { linkText: 'Client Settings', route: `/clients/${selectedClient.recordId}/settings` },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <Typography tabIndex={0} sx={styles.clientLabel} variant='body2' component='label' htmlFor='combo-box-clients'>
                Client Name:
              </Typography>
              <Autocomplete
                id="combo-box-clients"
                aria-label="Client Name Dropdown"
                disablePortal
                options={clients.filter(client => !isSelectedFieldNameParentFieldValue(client.borrowerName))}
                getOptionLabel={(clientOption) => clientOption.borrowerName}
                renderOption={(prop, option) => {
                  return (
                    <Box 
                      component="li"
                      {...prop}
                      id={`option-${option.recordId}`} // Assign a unique ID to each option
                      key={option.recordId}
                      sx={styles.dropdownText}
                    >
                      <Box tabIndex={0} sx={styles.labelChipContainer}>{option.borrowerName}</Box>
                      <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.borrowerName) && styles.hidden) }}>
                        <Chip size='small' sx={styles.parentIdentifierChip} />
                      </Box>
                    </Box>
                  );
                }}
                onChange={handleClientChange}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    inputProps={{
                      ...params.inputProps, 
                      'aria-label': 'Client Name Dropdown', 
                      'aria-activedescendant': activeDescendantID 
                    }} // Set the aria-activedescendant attribute to the active option's ID
                  />
                )}
                onHighlightChange={(event, option) => {
                  if (option) {
                    setActiveDescendantID(`option-${option.recordId}`); // Update the active option ID on highlight change
                  }
                }}
                size="small"
                fullWidth
                sx={styles.clientDropdownBox}
                value={selectedClient}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.titleCard}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
          Ineligible Inventory Setting
        </Typography>
      </Box>

      <Box sx={styles.headerCard} py={2} gap={2}>
        <Grid container spacing={2} sx={styles.calculateSettings}>
          <Grid item xs={12} md={6} gap={1} sx={styles.gridStyle}>
            <Typography tabIndex={0} sx={styles.labelStyle} variant='body2' component='label' htmlFor='combo-box-inventory'>
              Collateral Name
            </Typography>
            <Autocomplete
              id='combo-box-inventory'
              aria-label='Collateral Name Dropdown'
              disablePortal
              options={inventories}
              getOptionLabel={inventoryOption => inventoryOption.invCollateralName}
              renderOption={(prop, option) => (
                <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownText}>
                  {option.invCollateralName}
                </Box>
              )}
              onChange={handleInventoryChange}
              renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Collateral Name Dropdown' }} />}
              size='small'
              fullWidth
              sx={styles.clientComboBox}
              value={selectedInventory}
              componentsProps={{
                popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
              }
            />
          </Grid>
        </Grid>
        <Box sx={styles.totalAmountContainer}>
          <Typography tabIndex={0} sx={styles.labelTotalAmount}>
            Total Amount:
          </Typography>
          <Typography tabIndex={0} sx={styles.valueTotalAmount}>
            {currencyFormat(totalAmount.toString())}
          </Typography>
        </Box>
        <ConfirmModal
          open={showPrompt}
          onClose={() => { handleDiscardChanges(); }}
          onConfirm={() => { setShowPrompt(false); }}
          onButtonClose={() => { setShowPrompt(false); }}
          promptChecker
          title={`Confirm Navigation`}
          description={`You have unsaved changes. Are you sure you want to leave this page?`}
          yesButtonText={`Keep Editing`}
          noButtonText={`Discard Changes`}
          confirmOnly
        />
      </Box>
    </>
  );
}

export default InventoryIneligibleHeader;
