import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import dayjsUTC from "dayjs/plugin/utc";
import dayjsTimeZone from "dayjs/plugin/timezone";
import { FormikProps } from "formik";
import { Dispatch, ForwardedRef, SetStateAction } from "react";
import NumberFormat, { InputAttributes } from "react-number-format";
import { IARBorrowerInput, IARCollateral, IClient, ICustomProps, IExportFileType, IInventoryInput, ISearchProps, ISetSelected } from "../interfaces";
import { IBBPeriodFilter, IComboBoxIds, IComboBoxParams, IComboBoxSetStates, IOption } from "../interfaces/comboBox";
import { arCollateralAPI, arCustomersAPI, bbPeriodAPI, bbCalcDateAPI, borrowerAPI, ineligibleSettingAPI, inventoryAPI, fileImportAPI, ineligibleRuleAPI, arTransactionAPI, reportsAPI, rolePermissionAPI,documentAttributeAPI, collateralRuleAPI, borrowingBaseReportAPI, multiCurrencyAPI, bbCalcSummaryCustomerAPI, ruleConditionFieldListAPI, arVendorsAPI } from "../service/api";
import axiosInstance from "../service/axiosInstance";
import request from "../service/request";
import { AMERICAN_DATE_FORMAT, GET, POST, API_DOMAIN, dataMappingFields, fieldTypeList, MIME_TYPES, DELETE, STATUS_CODES, PERMISSIONS, fieldsPerDocumentType, TIMESTAMP_WITH_ZONE, PATCH } from "./constants";
import Papa, { ParseResult } from "papaparse";
import { IARIneligible } from "../pages/reports/ar-ineligible";
import * as XLSX from 'xlsx';
import { IRoleAPI, IUserAPI } from "../interfaces/rolesPermissionInterface";
import { IDocumentMappings, IExistingMapping, IUploadedFile } from "../interfaces/fileimport";
import { ICurrencyWithClientCurrency, IRate } from "../interfaces/multiCurrencyInterface";
import { IDocumentAttributePayload } from "../interfaces/dataMap";
import crypto from 'crypto';
import { amtHeaders } from "../pages/file-import/stepper/stepper-content/datamap-tab";
import { IClientFormikValues, IClientInfo, IClientSettingsFormikValues } from "../interfaces/clientListInterface";
import _ from "lodash";
import { IExportReportWithPayload } from "../interfaces/exportReportInterface";
import { IARBorrowingBase, IARBorrowingBaseTotals, IInvBorrowingBase, IOtherCollateralReport } from "../interfaces/reportsInterface";

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimeZone);

/////////////////////////////
// setLocalStorageItem()
/////////////////////////////
export const setLocalStorageItem = (storageKey: string, state: string) => {
  localStorage.setItem(storageKey, JSON.stringify(state));
}

/////////////////////////////
// getLocalStorageItem()
/////////////////////////////
export const getLocalStorageItem = (storageKey: string) => {
  const savedState = localStorage.getItem(storageKey);
  try {
    if (!savedState) {
      return undefined;
    }
    return JSON.parse(savedState);
  } catch (e) {
    return undefined;
  }
}

export const formatDate = (value: string, format: string) => {
  if (value===''){
    return dayjs().format(format);
  }
  else{
    return dayjs(value).format(format);
  }
  
}

export const formatDateToLocal = (utcDateString: string, resultingFormat: string, utcRetrievedFormat?: string) => {
  if (utcDateString === '') return dayjs().format(resultingFormat);
  else if (utcRetrievedFormat) {
    // utc format from BE is commonly 'MM/DD/YYYY hh:mm A'
    const utcDate = dayjs.utc(utcDateString, utcRetrievedFormat);
    const localDate = utcDate.local();
    const formattedDate = localDate.format(resultingFormat);
    return formattedDate;
  } else {
    // if the dateString from BE has no format
    return dayjs.utc(utcDateString).local().format(resultingFormat);
  }
}

export const getDateObject = (dateString?: string) => {
  if (dateString === undefined) { return new Date(1970, 0, 1); }
  // split date string into array
  const parts = dateString.split('-');
  
  // initialize date with integers instead of strings;
  return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
}

export const objectToURLParams = (object: any) => {
  return new URLSearchParams(object).toString()
}

export const checkIfZeroOrUndefined = (value?: number | null) => {
  const parsedVal = parseFloat(value ? value.toFixed(4) : '0');

  if (Math.abs(parsedVal)) return parsedVal;
  else return 0;
}

export const formatCurrency = (value: number | null, currency?: string, exchangeRate?: number) => {
  return formatNumber({
    style: "currency",
    currency: currency ?? "USD",
    currencySign: 'accounting'
  }, 
  checkIfZeroOrUndefined(value),
  exchangeRate
  )
}

export const stringCurrencyToNumber = (currencyString: string): number => {
  const cleanedString = currencyString.replace(/[()]/g, '-').replace(/[^\d.-]/g, '');
  return parseFloat(cleanedString);
}

export const formatNumber = (props: Intl.NumberFormatOptions, value: number, exchangeRate?: number) => {
  return new Intl.NumberFormat("en-US", props).format(exchangeRate? value / exchangeRate  : value)
}

export const formatPercentage = (value: number | null | undefined, precision: number) => {
  return `${((checkIfZeroOrUndefined(value)) * 100).toFixed(precision)}%`;
};

export const formatFileSize = (bytes: number, precision: number) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**precision;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(precision) + ' ' + units[u];
}

export const parseBoolean = (value: string) => {
  switch (value) {
    case 'true': {
      return true;
    }
    case 'false': {
      return false;
    }
    default: {
      return;
    }
  }
}

export const getSumArray = (array: number[]) => {
  return array.reduce((sum, current) => sum + current, 0);
}

export const sortAscending = (stringA?: string, stringB?: string) => {
  if (stringA && stringB) {
    const nameA = stringA.toUpperCase();
    const nameB = stringB.toUpperCase();

    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export const roundToXDigits = (value: number, digits: number) => {
  value = value * Math.pow(10, digits);
  value = Math.round(value);
  value = value / Math.pow(10, digits);
  return value;
};

export const sortIndexAscending = (array: any) => {
  return [...array].sort((a, b) => a.index - b.index)
}

export const sortRecordIdAscending = (array: IOption[]) => {
  return [...array].sort((a: IOption, b: IOption) => a.recordId - b.recordId)
}

export const sortByLatest = (array: any[]) => [...array].sort((a, b) => {
  const firstClientDate = new Date(a.createdAt);
  const secondClientDate = new Date(b.createdAt);
  return firstClientDate.valueOf() - secondClientDate.valueOf();
}).reverse();

export const isObjectsEqual = (...args: any[]) => {
  if (args.length < 2) { return true; }

  const initialItem = args[0];
  for (let index = 1; index < args.length; index++) {
    const currentItem = args[index];
    const isItemsEqual = _.isEqual(initialItem, currentItem);
    if (!isItemsEqual) { return false; }
  }

  return true;
}

export const exportReport = async (setExporting: Dispatch<SetStateAction<boolean>>, apiURL: string, payloadData: any, fileName: string) => {
  setExporting(true);

  try {
    const exportResponse = await axiosInstance.request({
      url: apiURL,
      method: POST,
      responseType: 'blob',
      data: payloadData,
      headers: { 'X-Timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone }
    })

    let exportData = exportResponse.data;

    const url = window.URL.createObjectURL(new Blob([exportData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log('EXPORT ERROR : ', error);
  } finally {
    setExporting(false);
  }
};

export const triggerExportReport = async (setExporting: Dispatch<SetStateAction<boolean>>, apiURL: string, payloadData: any) => {
  try {
    setExporting(true);

    await axiosInstance.request({
      url: apiURL,
      method: POST,
      responseType: 'blob',
      data: payloadData,
      headers: { 'X-Timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone }
    });
  } catch (error) {
    console.log('TRIGGER EXPORT ERROR : ', error);
  } finally {
    setExporting(false);
  }
}
 
export const getFileName = (exportAs: IExportFileType, reportName: string, endDate?: string, clientName?: string, collateralName?: string) => {
  const str = endDate;
  let bbPeriodString = {
    month: '',
    day: '',
    year: '',
  }

  if (str) {
    const [month, day, year] = str.split('/');
    
    bbPeriodString.month = month;
    bbPeriodString.day = day;
    bbPeriodString.year = year;
  }

  let collateral = '';
  if (collateralName) {
    collateral = collateralName.split(' ').join('');
  }

  if (exportAs === 'PDF') {
    return `${clientName} ${reportName}-${collateral}_${bbPeriodString.month}-${bbPeriodString.day}-${bbPeriodString.year}.pdf`;
  } else {
    return `${clientName} ${reportName}-${collateral}_${bbPeriodString.month}-${bbPeriodString.day}-${bbPeriodString.year}.xlsx`;
  }
};

export const getFileNameArCompare = (exportAs: IExportFileType, reportName: string, clientName?: string) => {
  if (exportAs === 'PDF') {
    return `${clientName} ${reportName}.pdf`;
  } else {
    return `${clientName} ${reportName}.xlsx`;
  }
};

export const compareStrings = (val: any) => {
  if (typeof val === 'string') return val.toLowerCase();
  else return val;
}

export const descendingComparator= <T, >(a: T, b: T, orderBy: keyof T, report: 'arIneligibleCustView' | 'arAging' | 'others') => {
  const value = (val: any) => {
    if (report === 'arAging') return parseInt(val) === 0 ? -Infinity : compareStrings(val);
    else if (report === 'arIneligibleCustView') return val === undefined ? -Infinity : compareStrings(val);
    else return compareStrings(val);
  }

  const valA = value(a[orderBy]);
  const valB = value(b[orderBy]);

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

export const getComparator = <Key extends keyof any>(order: Order, orderBy: Key, report: 'arIneligibleCustView' | 'arAging' | 'others') : (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, report)
    : (a, b) => -descendingComparator(a, b, orderBy, report);
}

export const stableSort = <T, >(array: readonly T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export type Order = 'asc' | 'desc';

export const searchGlobal = <T extends Object>(trail: T, searchVals: ISearchProps<T>[]) => {
  return Object.values(trail).some((val) => {
    const globalSearch = searchVals.find((element) => element.id === 'global');
    
    if (globalSearch?.value !== '') {
      const custRegEx = new RegExp(globalSearch?.value as string, 'gi');
      return val.search(custRegEx) !== -1;
    } else {
      return true;
    }
  })
}

export  const searchColumn = <T extends Object>(trail: T, searchVals: ISearchProps<T>[]) => {
  const columnSearchVals = searchVals.filter((element) => element.id !== 'global');

  return columnSearchVals.every((element) => {
    if (element.value !== '') {
      const custRegEx = new RegExp(element.value, 'gi');
      return String(trail[element.id as keyof T]).search(custRegEx) !== -1;
    } else {
      return true;
    }
  })
}

export const getInputProps = (inputFormat: 'currency' | 'percent' | 'integer' | 'id' | 'phone', ref: ForwardedRef<NumberFormat<InputAttributes>>, props: ICustomProps) => {
  const { onChange, ...other } = props;

  const inputFormats = {
    'currency': {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '$'
    },
    'percent': {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: false,
      suffix: '%'
    },
    'integer': {
      thousandSeparator: true,
      decimalScale: 0,
      allowNegative: false
    },
    'id': {
      thousandSeparator: false,
      decimalScale: 0,
      allowNegative: false
    },
    'phone': {
      format: '+1 (###) ###-####'
    }
  }

  const inputProps = {
    ...other,
    getInputRef: ref,
    onValueChange: (values: any) => {
      onChange({
        target: { value: values.value, name: other.name },
      });
    },
    type: 'tel' as 'tel' | 'text' | 'password' | undefined,
    ...inputFormats[inputFormat],
  }
  return inputProps;
}

export const getOptions = async (options: {
                            type: IComboBoxIds,
                            params: IComboBoxParams,
                            getSetStatesByType: (type: IComboBoxIds) => IComboBoxSetStates,
                            setSelectedByType: (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => void,
                            mainRequest: Promise<AxiosResponse<any, any>>,
                            requestForDefault?: boolean,
                            filter?: IBBPeriodFilter
                          }) => {
  const { type, params, getSetStatesByType, setSelectedByType, mainRequest, requestForDefault, filter } = options;
  try {
    const response = await mainRequest;
    const content = response.data.content;
    const setStates = getSetStatesByType(type);
    const id = parseInt(getParamsByType(type, params).params);
    const borrowerId = parseInt(params.borrowerId);
    const arCollateralId = parseInt(params.arCollateralId);

    if (content.length) {
      const options: IOption[] = content.map((value: any) => getOptionsByType(value, type));
      await getSelectedByType(borrowerId, arCollateralId, {id, options, type, setStates, setSelectedByType}, filter, requestForDefault);
    }
  } catch (error) {
    console.log(`${type} API ERROR : `, error);
  }
};

export const getSelectedByType = async (borrowerId: number, arCollateralId: number, setSelectedProps: ISetSelected, filter?: IBBPeriodFilter, requestForDefault?: boolean) => {
  const { id, options, type, setStates, setSelectedByType } = setSelectedProps;

  switch (type) {
    case 'bbPeriod':
      try {
        let filtered: IOption[] = [];
        if (filter === 'settings') {
          const response = await getBBPeriodWithTransactionsRequestByARCollateralId(arCollateralId);
          const periodsWithTransactions: number[] = response.data;
          filtered = options.filter((period: IOption) => periodsWithTransactions.includes(period.recordId));
        } else if (filter === 'ineligible') {
          const response = await getCalcBBPeriodRequestByARCollateralId(arCollateralId);
          const periodsWithIneligibleCalculations: number[] = response.data;
          filtered = options.filter((period: IOption) => periodsWithIneligibleCalculations.includes(period.recordId));
        } else if (filter === 'rollForward') {
          const response = await getBBPeriodsWithRollForwardReport(borrowerId, arCollateralId);
          const periods: number[] = response.data;
          filtered = options.filter((period: IOption) => periods.includes(period.recordId));
        } else if (filter === 'apAging') {
          const response = await getBBPeriodsWithAPTransactions(arCollateralId);
          const periods: number[] = response.data;
          filtered = options.filter((period: IOption) => periods.includes(period.recordId));
        } else {
          const response = await getCalcBBPeriodRequestByBorrowerId(borrowerId);
          const periodsWithCalculations: number[] = response.data;
          filtered = options.filter((period: IOption) => periodsWithCalculations.includes(period.recordId));
        }

        setStates.setOptions(filtered);
        commonSelect({options: filtered, id, type, setStates, setSelectedByType});
      } catch (error) {
        console.log('GET CALC BB PERIODS ERROR : ', error);
      }
      break;
    case 'arCollateral':
      try {
        const response = await getARCollateralRequestByBorrowerInput(borrowerId);
        const arCollaterals: IARBorrowerInput[] = response.data.content;

        const mapped = arCollaterals.map((arCollateral: IARBorrowerInput) => arCollateral.arCollateralName)
        const filtered = options.filter((arCollateral: IOption) => mapped.includes(arCollateral.label));
        setStates.setOptions(filtered);

        const selected = filtered.find((option: IOption) => option.recordId === id);
        checkDefault(requestForDefault, {id, options: filtered, selected, type, setStates, setSelectedByType});
      } catch (error) {
        console.log('GET AR BORROWER INPUTS ERROR : ', error);
      }
      break;
    case 'invCollateral':
      try {
        const response = await getCollateralRequestByInput(borrowerId);
        const invCollaterals: IInventoryInput[] = response.data.content;

        const mapped = invCollaterals.map((invCollateral: IInventoryInput) => invCollateral.invCollateralName)
        const filtered = options.filter((invCollateral: IOption) => mapped.includes(invCollateral.label));
        setStates.setOptions(filtered);

        const selected = filtered.find((option: IOption) => option.recordId === id);
        checkDefault(requestForDefault, {id, options: filtered, selected, type, setStates, setSelectedByType});
      } catch (error) {
        console.log('GET AR BORROWER INPUTS ERROR : ', error);
      }
      break;
    case 'customer': 
      options.unshift({label: 'All', recordId: 0, default: false});
      setStates.setOptions(options);
      commonSelect({options, id, type, setStates, setSelectedByType});
      break;
    default:
      setStates.setOptions(options);
      commonSelect({options, id, type, setStates, setSelectedByType});
      break;
  }
};

export const commonSelect = (setSelectedProps: ISetSelected) => {
  const { options, id, setSelectedByType , type, setStates } = setSelectedProps;
  const selected = options.find((option: IOption) => option.recordId === id);
  if (selected) setSelectedByType(selected, type, setStates);
};

export const checkDefault = (requestForDefault: boolean | undefined, setSelectedProps: ISetSelected) => {
  const { selected, id, setSelectedByType, type, setStates, options } = setSelectedProps;

  if (selected && id) {
    setSelectedByType(selected, type, setStates);
  } else if (requestForDefault) {
    const asc = sortRecordIdAscending(options);
    const def = asc.find((option: IOption) => option.default === true);
    
    if (def) setSelectedByType(def, type, setStates);
    else setSelectedByType(asc[0], type, setStates);
  }
};

export const getOptionsByType = (value: any, type: IComboBoxIds) => {
  const options = { recordId: value.recordId || 0, label: '', default: value.default || false };

  if (type === 'client') {
    options['parentClient'] = value.parentClient;
    options['parentClientFk'] = value?.parentClientFk;
    options['currencyId'] = value?.currencyId;
  }

  if (type === 'bbPeriod') {
    options['endDate'] = value?.endDate;
  }

  options.label = getLabelByType(value, type);
  return options;
};

export const getLabelByType = (value: any, type: IComboBoxIds) => {
  const labels = {
    'client': value.borrowerName,
    'invCollateral': value.invCollateralName,
    'arCollateral': value.arCollateralName,
    'customer': value.custName,
    'bbPeriod': formatDate(value.endDate || '', AMERICAN_DATE_FORMAT),
    'bbCalcDate': formatDate(value.endDate || '', AMERICAN_DATE_FORMAT),
  }

  return labels[type] ?? '';
};

export const getParamsByType = (type: IComboBoxIds, params: IComboBoxParams) => {
  const parameters = {
    'client': { key: 'clientId', params: params.borrowerId },
    'invCollateral': { key: 'invCollateralId', params: params.invCollateralId },
    'arCollateral': { key: 'arCollateralId', params: params.arCollateralId },
    'customer': { key: 'customerId', params: params.customerId },
    'bbPeriod': { key: 'bbPeriodId', params: params.bbPeriodId },
    'bbCalcDate': { key: 'bbPeriodId', params: params.bbPeriodId },
    'type': { key: 'bucketType', params: params.bucketType },
    'currency': { key: 'currencyId', params: params.currencyId },
  }

  return parameters[type] ?? { key: '', params: '0' };
};

export const getARIneligibleDetailsRequest = (row: IARIneligible) => {
  const { borrowerId, arCollateralId, bbPeriodId, arCustomerId, ineligibleCode } = row;

  return (
    axiosInstance.request({
      url: reportsAPI.arIneligibleReport.GET_INELIGIBLE_DETAILS,
      method: GET,
      params: { borrowerId, arCollateralId, bbPeriodId, arCustomerId, ineligibleCode },
    })
  )
}

export const getUpcARIneligibleDetailsRequest = (row: IARIneligible) => {
  const { parentClientId, arCollateralId, endDate, arCustomerId, ineligibleCode, isUpcParentCustomer } = row;

  return (
    axiosInstance.request({
      url: reportsAPI.arIneligibleReport.GET_UPC_INELIGIBLE_DETAILS,
      method: GET,
      params: {
        parentClientId,
        arCollateralId,
        asOfDate: formatDate(endDate, 'YYYYMMDD'),
        arCustomerId,
        ineligibleCode,
        isUpcParentCustomer: Boolean(isUpcParentCustomer)
      },
    })
  )
}
  
export const getClientRequest = async () => {
  let requestParams;
  const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
  const canViewClients = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_CLIENT);
  if (canViewAssignedClientsOnly && !canViewClients) {
    requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC', crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
  } else {
    requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC'}
  }
  const token = getLocalStorageItem('token');
  return axiosInstance.request({
    url: borrowerAPI.FIND_BY_CRITERIA,
    method: GET,
    params: requestParams,
    headers: { token: token !== undefined ? token : '' }
  });
}

export const getClientByIdRequest = (recordId: number) => request({
  url: `${borrowerAPI.FIND_BY_ID}/${recordId}`,
  method: GET
});

export const getChildClientsRequest = (parentId: number, isArchive: boolean) => request({
  url: `${borrowerAPI.FIND_CHILD_CLIENTS}`,
  method: GET,
  params: {
    parentClientFk: parentId,
    isArchive,
  },
});

export const getCollateralRequest = (borrowerId: number) => axiosInstance.request({
  url: inventoryAPI.FIND_IS_ARCHIVED,
  method: GET,
  params: { borrowerId, isArchived: false, pageNo: 0, pageSize: 99999, sortBy: 'invCollateralName,ASC' }
});

export const getCollateralRequestByInput = (borrowerId: number) => axiosInstance.request({
  url: inventoryAPI.FIND_IS_ARCHIVED_BY_INPUT,
  method: GET,
  params: { borrowerId, isArchived: false, pageNo: 0, pageSize: 99999, sortBy: 'invCollateralName,ASC' }
});

export const getDefaultCollateralRequest = (borrowerId: number) => axiosInstance.request({
  url: inventoryAPI.FIND_IS_ARCHIVED,
  method: GET,
  params: { borrowerId, isArchived: false, pageNo: 0, pageSize: 99999, sortBy: 'recordId,ASC' }
});

export const getARCollateralRequest = (borrowerId: number) => axiosInstance.request({
  url: arCollateralAPI.FIND_IS_ARCHIVED,
  method: GET,
  params: { borrowerId, isArchived: false, sortBy: 'arCollateralName,ASC' }
});

export const getARCollateralRequestByBorrowerInput = (borrowerId: number) => axiosInstance.request({
  url: arCollateralAPI.FIND_IS_ARCHIVED_BY_BORROWER_INPUT,
  method: GET,
  params: { borrowerId, isArchived: false, pageNo: 0, pageSize: 99999, sortBy: 'arCollateralName,ASC' }
});

export const getDefaultARCollateralRequest = (borrowerId: number) => axiosInstance.request({
  url: arCollateralAPI.FIND_IS_ARCHIVED,
  method: GET,
  params: { borrowerId, isArchived: false, pageNo: 0, pageSize: 99999, sortBy: 'recordId,ASC' }
});

export const getBBPeriodRequest = (borrowerId: number) => axiosInstance.request({
  url: bbPeriodAPI.FIND_BY_BORROWER_ID,
  method: GET,
  params: { borrowerId, pageNo: 0, pageSize: 99999, sortBy: 'endDate,DESC' }
});

export const getBBCalcDatesRequest = (borrowerId: number) => axiosInstance.request({
  url: bbCalcDateAPI.FIND_DATES_BY_BORROWER_ID,
  method: GET,
  params: { borrowerId }
});

export const getCalcBBPeriodRequestByBorrowerId = (borrowerId: number) => axiosInstance.request({
  url: bbPeriodAPI.FIND_CALC_BBPERIOD_BY_BORROWER_ID,
  method: GET,
  params: { borrowerId }
});

export const getCalcBBPeriodRequestByARCollateralId = (arCollateralId: number) => axiosInstance.request({
  url: bbPeriodAPI.FIND_CALC_BBPERIOD_BY_AR_COLLATERAL_ID,
  method: GET,
  params: { arCollateralId }
});

export const getBBPeriodWithTransactionsRequest = (borrowerId: number) => axiosInstance.request({
  url: arTransactionAPI.GET_BB_PERIODS_BY_BORROWER_ID,
  method: GET,
  params: { borrowerId }
});

export const getBBPeriodWithTransactionsRequestByARCollateralId = (arCollateralId: number) => axiosInstance.request({
  url: arTransactionAPI.GET_BB_PERIODS_BY_AR_COLLATERAL_ID,
  method: GET,
  params: { arCollateralId }
});

export const getBBPeriodsWithRollForwardReport = (borrowerId: number, arCollateralId: number) => axiosInstance.request({
  url: reportsAPI.rollForwardReport.GET_AVAILABLE_BB_PERIODS,
  method: GET,
  params: { borrowerId, arCollateralId }
});

export const getBBPeriodsWithAPTransactions = (arCollateralId: number) => axiosInstance.request({
  url: reportsAPI.apAgingReport.GET_AVAILABLE_BB_PERIODS,
  method: GET,
  params: { arCollateralId }
});

export const getCustomerRequest = (arCollateralId: number) => axiosInstance.request({
  url: arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
  method: GET,
  params: { arCollateralId, pageNo: 0, pageSize: 99999, sortBy: 'custName,ASC' }
});

export const getCustomerByBorrowerIdRequest = (borrowerId: number) => request({
  url: arCustomersAPI.FIND_BY_BORROWER_ID,
  method: GET,
  params: { borrowerId, pageNo: 0, pageSize: 99999, sortBy: 'custName,ASC' }
});

export const getParentCustomerByParentBorrowerId = (parentId: number) => request({
  url: arCustomersAPI.FIND_PARENT_CUSTOMER_BY_PARENT_BORROWER_ID,
  method: GET,
  params: { 
    parentId,
  },
});
export const clearAllNewCustomers = (args: { arCollateralId?: number, borrowerId?: number }) => request({
  url: arCustomersAPI.CLEAR_ALL_NEW_CUSTOMERS_BY_CRITERIA,
  method: PATCH,
  params: args
});

export const clearNewCustomersByIds = (arCustomerIds: number[]) => request({
  url: arCustomersAPI.CLEAR_NEW_CUSTOMERS_BY_IDS,
  method: PATCH,
  data: arCustomerIds,
})

export const getApplicableCustomersRequest = (arCollateralId: number, bbPeriodId: number | undefined, payload: any[]) => axiosInstance.request({
  url: `${ineligibleSettingAPI.GET_APPLICABLE_CUSTOMERS}/${arCollateralId}`,
  method: POST,
  params: {
    getAllApplicable: true,
    bbPeriodId: bbPeriodId
  },
  data: payload,
});

export const getApplicableCustomersUpcRequest = (parentBorrowerId: number, endDate: string, payload: any[]) => request({
  url: `${ineligibleSettingAPI.GET_APPLICABLE_CUSTOMERS_UPC}/${parentBorrowerId}`,
  method: POST,
  params: {
    getAllApplicable: false,
    endDate,
  },
  data: payload,
});

export const getVendorByCriteriaRequest = (params: { 
  borrowerId?: number, 
  arCollateralId?: number, 
  vendorName?: string, 
  vendorSrcId?: string, 
  custName?: string, 
  custSrcId?: string, 
  pageNo?: number,
  pageSize?: number,
  sortBy?: string,
}) => request({
  url: arVendorsAPI.FIND_BY_CRITERIA,
  method: GET,
  params: params
});

export const clearAllNewVendors = (args: { arCollateralId?: number, borrowerId?: number }) => request({
  url: arVendorsAPI.CLEAR_ALL_NEW_VENDORS_BY_CRITERIA,
  method: PATCH,
  params: args
});

export const clearNewVendorsByIds = (arVendorIds: number[]) => request({
  url: arVendorsAPI.CLEAR_NEW_VENDORS_BY_IDS,
  method: PATCH,
  data: arVendorIds,
});

export const getIneligibleSettings = (borrowerId: number, arCollateralId: number) => axiosInstance.request({
  url: ineligibleSettingAPI.FIND_BY_BORROWER_ID_AND_AR_COLLATERAL_ID,
  method: GET,
  params: { borrowerId, arCollateralId }
});

export const getIneligibleRule = (ineligibleSettingId: number, arCollateralId: number) => axiosInstance.request({
  url: ineligibleRuleAPI.FIND_BY_INELIGIBLE_SETTING_ID,
  method: GET,
  params: { ineligibleSettingId, arCollateralId, pageSize: 99999 }
});

export const calculateIneligibles = (borrowerId: number, bbPeriodId: number, arCollateralId: number) => axiosInstance.request({
  url: bbCalcSummaryCustomerAPI.CALCULATE_INELIGIBLES,
  method: POST,
  params: {
    borrowerId: borrowerId,
    bbPeriodId: bbPeriodId,
    arCollateralId: arCollateralId,
  },
});

export const getDocumentAttributesByCriteria = (borrowerId: number, documentTypeId: number) => axiosInstance.request({
  url: documentAttributeAPI.FIND_BY_CRITERIA,
  method: GET,
  params: {
    borrowerId,
    documentTypeId,
  },
});

export const getDocumentAttributesByBorrowerId = (borrowerId: number) => request({
  url: documentAttributeAPI.FIND_BY_BORROWER_ID,
  method: GET,
  params: {
    borrowerId,
  },
});

export const getDocumentAttributesByParentId = (parentId: number) => request({
  url: documentAttributeAPI.FIND_BY_PARENT_ID,
  method: GET,
  params: {
    parentId,
  },
});

export const addDocumentAttribute = (payload: IDocumentAttributePayload) => axiosInstance.request({
  url: documentAttributeAPI.ADD_DOCUMENT_ATTRIBUTE,
  method: POST,
  data: payload,
});

export const deleteDocumentAttribute = (recordId: number) => axiosInstance.request({
  url: `${documentAttributeAPI.DELETE_DOCUMENT_ATTRIBUTE_BY_ID}/${recordId}`,
  method: DELETE,
});

export const getCollateralRuleFieldList = (documentId: number, documentTypeId: number) => request({
  url: collateralRuleAPI.GET_FIELD_LIST,
  method: GET,
  params: {
    documentId,
    documentTypeId,
  },
});

export const getRuleConditionFieldListByParentId = ({ parentId, pageNo, pageSize, sortBy }: { parentId: number, pageNo?: number, pageSize?: number, sortBy?: string }) => request({
  url: `${ruleConditionFieldListAPI.FIND_FIELD_LIST_BY_PARENT_ID}/${parentId}`,
  method: GET,
  params: {
    ...(pageNo !== undefined && { pageNo }),
    ...(pageSize !== undefined && { pageSize }),
    ...(sortBy !== undefined && { sortBy }),
  },
});

export const getRuleConditionValueListByParentId = ({ parentId, documentTypeId, tableField, isUserDefined }: { parentId: number, documentTypeId: number, tableField: string, isUserDefined: boolean }) => request({
  url: ruleConditionFieldListAPI.FIND_VALUE_LIST_BY_PARENT_ID,
  method: GET,
  params: {
    parentFk: parentId,
    documentTypeFk: documentTypeId,
    tableField,
    isUserDefined,
  },
});

export const getBorrowingBaseReportByBorrowerIdAndBbCalcDateId = (borrowerId: number, calcDate: string) => request({
  url: borrowingBaseReportAPI.GET_BB_REPORT,
  method: GET,
  params: {
    borrowerId,
    calcDate,
  }
});

export const getInvBorrowingBaseList = (borrowerId: number) => request({
  url: borrowingBaseReportAPI.GET_INV_BB_LIST,
  method: GET,
  params: {
    borrowerId,
  },
});

export const getARBorrowingBaseTotals = (borrowerId: number, endDate: string) => request({
  url: borrowingBaseReportAPI.GET_AR_BB_REPORT_LIST_TOTALS,
  method: GET,
  params: {
    borrowerId, endDate
  },
});

export const checkHasPaymentTermsRequest = (arCollateralId: number, bbPeriodId?: number) => axiosInstance.request({
  url: `${API_DOMAIN}/ar/transactions/hasPaymentTerms`,
  method: GET,
  params: { arCollateralId, ...(bbPeriodId !== undefined && { bbPeriodId: bbPeriodId }) }
});

export const checkHasPaymentTermsOnParentBorrowerRequest = (parentBorrowerId: number) => axiosInstance.request({
  url: `${API_DOMAIN}/ar/transactions/hasPaymentTermsOnParentBorrower`,
  method: GET,
  params: { parentBorrowerId }
});

export const formatBytes = (bytes: number = 0, decimals = 2) => {
  if (!+bytes) return '0 bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

export const getHeaderTooltip = (orderBy: any, order: Order, headCell: any) => {
  const active = orderBy === headCell.id;
  if (order === 'asc' || !active) {
    return 'Sort rows by this header in descending order';
  } else {
    return 'Sort rows by this header in ascending order';
  }
};

export const trimOnBlur = (e: any, formik: FormikProps<any>, name: string, value?: string) => {
  const trimmed = value ? value.trim() : value;
  formik.setFieldValue(name, trimmed);
  formik.handleBlur(e);
};

export const getOptionLabel = (option: IOption) => option.label;
export const isOptionEqualToValue = (option: IOption, value: IOption) => option.recordId === value.recordId;

export const getUserOptionLabel = (option: IUserAPI) => `${option.firstName} ${option.lastName}`;
export const isUserOptionEqualToValue = (option: IUserAPI, value: IUserAPI) => option.id === value.id;

export const getDefaultMapping = (uploadedFile: IUploadedFile, values: string[][], isDirty: boolean, documentMappings?: any) => {
  // if selected sheet & excluded rows are the same, and there is a saved data mapping table fields, return the saved data mapping table fields
  if (!isDirty && uploadedFile.dataMappingTableFields) {
    const final: IDocumentMappings = {
      fieldMappings: uploadedFile.dataMappingTableFields,
      changeAmt2Sign: Boolean(uploadedFile.dataMappingChangeAmt2Sign),
    }
    return final;
  } else {
    return getSheetMapping(uploadedFile, values, documentMappings);
  }
}

export const getSheetMapping = (uploadedFile: IUploadedFile, values: string[][], documentMappings?: any) => {
  const existingMappings = documentMappings?.fieldMappings as IExistingMapping[];
  const allMappings = existingMappings?.map(existing => existing.mapping);
  const tableFields = fieldsPerDocumentType[uploadedFile.documentType ?? ''] ?? [];
  const loweredFields = tableFields
    .filter((tableFields) => !allMappings?.includes(tableFields.value))
    .map((tableFields) => ({ name: tableFields.name.toLowerCase(), value: tableFields.value }));

  const matchingFields = values[0].reduce((arr: (string | null)[], curr: string) => {
    const matchingMapping = existingMappings?.find(existing => {
      const headerBlank = checkIfBlankString(existing.header);
      const matched = existing.header.toLowerCase() === curr.toLowerCase();
      return !headerBlank && matched;
    });

    if (matchingMapping && !arr.includes(matchingMapping.mapping)) {
      arr.push(matchingMapping.mapping);
      return arr;
    }

    const matchingField = loweredFields.find((tableField) => tableField.name === curr.toLowerCase());
    if (matchingField && !arr.includes(matchingField.value)) {
      arr.push(matchingField.value);
      return arr;
    } else {
      arr.push(null);
      return arr;
    }
  }, []);

  const final: IDocumentMappings = {
    fieldMappings: matchingFields,
    changeAmt2Sign: Boolean(documentMappings?.changeAmt2Sign)
  }

  return final;
}

export const getExistingMappings = async (arCollateralId: number | undefined, documentTypeId: number) => {
  try {
    const response = await axiosInstance.request({
      url: fileImportAPI.dataMapping.GET_EXISTING_MAPPINGS,
      method: GET,
      params: { arCollateralId, documentTypeId }
    });
    return response.data;
  } catch (error) {
    console.log('GET EXISTING MAPPING ERROR: ', error);
    return ({
      fieldMappings: [],
      changeAmt2Sign: false
    });
  }
}

export const getExistingMappingsByBorrowerId = async (borrowerId: number, documentTypeId: number) => {
  try {
    const response = await axiosInstance.request({
      url: fileImportAPI.dataMapping.GET_EXISTING_MAPPINGS_BY_BORROWER,
      method: GET,
      params: { borrowerId, documentTypeId }
    });
    return response.data;
  } catch (error) {
    console.log('GET EXISTING MAPPING ERROR: ', error);
    return ({
      fieldMappings: [],
      changeAmt2Sign: false
    });
  }
}

export const getUsedUdfByArCollateralId = async (arCollateralId?: number) => {
  try {
    const response = await axiosInstance.request({
      url: fileImportAPI.dataMapping.GET_USED_UDF_BY_AR_COLLATERAL_ID,
      method: GET,
      params: { arCollateralId }
    });
    return response.data;
  } catch (error) {
    console.log('GET USED USER DEFINED FIELDS ERROR: ', error);
    return [];
  }
}

export const getUsedUdfByParentClientId = async (parentClientId?: number) => {
  try {
    const response = await axiosInstance.request({
      url: fileImportAPI.dataMapping.GET_USED_UDF_BY_PARENT_CLIENT_ID,
      method: GET,
      params: { parentClientId }
    });
    return response.data;
  } catch (error) {
    console.log('GET USED USER DEFINED FIELDS ERROR: ', error);
    return [];
  }
}

export const getMIMEtype = (ext: 'csv' | 'xls' | 'xlsx') => {
  if (ext === 'csv') {
    return { type: `${MIME_TYPES[ext]};charset=utf-8;`};
  } else {
    return { type: `${MIME_TYPES[ext]};`};
  }
}

export const checkIfSupportedExtension = (ext: string) => {
  if (ext === 'csv' || ext === 'xls' || ext === 'xlsx') {
    return getMIMEtype(ext);
  } else {
    return { type: '' };
  }
}

export const getFileExtension = (filename: string) => {
  const index: number = filename.lastIndexOf('.');

  if (index !== -1) {
    return filename.substring(index + 1);
  } else {
    return "Invalid file extension";
  }
}

export const getFileValuesByDocument = async (uploadedFile: IUploadedFile) => {
  try{
    const fileExtension = getFileExtension(uploadedFile.filename);
    let file: any;

    if (uploadedFile.file) {
      file = uploadedFile.file;
    } else {
      const formData = new FormData();
      formData.append('filename', uploadedFile.filename);
      formData.append('borrowerId', uploadedFile.borrowerFk.toString());
  
      const response = await axiosInstance.request({
        url: fileImportAPI.uploadFile.DOWNLOAD_FILE,
        method: POST,
        responseType: 'blob',
        data: { filename: uploadedFile.filename, borrowerId: uploadedFile.borrowerFk.toString()},
        headers: { 'content-type': 'multipart/form-data' }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv;charset=utf-8;'}));
      file = await fetch(url)
        .then(r => r.blob())
        .then(blobFile => new File(
          [blobFile],
          uploadedFile.filename,
          checkIfSupportedExtension(fileExtension)
        ))
    }

    if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      return await parseExcel(file, uploadedFile);
    } else {
      return await parseCsv(file);
    }
  } catch (error) {
    console.log('DOWNLOAD FILE ERROR : ', error);
  };
};

export const parseExcel = async (file: File, uploadedFile: IUploadedFile) => {
  const data: Array<string[]> = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target?.result;
      const workbook = XLSX.read(buffer, { type: 'array', cellStyles: true, dense: true, sheets: uploadedFile.selectedSheet });
      const worksheet = workbook.Sheets[uploadedFile.selectedSheet ?? workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_csv(worksheet, { blankrows: false, skipHidden: false })

      resolve(parseCsv(jsonData));
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });

  return data;
}

export const parseCsv = async (file: File | string) => {
  const data: Array<string[]> = await new Promise(function(resolve, reject){
    Papa.parse(file, {
      header: false,
      delimiter: ",",
      skipEmptyLines: 'greedy',
      worker: true,
      complete: function (results: ParseResult<any>) {
        resolve(results.data);
      },
      error: function(err: any) {
        reject(err);
      }
    })
  })
  return data;
};

export const getAdditionalKeysInPayload = (documentType: string) => {
  const customers: string[] = ['borrowerId', 'arCollateralId', 'documentId'];
  const transactional: string[]  = [...customers, 'bbPeriodId', 'shouldReplace'];

  const additionalKeysInPayload = {
    'AR Aging': transactional,
    'AP Aging': transactional,
    'GL Transaction': transactional,
    'Customer List': customers,
    'Vendor List': customers,
  }

  return additionalKeysInPayload[documentType] ?? [];
}

export const getAllKeysInPayload = (headers: (string | null)[], documentType: string) => {
  const filteredHeaders = headers.filter(header => header);
  return [...filteredHeaders, ...getAdditionalKeysInPayload(documentType)];
}

export const payloadToCsv = (payload: any[], headers: (string | null)[], documentType: string) => {
  const allKeys = getAllKeysInPayload(headers, documentType);
  const csv = Papa.unparse(payload, { columns: allKeys });
  return csv;
}

export const csvToFile = (csv: string) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  return new File([blob], 'payload.csv', { type: 'text/csv' });
}

export const checkIfBlankString = (str: string) => {
  return str.trim() === '' || str === null || str === 'NULL' || str === 'null';
};

export const checkRequiredFieldsFilled = (requiredFields: string[], optionalFields: string[], defaultMapping: any, cleanedValues: any) => {
  const nullColumns: string[] = [];

  const count: {[key: string]: Array<{[key: number]: string[]}>} = {};
  let columnHeaderForDocNum: string = '';

  const requiredFieldsMapping = [...requiredFields, ...optionalFields].map((field: string) => {
    const headerIndex = defaultMapping.indexOf(field);
    const isOptional = optionalFields.includes(field);
    if (headerIndex === -1) return isOptional;
  
    const requiredValues = cleanedValues.map((value: string[], index: number) => {
      const current = value[headerIndex];

      if (field === 'arTrxNo') {
        const currentValues = count[current] || [];
        const rowNumber: number = index + 1;
        const rowToVal: {[key: number]: string[]} = {[rowNumber]: value};
        count[current] = [...currentValues, rowToVal];
        if (index === 0) columnHeaderForDocNum = current;
      }

      return current;
    });

    const filteredItems = requiredValues.filter((item: string) => !checkIfBlankString(item));
    if (requiredValues.length !== filteredItems.length && requiredValues.length) nullColumns.push(defaultMapping[headerIndex]);

    if (isOptional) return isOptional;
    else return requiredValues.length === filteredItems.length;
  });

  const duplicates: Array<{[key: number]: string[]}> = Object.values(count).filter(values => values.length > 1).flatMap(values => values);

  return { requiredFieldsMapping, nullColumns, duplicates, columnHeaderForDocNum };
}

export const createNullMessage = (nullColumns: string[]) => {
  const mapped = nullColumns.map(column => findFieldInFieldList(column));

  switch (mapped.length) {
    case 0: return '';
    case 1: return mapped[0];
    default: return mapped.reduce((prevValue: string, currValue: string, currentIndex: number, array: string[]) => {
                      const lastIndex = array.length - 1;
                      if (currentIndex === lastIndex) return prevValue + `and ${currValue}`
                      else return prevValue + `${currValue}, `
                    }, '');
  }
};

export const createDuplicateWarning = (duplicateDocNums: {[key: number]: string[];}[], fileHeader: string) => {
  const cleaned = replaceNewlinesWithSpaces(fileHeader);
  const initialString = `${cleaned === 'Document #' ? 'Document #' : cleaned + " (Document #)"} has duplicate values. See row(s)${[...duplicateDocNums]
    .slice(0, 3)
    .sort((a, b) => {
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];
      return Number(keyA) - Number(keyB);
    }).map((row, index, array) => {
      const rowNumber = Object.keys(row);
      const isLastRow = index === duplicateDocNums.length - 1;

      if (array.length === 1) return (` ${rowNumber[0]}.`)
      else if (isLastRow) return (` and ${rowNumber}.`);
      else return (` ${rowNumber[0]}`)
    })}`

    if (duplicateDocNums.length > 3) {
      const additionalString = ` and ${duplicateDocNums?.length - 3} more rows.`;
      return initialString.concat(additionalString);
    }

  return initialString;
}

export const createGeneralWarningMessage = (initialString: string, columnsToWarn: string[]) => {
  const mapped = columnsToWarn.map(column => findFieldInFieldList(column));

  const noMappedString = mapped.reduce((accumulator: string, current: string, currentIndex: number, array: string[]) => {
    const lastRow = currentIndex === array.length - 1;

    if (array.length === 1) return accumulator + `${current}.`
    else if (lastRow) return accumulator + `and ${current}.`
    else return accumulator + `${current}, `;
  }, '');

  return initialString.concat(noMappedString);
}

export const createBulletedMessage = (messages: string[]) => {
  return messages.reduce((accumulator, current) => {
    accumulator += `* ${current} \r\n`;
    return accumulator;
  }, '');
}

export const replaceNewlinesWithSpaces = (string: string) => {
  const newlineRegex = /\n/g;
  const newString = string.replace(newlineRegex, " ");
  return newString;
}

export const createOrdinalStrings = (index: number) => {
  const suffixes = { 1: 'st', 2: 'nd', 3: 'rd' };
  const suffix = (index + 1) % 10 in suffixes ? suffixes[(index + 1) % 10] : 'th';
  return (index + 1) + suffix;
}

export const findFieldInFieldList = (fieldName: string) => {
  const field = fieldTypeList.find(fieldList => fieldList.fieldName === fieldName);
  if (field) return field.field;
  else return fieldName;
}

export const getTableRowsAfterPaging = (page: number, rowsPerPage: number, values: any[]) => {
  const startingIndex = page * rowsPerPage;
  const exclusiveEndingIndex = (page + 1) * (rowsPerPage === -1 ? values.length : rowsPerPage);
  return values.slice(startingIndex, exclusiveEndingIndex);
};

export const getTooltip = (event: any) => {
  if (event.currentTarget) {
    const target = event.currentTarget as HTMLElement;
    if (target.offsetWidth < target.scrollWidth && !target.getAttribute('title')) {
      target.setAttribute('title', target.innerHTML);
    }
  }
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export const fileToBase64 = (file: File): Promise<string> => {
return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
  })
}

export const base64ToArrayBuffer = (base64: string[]) => {
  const combinedByteArray = base64.map(chunk => window.atob(chunk)).join('');
  const bytes = new Uint8Array(combinedByteArray.length);
  for (let i = 0; i < combinedByteArray.length; i++) {
    const ascii = combinedByteArray.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const saveByteArray = (filename: string, bytes: Uint8Array) => {
  const ext = getFileExtension(filename);
  const mimeType = checkIfSupportedExtension(ext);
  
  const blob = new Blob([bytes], { ...mimeType });
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const getFieldMatchingValue = (fieldTable: string, fieldType: string) => {
  const fields = {
    'ARTransaction': dataMappingFields.arAging,
    'ARCustomer': dataMappingFields.customerList,
  }

  const fieldList: any[] = fields[fieldTable] ?? dataMappingFields.arAging;
  return fieldList.find((field) => field.name === fieldType).value;
}

export const getFieldMatchingLabel = (fieldTable: string, fieldName: string) => {
  const fields = {
    'ARTransaction': dataMappingFields.arAging,
    'ARCustomer': dataMappingFields.customerList,
  }

  const fieldList: any[] = fields[fieldTable] ?? dataMappingFields.arAging;
  return fieldList.find((field) => field.value === fieldName).name;
}

export const getFallbackString = (str?: string | number) => {
  if (str) return `${str}`;
	else return '';
}

export const getResponseMessage = (statusCode: number, entity?: string, name?: string) => {
  switch (statusCode) {
    case STATUS_CODES.OK:
    case STATUS_CODES.CREATED:
      return `${name ?? ""} has been successfully saved!`;
    case STATUS_CODES.CONFLICT:
      return `${entity ?? ""} name already exists.`;
    default:
      return `Failed to save ${entity ?? ""}. Please check your inputs.`
  }
}

export const getRolesOfUser = async (userId: string, token?: string) => {
  try {
    const localStorageToken = getLocalStorageItem('token');
    const response = await axiosInstance.request({
      url: rolePermissionAPI.GET_ROLES_OF_USER,
      method: GET,
      headers: {
        token : token ?? (localStorageToken ?? ''),
      },
      params: {
        userId: userId
      }
    })
    
    const roles : IRoleAPI[] = response.data;

    // const roles : IRoleAPI[] = accessRoles.map(accessRole => {
    //   return {
    //     id: accessRole.roleId.toString(),
    //     name: accessRole.name,
    //     realmRoles: accessRole.permissions.map(permission => permission.name)
    //   }
    // })
    return roles;
  } catch (error) {
    console.log('GET ROLES OF USER ERROR:',error);
  }
}

export const getPermissionsOfUser = async (userId: string, token?: string) => {
  const roles = await getRolesOfUser(userId, token);
  let userPermissions : string[] = [];

  if (roles !== undefined) {
    roles.forEach(role => {
      role.realmRoles.forEach(permission => userPermissions.push(permission)) 
    });
  
    return userPermissions;
  } else {
    return ['error']
  }
}

export const checkUserPermissions = async (userId: string, permission: string, token?: string) => {
  try {
    const localStorageToken = getLocalStorageItem('token');
    const response = await axiosInstance.request({
      url: rolePermissionAPI.CHECK_PERMISSIONS_OF_USER,
      method: GET,
      headers: {
        token : token ?? (localStorageToken ?? ''),
      },
      params: {
        userId: userId,
        permission: permission
      }
    })
    const hasPermission : boolean = response.data;
    return hasPermission;
  } catch (error) {
    console.log('CHECK USER PERMISSIONS ERROR:',error);
  }
}

export const getTextWidth = (text: string, fontStyle: string) => {
  const canvas = document.createElement('canvas');
  let context = canvas.getContext('2d', { alpha: false});
  if (context === null || context === undefined) { return 0; }
  context.font = fontStyle;
  let metrics = context.measureText(text);
  return metrics.width;
};

export const getBorrowerInputByCollateralId = async (arCollateralId: number) => {
  const borrowerInput = await axiosInstance.request({
    url: arCollateralAPI.FIND_AR_SETTINGS_BY_AR_COLLATERAL_ID,
    method: GET,
    params: {arCollateralId: arCollateralId, isCurrent: true}
  })

  return borrowerInput.data.content[0] as IARBorrowerInput
}

export const getCurrencies = async (clientCurrencyId?: number) => {
  try {
    const allCurrencies = await axiosInstance.request({
      url: multiCurrencyAPI.GET_ALL_CURRENCIES,
      method: GET
    });
    
    if (allCurrencies) {
      let temp: ICurrencyWithClientCurrency = {
        currencies: [],
        clientCurrency: undefined
      }

      allCurrencies.data.content.forEach(currency => {
        if (clientCurrencyId && currency.recordId.toString() === clientCurrencyId.toString()) {
          temp.clientCurrency = currency
        } else { 
          if (currency?.currencyCode == 'USD' && currency?.currencyName == 'United States Dollar') {
            temp.currencies.unshift({
              recordId:     currency.recordId,
              currencyName: currency.currencyName,
              currencyCode: currency.currencyCode,
              symbol:       currency.symbol
            })
          } else (
            temp.currencies.push({
              recordId:     currency.recordId,
              currencyName: currency.currencyName,
              currencyCode: currency.currencyCode,
              symbol:       currency.symbol
          })
          )        
         
        }
      });

      return temp
    }
  } catch (error) {
    console.log('GET CURRENCIES ERROR: ', error);
  }
};

export const checkRate = async (arCollateralId: number, borrowerId: number, selectedAsOfDate: string) => {
  try {
    const borrowerInput = await getBorrowerInputByCollateralId(arCollateralId);
    const borrower      = await getClientByIdRequest(borrowerId);

    if (borrowerInput && borrower) {
      const allRates = await axiosInstance.request({
        url: multiCurrencyAPI.GET_ALL_RATES,
        method: GET,
        params: {
          borrowerId: borrower.data.recordId,
          fromCurrencyId: borrower.data.currencyId
        },
      });

      if (allRates) {
        if (borrower.data.currencyId.toString() === borrowerInput.currency) {
          return {currencyRate: 1}
        }
        
        return allRates.data.content.find(rate =>
          rate.toCurrencyId.toString() === borrowerInput.currency &&
          formatDate(rate.asOfDate, AMERICAN_DATE_FORMAT) === selectedAsOfDate
        )
      }
    }
  } catch (error) {
    console.log('CHECK RATE ERROR: ', error);
  }
};

export const generateSkeletonRows = (numRows: number, numColumns: number) => {
  const skeletonRows: {id: string, numColumns: number}[] = [];
  for (let i = 1; i <= numRows; i++) {
    const id = `unique_id_${i}`;
    skeletonRows.push({ id, numColumns });
  }
  return skeletonRows;
};

export const prependElement = (array: Array<any>, field: string, value: string): Array<any> => {
  const indexToMove = array.findIndex(obj => obj[field].toString() === value)
  if(indexToMove >= 0){
    array.unshift(array.splice(indexToMove, 1)[0]);
  }
  return array
}

export const toCamelCase = (inputString: string) => {
  return inputString
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(''); 
}

export const getUniqueKeys = (item: any) => {
  const randomBytes = crypto.randomBytes(4); // Adjust the number of bytes as needed
  const randomString = randomBytes.toString('hex');
  return `${item}-${randomString}`;
}

export const generateRandomString = (length: number): string => {
  return crypto.randomBytes(length).toString('hex').slice(0, length);
}

export const displayTextWidth = (text : string) => {
  const canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  let metrics = context?.measureText(text);
  return metrics?.width as unknown as number;
}

export const haveSameContentsAndPosition = (array1: any[], array2: any[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

export const handleDownloadFile = async (row: IUploadedFile) => {
  try {
    const formData = new FormData();
    formData.append('filename', row.filename);
    formData.append('borrowerId', row.borrowerFk.toString());

    const params = {
      filename: row.filename,
      borrowerId: row.borrowerFk
    };

    await generateDownloadLink(
      fileImportAPI.uploadFile.DOWNLOAD_FILE,
      params,
      undefined,
      row.filename
    )
  } catch (error) {
    console.log('DOWNLOAD FILE ERROR : ', error);
  };
};

export const generateDownloadLink = async (endPoint: string, params: any, data: any, filename: string) => {
  const response = await axiosInstance.request({
    url: endPoint,
    method: POST,
    responseType: 'blob',
    params,
    data,
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

/**
 * This function ensures that the provided ID is of type number.
 * It verifies the type of the given ID. If the ID is a string,
 * it will return 0 to ensure a numerical output. Otherwise, it returns the original ID.
 *
 * @param id - The ID to be checked.
 * @returns The numeric representation of the ID or 0 if it's a string.
 */
export const getNumber = (id: number | string) => {
  if (typeof id === 'string') return 0;
  else return id;
}

/**
 * This function reads an Excel file and extracts the names of its sheets.
 * @param uploadFile The Excel file from which to extract sheet names.
 * @returns A Promise that resolves with an array of sheet names.
 */
export const getSheetNames = async (uploadFile: IUploadedFile) => {
  const data: Promise<string[]> = new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target?.result;
      const workbook = XLSX.read(buffer, { type: 'array', bookSheets: true, sheetRows: 0, dense: true });
      resolve(workbook.SheetNames);
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsArrayBuffer(uploadFile.file ?? new Blob());
  });

  return data;
};

/**
 * This function validates objects if they exist or not
 * @param objects The objects to be validated
 * @returns True if all objects exist or false if at least one object is null or undefined
 */
export const validateObjects = (...objects) => {
  const valid = objects.filter((object) => {
    return object === null || typeof object === 'undefined';
  });

  return valid.length === 0;
}

/**
 * Checks and retrieves rates for a specific borrower, source currency, and list of target currencies on a given date.
 *
 * @param borrowerId The ID of the borrower.
 * @param romCurrencyId The ID of the source currency.
 * @param toCurrencyIds An array of target currency IDs to check rates for.
 * @param asOfDate The date for which rates are being checked (in string format).
 * @returns An array of rate objects if all target currencies are present, otherwise undefined.
 */
export const checkRatesByToCurrencyIds = async (borrowerId: number, fromCurrencyId: number, toCurrencyIds: number[], asOfDate: string) => {
  try {
    const allToCurrencyIdsEqualFromCurrencyId: boolean = toCurrencyIds.every(toCurrencyId => toCurrencyId === fromCurrencyId);

    if (toCurrencyIds.length === 0) {
      return undefined;
    }
    
    if (allToCurrencyIdsEqualFromCurrencyId) {
      return [{
        recordId: 1,
        asOfDate: formatDate(asOfDate, TIMESTAMP_WITH_ZONE),
        currencyRate: 1,
        userName: "",
        borrowerId,
        fromCurrencyId,
        toCurrencyId: fromCurrencyId
      }]
    }
    
    const response = await axiosInstance.request({
      url: multiCurrencyAPI.GET_ALL_RATES_BY_TO_CURRENCY_IDS,
      method: POST,
      params: { borrowerId, fromCurrencyId, asOfDate, },
      data: toCurrencyIds
    });
    const rates: IRate[] = response.data.content;
    if (rates.length === 0) {
      return undefined;
    }

    const availableToCurrencyIds: number[] = rates.map(rate => rate.toCurrencyId);
    const allToCurrencyIdsPresent: boolean = toCurrencyIds
      .filter(toCurrencyId => toCurrencyId !== fromCurrencyId)
      .every(toCurrencyId => {
        return availableToCurrencyIds.includes(toCurrencyId)
      });
    
    if (allToCurrencyIdsPresent) {
      return rates.filter(rate => toCurrencyIds.includes(rate.toCurrencyId));
    } else return undefined;
  } catch (error) {
    console.log('CHECKING RATES BY TO CURRENCY IDS ERROR: ', error);
  }
}

/**
   * This function gets the available as of dates for calculation.
   * @param borrowerId The record id of the Ultimate Parent Client.
   */
export const getAvailableUpcDatesForCalc = async (borrowerId: number) => {
  try {
    const response = await axiosInstance.request({
      url: arTransactionAPI.GET_AVAILABLE_UPC_DATES,
      method: GET,
      params: { parentClientId: borrowerId }
    });

    const endDates: string[] = response.data;
    const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
      recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
      label: formatDate(endDate, AMERICAN_DATE_FORMAT),
      default: index === 0
    }))
    return bbPeriodOpts;
  } catch (error) {
    console.log('GET AVAILABLE UPC AS OF DATES FOR CALCULATION ERROR: ', error);
  }
};

/**
   * This function gets the available as of dates for ap aging calculation.
   * @param borrowerId The record id of the Ultimate Parent Client.
   */
export const getAvailableUpcDatesForCalcForAPAging = async (borrowerId: number) => {
  try {
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/apTransactions/upc/findUpcAsOfDates`,
      method: GET,
      params: { parentClientId: borrowerId }
    });

    const endDates: string[] = response.data;
    const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
      recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
      label: formatDate(endDate, AMERICAN_DATE_FORMAT),
      default: index === 0
    }))
    return bbPeriodOpts;
  } catch (error) {
    console.log('GET AVAILABLE UPC AS OF DATES FOR AP AGING CALCULATION ERROR: ', error);
  }
};

/**
 * Retrieves a list of currency IDs associated with a borrower by making a request to the arCollateral API.
 * @param borrowerId The ID of the borrower for whom currency IDs are retrieved.
 * @returns A Promise that resolves to an array of currency IDs.
 */
export const getUpcToCurrencyIds = async (borrowerId: number, asOfDate: string) => {
  try {
    const response = await axiosInstance.request({
      url: arCollateralAPI.FIND_CURRENCY_IDS,
      method: GET,
      params: { parentClientId: borrowerId, asOfDate }
    });

    const ids: number[] = response.data;
    return ids;
  } catch (error) {
    console.log('GET UPC TO CURRENCY IDS ERROR: ', error);
  }
}

/**
 * Retrieves a list of currency IDs for AP Aging associated with a borrower by making a request to the arCollateral API.
 * @param borrowerId The ID of the borrower for whom currency IDs are retrieved.
 * @returns A Promise that resolves to an array of currency IDs.
 */
export const getUpcToCurrencyIdsForAPAging = async (borrowerId: number, asOfDate: string) => {
  try {
    const response = await axiosInstance.request({
      url: arCollateralAPI.FIND_CURRENCY_IDS_FOR_AP_AGING,
      method: GET,
      params: { parentClientId: borrowerId, asOfDate }
    });

    const ids: number[] = response.data;
    return ids;
  } catch (error) {
    console.log('GET UPC TO CURRENCY IDS FOR AP AGING ERROR: ', error);
  }
}

/**
 * Retrieves a list of currency IDs for GL Transaction associated with a borrower by making a request to the arCollateral API.
 * @param borrowerId The ID of the borrower for whom currency IDs are retrieved.
 * @returns A Promise that resolves to an array of currency IDs.
 */
export const getUpcToCurrencyIdsForGlTransaction = async (borrowerId: number, asOfDate: string) => {
  try {
    const response = await axiosInstance.request({
      url: arCollateralAPI.FIND_CURRENCY_IDS_FOR_GL_TRANSACTION,
      method: GET,
      params: { parentClientId: borrowerId, asOfDate }
    });

    const ids: number[] = response.data;
    return ids;
  } catch (error) {
    console.log('GET UPC TO CURRENCY IDS FOR GL TRANSACTION ERROR: ', error);
  }
}

export const getClientByID = (recordId: number, clients: IClient[]) => clients.find(client => client.recordId === recordId) ?? null;

export const getARCollateralByID = (recordId: number, arCollaterals: IARCollateral[]) => arCollaterals.find(arCollateral => arCollateral.recordId === recordId) ?? null;

export const cleanPayload = (obj: any) => {
  const shallow = {...obj};
  const isRemainingPayloadAllAmountHeaders = Object.keys(shallow).every(key => amtHeaders.includes(key));
  if (isRemainingPayloadAllAmountHeaders) {
    for (const [key, value] of Object.entries(shallow)) {
      if (value === 0.00) {
        delete shallow[key];
      }
    }
  }
  return shallow;
}

export const formatNumberWithCurrencySymbol = (number: number, currencySymbol?: string) => {
  // Determine currencySymbol
  const symbol = currencySymbol ?? '';

  // Check if the number is negative
  let isNegative = number < 0;

  // Take the absolute value of the number
  number = Math.abs(number);

  // Convert the number to a string and split it into integer and decimal parts
  let parts = number.toFixed(2).toString().split(".");

  // Add commas for thousands separator
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate symbol, integer and decimal parts
  let formattedNumber = symbol + parts.join(".");

  // If the number was negative, add parentheses
  if (isNegative) {
      formattedNumber = "(" + formattedNumber + ")";
  }

  return formattedNumber;
}

export const timeout = async (milliseconds: number) => await new Promise(res => setTimeout(res, milliseconds));

export const cleanValues = (sheetValues: string[][]) => 
  sheetValues.filter((value: string[]) => !value.every((item: string) => checkIfBlankString(item)));

export const excludeRows = (sheetValues: string[][], excludedRowsEndRowNum: number | undefined) => {
  return sheetValues.filter((value: string[], index: number) => {
    const startingIndex = excludedRowsEndRowNum === undefined ? 0 : excludedRowsEndRowNum + 1;
    return index >= startingIndex;
  });
};

export const customAsyncStagingRequest = async (apiRequests: any[], dataMapped: any[], batchSize: number, concurrencyLimit: number) => {
  let statusCode: number = 0;
  const totalChunks = Math.ceil(dataMapped.length / batchSize);
  const chunks: any[] = [];
  
  // Divide data into chunks
  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const startIndex = chunkIndex * batchSize;
    const endIndex = Math.min(startIndex + batchSize, dataMapped.length);
    const chunk = dataMapped.slice(startIndex, endIndex);
    chunks.push(chunk);
  }

  // Send requests in batches with the specified concurrency limit
  for (let i = 0; i < totalChunks; i += concurrencyLimit) {
    const batch = chunks.slice(i, i + concurrencyLimit);
    const requests = batch.map(chunk => {
      const staging = apiRequests[0];
      staging['data'] = chunk;
      return axiosInstance.request(staging);
    });

    // Wait for all requests in the batch to complete
    await Promise.all(requests)
      .then((responses) => {
          responses.forEach((response) => {
              statusCode = response.status;
          });
      })
      .catch((error) => {
        console.log('Concurrent Request Error: ', error);
        throw new Error();
      });
  }
  
  return statusCode;
}

export const customAsyncStagingRequestWithCsv = async (
  apiRequests: any[],
  dataMapped: any[],
  headers: (string | null)[],
  documentType: string,
  batchSize: number,
  concurrencyLimit: number
) => {
  let statusCode: number = 0;
  const totalChunks = Math.ceil(dataMapped.length / batchSize);
  const chunks: File[] = [];
  
  // Divide data into chunks
  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const startIndex = chunkIndex * batchSize;
    const endIndex = Math.min(startIndex + batchSize, dataMapped.length);
    const chunk = dataMapped.slice(startIndex, endIndex);
    const csv = payloadToCsv(chunk, headers, documentType);
    const csvFile = csvToFile(csv);
    chunks.push(csvFile);
  }

  // Send requests in batches with the specified concurrency limit
  for (let i = 0; i < totalChunks; i += concurrencyLimit) {
    const batch = chunks.slice(i, i + concurrencyLimit);
    const requests = batch.map(chunk => {
      const staging = apiRequests[0];
      staging['data']['file'] = chunk;
      return axiosInstance.request(staging);
    });

    // Wait for all requests in the batch to complete
    await Promise.all(requests)
      .then((responses) => {
        responses.forEach((response) => {
          statusCode = response.status;
        });
      })
      .catch((error) => {
        console.log('Concurrent Request Error: ', error);
        throw new Error();
      });
  }
  
  return statusCode;
}

export const getParentClientHasDefault = (clientInfo: IClientInfo[], formik: FormikProps<IClientFormikValues> | FormikProps<IClientSettingsFormikValues>) => {
  const parentClientFk = formik.values.parentClientFk;

  const client = clientInfo.find(client => client.recordId === parentClientFk);
  if (!client) {
    return false;
  }

  return client.parentHasDefault;
}

export const checkFileNameIfExisting = (exports: IExportReportWithPayload[], filename: string, reportName: string) => {
  let fileNameToExport = filename;
  const originalFileNames = exports
    .filter(newExport => newExport.originalFileName?.includes(reportName))
    .map(newExport => newExport.originalFileName);

  if (originalFileNames.includes(filename)) {
    let dotIndex = filename.lastIndexOf('.');
    let baseName: string, extension: string;

    if (dotIndex !== -1) {
      baseName = filename.substring(0, dotIndex);
      extension = filename.substring(dotIndex);
    } else {
      baseName = filename;
      extension = '';
    }

    fileNameToExport = `${baseName} (${originalFileNames.length})${extension}`;
  }
  return fileNameToExport;
}

export const haveSamePositions = (itemsToCheck: string[], array1: (string | null)[], array2: (string | null)[]) => {
  // Filter out null and undefined values from array1 and array2
  const filteredArray1 = array1.filter(item => item !== null && item !== undefined) as string[];
  const filteredArray2 = array2.filter(item => item !== null && item !== undefined) as string[];

  // Get headers that are present in both arrays and in itemsToCheck
  const existingHeadersSet = new Set([...filteredArray1, ...filteredArray2].filter(item => itemsToCheck.includes(item)));
  
  // Get positions
  const samePositions: boolean[] = Array.from(existingHeadersSet).map(header => {
    const index1 = array1.indexOf(header);
    const index2 = array2.indexOf(header);
    return index1 === index2;
  });

  return samePositions.every(position => position);
}

export const generateBorrowingBaseReport = async (options: {
  borrowerId: number,
  endDate: string, 
  bbCalcDateId: number,
}) => {
  const { borrowerId, endDate, bbCalcDateId } = options;
  let arBbReport: Array<IARBorrowingBase>;
  let arBbTotals: IARBorrowingBaseTotals;
  let invBbReport: Array<IInvBorrowingBase>;
  let otherCollateralReport: IOtherCollateralReport;
  try {
    const arBorrowingBaseRes = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/bbReports/search/findByBorrowerId`,
      method: GET,
      params: { borrowerId, endDate }
    });

    arBbReport = arBorrowingBaseRes.data;

    const arBBTotalRes = await getARBorrowingBaseTotals(borrowerId, endDate);
    arBbTotals = arBBTotalRes.data;

    const invBorrowingBaseRes = await getInvBorrowingBaseList(borrowerId);

    invBbReport = invBorrowingBaseRes.data;

    const otherCollateralRes = await request({
      url: `${API_DOMAIN}/bb/bbReports?borrowerId=${borrowerId}`,
      method: GET,
    });

    otherCollateralReport = otherCollateralRes.data;

    const borrowingBaseReport = {
      borrowerFk: borrowerId,
      bbCalcDateFk: bbCalcDateId,
      calcDate: endDate,
      arCollateralReport: arBbReport,
      totalGrossAr: arBbTotals.totalGrossAr,
      totalAvailAr: arBbTotals.totalAvailAr,
      effectiveAdvRate: arBbTotals.effectiveAdvRate,
      invCollateralReport: invBbReport,
      otherCollateralReport: otherCollateralReport,
    }

    const generateRes = await request({
      url: borrowingBaseReportAPI.ADD_OR_UPDATE_BB_REPORT,
      method: POST,
      data: borrowingBaseReport,
    });

    return generateRes.data;
  }
  catch (error) {
    console.log('GENERATE BORROWING BASE REPORT: ', error);
  }
}

/**
 * This method returns either the provided number or 0 if the number is undefined.
 * 
 * @param num The input number which might be undefined.
 * @returns The input number if it's defined, otherwise returns 0.
 */
export const getPotentiallyUndefinedNumber = (num: undefined | number) => {
  const IS_NUMBER_UNDEFINED = (num === undefined);
  if (IS_NUMBER_UNDEFINED) { return 0; }
  return num;
};

export const isOddNumber = (num: number) => num%2 !== 0;

export const checkIfHasParentSuffix = (srcId?: string) => {
  return srcId?.endsWith(' (Parent)');
} 