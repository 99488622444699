import colors from "../../utility/colors";

const styles = {
  absoluteBox: {
    marginTop: '2rem',
    marginX: '-1.5rem',
    position: 'absolute',
    width:'103.3%'
  },
  actionsIcon: {
    color: '#154A8A',
    fontSize: 25,
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    }
  },
  actionsIconButton: {
    marginLeft: '5px',
    marginRight: '5px',
    padding: '5px'
  },
  addButton: {
    '& .MuiTypography-root' : {
      textTransform: 'none',
    },
    px: '22.5px',
    py: '17px'
  },
  allRolesLabel: {
    color: '#154A8A',
    fontWeight: 'bold',
    marginRight: 'auto',
    padding: '1rem',
    paddingBottom: '0rem',
  },
  headerRoleLabel: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize:'16px'
  },
  bottomActionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto'
  },
  boxColor: {
    backgroundColor: '#F2F7F8'
  },
  buttonTabs: {
    '.MuiTabs-Box': {
      border: '1px solid #E0E0E0'
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px'
    },
    '.MuiTabs-indicator': {
      display: 'none'
    },
    '.MuiTabs-scroller': {
      background: '#F7F7F7'
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      boxShadow: '0 -5px 30px -7px #E0E0E0',
      color: '#000000',
      marginRight: '5px',
      padding: '8px',
      textTransform: 'none',
      width: '220px'
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: 'white',
      color: '#000000'
    }
  },
  buttonText: {
    color: '#010953',
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '5px'
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  disabled:{
    color: '#A6A8AA'
  },
  disabledIcon: {
    color: '#A6A8AA',
    cursor: 'default'
  },
  hidden: {
    display: 'none'
  },
  invisible: {
    visibility: 'hidden'
  },
  horizontalDivider: {
    borderColor: '#E0E0E0',
    bordertWidth: '3px',
    mt: '10px',
    mx: '5px' 
  },
  roleAndPermissionBox: {
    background: 'white',
    border: '2px solid #E0E0E0',
    borderRadius: '3px',
    boxShadow: '0 -5px 30px -7px #E0E0E0',
    maxHeight: '66vh',
    overflowY: 'auto'
  },
  roleAndPersmissionParrentBox:{
    width: '100%',
    maxHeight: '70vh',
    flexWrap: 'nowrap',
    columnGap: '7px'
  },
  roleListItem: {
    '&.Mui-selected': {
      borderBottom: '1px solid #154A8A',
      color: '#154A8A'
    }
  },
  searchField: {
    backgroundColor: 'white',
    width: '15vw',
    marginRight: '20px',
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      color: '#000',
      fontSize: '14px',
      height: '36.6px',
      padding: '15px',
      '& .MuiOutlinedInput-input': {
        padding: '0px'
      }
    }
  },
  searchStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '13px'
  },
  spaceX: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px'
  },
  tabBox: {
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '3px 3px 0 0',
    width: '100%'
  },
  tableCell: {
    border: 'none',
    height: '48px',
    paddingY: '0'
  },
  tableHeadCell: {
    '&.MuiTableCell-head': {
      backgroundColor: '#F7F7F7',
      fontWeight: 'bold'
    }
  },
  tableRow: {
    ':hover': {
      cursor: 'pointer',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F7F7F7',
    },
    
    '&:nth-of-type(odd)': {
      backgroundColor: '#FEFEFE',
    }
  },
  tabPanelBox: {
    p: 3,
  },
  title: {
    color: '#010953',
    fontSize: '18px',
    fontWeight: 'bold',
    ml: '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  visible: {
    display: 'block',
  },
  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  verticalDivider:{ 
  borderColor: '#154A8A',
  borderLeftWidth: '1px', 
  my: '5px',
  mx: '10px' 
  },
  gridContainer: {
    margin: 'auto',
    marginLeft: '1.5rem',
    marginTop: '1rem',
    marginRight: '1.6rem',
    paddingBottom: '10px',
    width: 'auto',
    borderBottom: '1px solid #E5E2E2'
  },
  fixedActionButtons: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '77%',
    zIndex: '2'
  },
  UnfoldMoreRounded: {
    color: colors.PRIMARY_TEXT,
    height: '.8rem', 
    width: '.8rem',
  },
  iconButton: {
    padding: '0',
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
};

export default styles;
