import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertColor, Box, Button, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs} from '@mui/material';
import axiosInstance from '../../../../../service/axiosInstance';
import { GET, API_DOMAIN, NON_EXISTING_PERCENT, NON_EXISTING, PUT, POST, PERMISSIONS, NO_PERMISSION_MSG, DELETE } from '../../../../../utility/constants';
import { IInventoryCollateral, IInventoryInput } from '../../../../../interfaces';
import NoDataPlaceholder from '../../../../common/no-data-placeholder';
import styles from './styles';
import { SkeletonRow, SkeletonTableHead } from '../../../../skeleton';
import requests from '../../../../../service/requests';
import Toaster from '../../../../toaster';
import { ClientSettingsPaginationContext, IClientSettingsPaginationContext } from '../../../../../context/clientSettingsPaginationContext';
import InventorySettingRow from './row';
import InventorySettingModal from './modal';
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser } from '../../../../../utility/helper';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../context/clientSettingsContext';

interface InventorySettingsTabProps {
  onSettingsChange: (settingsCount: number) => void;
  isLoading       : boolean;
}

export interface IInventorySetting {
  inventoryCollateral: IInventoryCollateral;
  inventoryInput: IInventoryInput;
}

export const getActiveCollaterals = async (borrowerId: number) => {
  const activeCollaterals = (
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId: borrowerId, sortBy: 'invCollateralName,ASC', isArchived: false },
    })
  ).data.content as IInventoryCollateral[];

  const activeInputs = (
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/inputs/search/findIsArchived`,
      method: GET,
      params: { borrowerId: borrowerId, sortBy: 'invCollateralName,ASC', isArchived: false },
    })
  ).data.content as IInventoryInput[];

  return activeCollaterals.filter(currentCollateral => {
    const inventoryInputIndex = activeInputs.findIndex(input => input.invCollateralFk === currentCollateral.recordId);
    return inventoryInputIndex !== NON_EXISTING;
  });
};

export const getDefaultCollateral = async (borrowerId: number) => {
  const defaultCollaterals = (
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/search/findIsDefault`,
      method: GET,
      params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'recordId,ASC', isDefault: true, }
    })
  ).data.content as IInventoryCollateral[]
  return defaultCollaterals[0] ?? null;
};

const InventorySettingsTab: React.FC<InventorySettingsTabProps> = (props) => {
  const TABS: { label: 'Active' | 'Archived'}[]                               = [ { label: 'Active' }, { label: 'Archived' } ];

  const navigate                                                              = useNavigate();
  const { borrowerId }                                                        = useParams();
  const { page, setPage, setCount, rowsPerPage,}                              = useContext(ClientSettingsPaginationContext) as IClientSettingsPaginationContext;
  const { canViewInv, setCanViewInv,
          setCanChangeDefaultInv,
          canAddInv, setCanAddInv,
          setCanUpdateInv,
          setCanArchiveDeleteInv,
          canViewInventoryWorksheet, setCanViewInventoryWorksheet, setInventoryCount,
          canViewInventoryIneligibles, setCanViewInventoryIneligibles }       = useContext(ClientSettingsContext) as IClientSettingsContext;
  const [selectedTabIndex, setSelectedTabIndex]                               = useState(TABS.findIndex(tab => tab.label === 'Active'));
  const [inventorySettings, setInventorySettings]                             = useState<IInventorySetting[]>([]);
  const [isAddInventorySettingModalOpen, setIsAddInventorySettingModalOpen]   = useState(false);
  const [isLoading, setIsLoading]                                             = useState(props.isLoading);
  const [toaster, setToaster]                                                 = useState<{ isOpen: boolean, message: string, severity: AlertColor }>({ isOpen: false, message: '', severity: 'success' });
  const [archivedInvNames, setArchivedInvNames]                               = useState<string[]>([]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    if (!hasSelectedBorrower()) { return; }
    getInventorySettings();
  }, [page, rowsPerPage, borrowerId, selectedTabIndex]);

  useEffect(() => {
    setPage(0);
  }, [selectedTabIndex]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewInv(permissions.includes(PERMISSIONS.VIEW_INV));
    setCanChangeDefaultInv(permissions.includes(PERMISSIONS.CHANGE_DEFAULT_INV));
    setCanAddInv(permissions.includes(PERMISSIONS.ADD_INV));
    setCanUpdateInv(permissions.includes(PERMISSIONS.UPDATE_INV));
    setCanArchiveDeleteInv(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_INV));
    setCanViewInventoryWorksheet(permissions.includes(PERMISSIONS.VIEW_INVENTORY_WORKSHEET));
    setCanViewInventoryIneligibles(permissions.includes(PERMISSIONS.VIEW_INVENTORY_INELIGIBLES));
  };

  const getInventorySettings = async () => {
    setIsLoading(true);
    const inventoryCollateralRequestPerTab = {
      'Archived': axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals/search/findIsArchived`,
        method: GET,
        params: { borrowerId, pageNo: page, pageSize: rowsPerPage, sortBy: 'invCollateralName,ASC', isArchived: true },
      }),
      'Active': axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals/search/findActiveAndDefaultCollateralsFirst`,
        method: GET,
        params: { borrowerId, pageNo: page, pageSize: rowsPerPage, sortBy: 'invCollateralName,ASC' }
      })
    }

    const selectedTabLabel = TABS[selectedTabIndex].label;
    const inventoryCollateralRequest = await inventoryCollateralRequestPerTab[selectedTabLabel];
    const inventoryCollaterals = inventoryCollateralRequest.data.content as IInventoryCollateral[];

    if (selectedTabLabel === 'Archived') {
      setArchivedInvNames([]);
    } else {
      const inventoryCollateralRequest = await inventoryCollateralRequestPerTab['Archived'];
      const inventoryCollaterals = inventoryCollateralRequest.data.content as IInventoryCollateral[];
      const archivedNames = inventoryCollaterals.map(inv => inv.invCollateralName.toLowerCase());
      setArchivedInvNames(archivedNames);
    }

    const inventoryInputs = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/inv/inputs/search/findIsArchived`,
        method: GET,
        params: { borrowerId: borrowerId, isArchived: TABS[selectedTabIndex].label === 'Archived', sortBy: 'invCollateralName,ASC' },
      })
    ).data.content as IInventoryInput[];
    const inventorySettings = inventoryCollaterals.reduce((previousInventorySettings, currentInventoryCollateral) => {
      const inventoryInput = inventoryInputs.find(input => input.invCollateralFk === currentInventoryCollateral.recordId);
      
      if (inventoryInput === undefined) { return previousInventorySettings; }
      const currentInventorySetting = {
        inventoryCollateral: currentInventoryCollateral,
        inventoryInput: {
          ...inventoryInput,
          ...(inventoryInput.sublimit !== undefined
            ? { sublimit: inventoryInput.amountLimitType === 'Percentage' ? inventoryInput.sublimit * 100 : inventoryInput.sublimit }
            : { sublimit: NON_EXISTING_PERCENT }),
          advanceRate: inventoryInput.advanceRate * 100,
          ...(inventoryInput.nolvAdvanceRate !== undefined
            ? { nolvAdvanceRate: inventoryInput.nolvAdvanceRate * 100 }
            : { nolvAdvanceRate: NON_EXISTING_PERCENT }),
          ...(inventoryInput.nolvAppraisal !== undefined
            ? { nolvAppraisal: inventoryInput.nolvAppraisal * 100 }
              : { nolvAppraisal: NON_EXISTING_PERCENT }),
          }
      };
      return [ ...previousInventorySettings, currentInventorySetting];
    }, [] as IInventorySetting[]);
    setInventorySettings(inventorySettings);
    setCount(inventoryCollateralRequest.data.totalElements);
    TABS[selectedTabIndex].label === 'Active' && props.onSettingsChange(inventoryCollateralRequest.data.totalElements);
    setIsLoading(false);
  };
    
    
   

  const getActiveInventorySettingByIndex = async (index: number) => {
    const inventoryCollateralsRequest = await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId: borrowerId, pageNo: index, pageSize: 1, isArchived: false },
    });
    const inventoryCollaterals = inventoryCollateralsRequest.data.content as IInventoryCollateral[];
    if (inventoryCollaterals.length === 0) { return null; }
    const inventoryInputs = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/inv/inputs/search/findIsArchived`,
        method: GET,
        params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, isArchived: false },
      })
    ).data.content as IInventoryInput[];
    if (inventoryInputs.length === 0) { return null; }
    const inventoryInput = inventoryInputs.find(input => input.invCollateralFk === inventoryCollaterals[0].recordId);
    if (inventoryInput === undefined) { return null; }
    const inventorySetting = {
      inventoryCollateral: inventoryCollaterals[0],
      inventoryInput: {
        ...inventoryInput,
        ...(inventoryInput.sublimit !== undefined
          ? { sublimit: inventoryInput.amountLimitType === 'Percentage' ? inventoryInput.sublimit * 100 : inventoryInput.sublimit }
          : { sublimit: NON_EXISTING_PERCENT }),
        advanceRate: inventoryInput.advanceRate * 100,
        ...(inventoryInput.nolvAdvanceRate !== undefined
          ? { nolvAdvanceRate: inventoryInput.nolvAdvanceRate * 100 }
          : { nolvAdvanceRate: NON_EXISTING_PERCENT }),
        ...(inventoryInput.nolvAppraisal !== undefined
          ? { nolvAppraisal: inventoryInput.nolvAppraisal * 100 }
          : { nolvAppraisal: NON_EXISTING_PERCENT }),
      }
    } as IInventorySetting;
    return inventorySetting;
  };

  const createInventorySetting = async (inventorySetting: IInventorySetting) => {
    const numericBorrowerId = parseInt(borrowerId as string);
    const { inventoryInput } = inventorySetting;
    setIsLoading(true);
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    const createdCollateral = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals`,
        method: POST,
        data: {
          borrowerFk: numericBorrowerId,
          invCollateralName: inventoryInput.invCollateralName,
          default: defaultCollateral === null,
        },
      })
    ).data as IInventoryCollateral;
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/inputs`,
      method: POST,
      data: {
        borrowerFk: createdCollateral.borrowerFk,
        invCollateralFk: createdCollateral.recordId,
        current: true,
        invCollateralName: inventoryInput.invCollateralName,
        ...(inventoryInput.sublimit !== NON_EXISTING_PERCENT && {
          sublimit: inventoryInput.amountLimitType === 'Percentage' ? inventoryInput.sublimit / 100 : inventoryInput.sublimit
        }),
        ...(inventoryInput.advanceRate !== NON_EXISTING_PERCENT && {
          advanceRate: inventoryInput.advanceRate / 100
        }),
        ...(inventoryInput.nolvAdvanceRate !== NON_EXISTING_PERCENT && {
          nolvAdvanceRate: inventoryInput.nolvAdvanceRate / 100
        }),
        ...(inventoryInput.nolvAppraisal !== NON_EXISTING_PERCENT && {
          nolvAppraisal: inventoryInput.nolvAppraisal / 100
        }),
        ...(inventoryInput.nolvIneligibleAmount !== NON_EXISTING_PERCENT && {
          nolvIneligibleAmount: inventoryInput.nolvIneligibleAmount
        }),
        sublimitType: inventoryInput.sublimitType,
        amountLimitType: inventoryInput.amountLimitType,
      },
    });
    setToaster({ isOpen: true, message: `${inventoryInput.invCollateralName} has been added`, severity: 'success'});
    setSelectedTabIndex(TABS.findIndex(tab => tab.label === 'Active'));
    setIsAddInventorySettingModalOpen(false);
    getInventorySettings();
  };

  const updateInventorySetting = async (inventorySetting: IInventorySetting) => {
    const { inventoryInput } = inventorySetting;
    setIsLoading(true);
    const updatedCollateral = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}`,
        method: PUT,
        data: {
          recordId: inventorySetting.inventoryCollateral.recordId,
          borrowerFk: inventorySetting.inventoryCollateral.borrowerFk,
          invCollateralName: inventoryInput.invCollateralName,
          default: inventorySetting.inventoryCollateral.default,
        },
      })
    ).data as IInventoryCollateral;
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/inputs/${inventoryInput.recordId}`,
      method: PUT,
      data: {
        recordId: inventoryInput.recordId,
        borrowerFk: updatedCollateral.borrowerFk,
        invCollateralFk: updatedCollateral.recordId,
        current: true,
        invCollateralName: inventoryInput.invCollateralName,
        ...(inventoryInput.sublimit !== NON_EXISTING_PERCENT && {
          sublimit: inventoryInput.amountLimitType === 'Percentage' ? inventoryInput.sublimit / 100 : inventoryInput.sublimit
        }),
        ...(inventoryInput.advanceRate !== NON_EXISTING_PERCENT && {
          advanceRate: inventoryInput.advanceRate / 100
        }),
        ...(inventoryInput.nolvAdvanceRate !== NON_EXISTING_PERCENT && {
          nolvAdvanceRate: inventoryInput.nolvAdvanceRate / 100
        }),
        ...(inventoryInput.nolvAppraisal !== NON_EXISTING_PERCENT && {
          nolvAppraisal: inventoryInput.nolvAppraisal / 100
        }),
        ...(inventoryInput.nolvIneligibleAmount !== NON_EXISTING_PERCENT && {
          nolvIneligibleAmount: inventoryInput.nolvIneligibleAmount
        }),
        sublimitType: inventoryInput.sublimitType,
        amountLimitType: inventoryInput.amountLimitType,
      },
    });
    setToaster({ isOpen: true, message: `${inventoryInput.invCollateralName} has been edited`, severity: 'success'});
    getInventorySettings();
  };

  const deleteInventorySetting = async (inventorySetting: IInventorySetting) => {
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}`,
      method: DELETE
    });

    if (!inventorySetting.inventoryCollateral.default) {
      setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been deleted`, severity: 'success'});
      getInventorySettings();
      return;
    }
    const firstInventorySetting = await getActiveInventorySettingByIndex(0);
    if (firstInventorySetting === null) {
      setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been deleted`, severity: 'success'});
      getInventorySettings();
      return;
    }
    await changeDefaultInventorySetting(firstInventorySetting);
    setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been deleted`, severity: 'success'});
    getInventorySettings();
  };

  const archiveInventorySetting = async (inventorySetting: IInventorySetting) => {
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/inputs/${inventorySetting.inventoryInput.recordId}/archive`,
      method: PUT
    });
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}/archive`,
      method: PUT
    });
    if (!inventorySetting.inventoryCollateral.default) {
      setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been archived`, severity: 'success'});
      getInventorySettings();
      return;
    }
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}/default`,
      method: PUT,
      params: { isDefault: false }
    });
    const firstInventorySetting = await getActiveInventorySettingByIndex(0);
    if (firstInventorySetting === null) {
      setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been archived`, severity: 'success'});
      getInventorySettings();
      getInventoryCountFromAPI();
      return;
    }
    await changeDefaultInventorySetting(firstInventorySetting);
    setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been archived`, severity: 'success'});
    getInventorySettings();
    getInventoryCountFromAPI();
  };

  const restoreInventorySetting = async (inventorySetting: IInventorySetting) => {
    const numericBorrowerId = parseInt(borrowerId as string);
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/inputs/${inventorySetting.inventoryInput.recordId}/archive`,
      method: PUT,
      params: { isArchived: false }
    });
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}/archive`,
      method: PUT,
      params: { isArchived: false }
    });
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    if (defaultCollateral !== null) {
      setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been restored`, severity: 'success'});
      getInventorySettings();
      getInventoryCountFromAPI();
      return;
    }
    await changeDefaultInventorySetting(inventorySetting);
    setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been restored`, severity: 'success'});
    getInventorySettings();
    getInventoryCountFromAPI();
  };

  const getInventoryCountFromAPI = async () => {
    const nonNullBorrowerId = borrowerId as string;
    const numericBorrowerId = parseInt(nonNullBorrowerId);
    const collaterals = await getActiveCollaterals(numericBorrowerId);
    setInventoryCount(collaterals.length);
  };

  const checkPermission = async (callback: Function, permission: string, ...args: any) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){ callback(...args); return; }
    setToaster({ isOpen: true, message: NO_PERMISSION_MSG, severity: 'error'});
  };

  const changeDefaultInventorySetting = async (inventorySetting: IInventorySetting, showToaster?: boolean) => {
    setIsLoading(true);
    const defaultCollaterals = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/inv/collaterals/search/findIsDefault`,
        method: GET,
        params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'recordId,ASC', isDefault: true, }
      })
    ).data.content as IInventoryCollateral[]
    if (defaultCollaterals.length > 0) {
      const configs = defaultCollaterals.map(collateral => ({
        url: `${API_DOMAIN}/inv/collaterals/${collateral.recordId}/default`,
        method: PUT,
        params: { isDefault: false }
      }));
      await requests(configs.map(config => axiosInstance.request(config)));
    }
    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/collaterals/${inventorySetting.inventoryCollateral.recordId}/default`,
      method: PUT,
      params: { isDefault: true }
    });
    showToaster && setToaster({ isOpen: true, message: `${inventorySetting.inventoryInput.invCollateralName} has been set as default.`, severity: 'success'});
    getInventorySettings();
  };

  const navigateToInventoryPath = async (path: string) => {
    const numericBorrowerId = parseInt(borrowerId as string);
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    if (defaultCollateral !== null) { navigate(`${defaultCollateral.recordId}/${path}`); return; }
    const activeCollaterals = await getActiveCollaterals(numericBorrowerId);
    if (activeCollaterals.length === 0) { return; }
    navigate(`${activeCollaterals[0].recordId}/${path}`);
  };

  const getTabProps = (label: string, index: number) => {
    return { label: label, key: index, id: `inv-collateral--${index}`, 'aria-controls': `inv-collateral-panel-${index}`, disabled: isLoading };
  };

  const hasSelectedBorrower = () => !(borrowerId === undefined || borrowerId === 'undefined');

  const getTableHeaders = () => {
    const headers = [
      { text: 'Collateral Name', width: '20%', sx: styles.tableHeadCell },
      { text: 'Sublimit', width: '20%', sx: { ...styles.tableHeadCell, ...styles.rightAlignedText } },
      { text: 'Advance Rate', width: '20%', sx: { ...styles.tableHeadCell, ...styles.rightAlignedText } },
      { text: 'NOLV Advance Rate', width: '20%', sx: { ...styles.tableHeadCell, ...styles.rightAlignedText } }
    ];
    if (TABS[selectedTabIndex].label === 'Active') {
      return ([
        { text: 'Default', width: '10%', sx: styles.centerAlignedText },
        ...headers,
      { text: 'Actions', width: '10%', sx: { ...styles.tableHeadCell, ...styles.centerAlignedText } }
      ]);
    }
    return ([
      ...headers,
      { text: 'Actions', width: '20%', sx: { ...styles.tableHeadCell, ...styles.centerAlignedText } }
    ]);
  };

  const getTableContent = () => {
    if (isLoading) {
      return (
        <Table sx={styles.table} data-test-id='inventory-settings-loader'>
          <SkeletonTableHead />
          <TableBody>
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </TableBody>
        </Table>
      );
    };
    if (!canViewInv) { return <NoDataPlaceholder messageText='You do not have the permission to view this page.' />; }
    if (inventorySettings.length === 0 && TABS[selectedTabIndex].label ==='Active') { return <NoDataPlaceholder messageText='There are no Inventory Collaterals added yet.'/>; }
    if (inventorySettings.length === 0 && TABS[selectedTabIndex].label === 'Archived') { return <NoDataPlaceholder messageText='There are no archives. Please add an archive to start.'/>; }
    return (
      <Table aria-label='Inventory Settings Table' sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.tableHeadRow}>
            {
              getTableHeaders().map(tableHeader => {
                const { text, ...rest } = tableHeader;
                return <TableCell key={`headerCell-${text}`} tabIndex={0} { ...rest }>{ text }</TableCell>;
              })
            }
          </TableRow>
        </TableHead>
        <TableBody sx={styles.tableBody}>
          {
            inventorySettings.map(inventorySetting => (
              <InventorySettingRow
                key={inventorySetting.inventoryCollateral.recordId}
                inventorySetting={inventorySetting}
                existingInvCollateralNames={inventorySettings.map(inventorySetting => inventorySetting.inventoryInput.invCollateralName)}
                onInventorySettingChangeDefault={(inventorySetting) => checkPermission(changeDefaultInventorySetting, PERMISSIONS.CHANGE_DEFAULT_INV, inventorySetting, true)}
                onInventorySettingDelete={(inventorySetting) => checkPermission(deleteInventorySetting, PERMISSIONS.DELETE_ARCHIVE_INV, inventorySetting)}
                onInventorySettingArchive={(inventorySetting) => checkPermission(archiveInventorySetting, PERMISSIONS.DELETE_ARCHIVE_INV, inventorySetting)}
                onInventorySettingRestore={(inventorySetting) => checkPermission(restoreInventorySetting, PERMISSIONS.DELETE_ARCHIVE_INV, inventorySetting)}
                onInventorySettingSave={(inventorySetting) => checkPermission(updateInventorySetting, PERMISSIONS.UPDATE_INV, inventorySetting)}
                archivedInvNames={archivedInvNames}
              />
            ))
          }
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <Toaster
        open={toaster.isOpen}
        message={toaster.message}
        severity={toaster.severity}
        onCloseChange={() => setToaster({  isOpen: false, message: toaster.message, severity: toaster.severity })}
      />
      { !hasSelectedBorrower() && <NoDataPlaceholder /> }
      <Box sx={{ ...((!hasSelectedBorrower()) && styles.hidden) }}>
        <Box sx={styles.topContainer}>
          <Box sx={styles.tabContainer}>
            <Tabs
              sx={styles.tabs}
              value={selectedTabIndex}
              onChange={(_event, value) => { setIsLoading(true); setSelectedTabIndex(value); }}
              aria-label='Inventory Settings Tabs'
            >
              { TABS.map((tab, index) => <Tab tabIndex={0} { ...getTabProps(tab.label, index) } />) }
            </Tabs>
          </Box>
          <Button
            disabled={isLoading || inventorySettings.length === 0}
            onClick={() => navigateToInventoryPath('ineligibles-inventory')}
            variant='contained' sx={{ ...styles.button, ...styles.navigationButtons, ...(!canViewInventoryIneligibles && styles.hidden)}}>
            Inventory Ineligibles
          </Button>
          <Button
            disabled={isLoading || inventorySettings.length === 0}
            onClick={() => navigateToInventoryPath('inventory-worksheet')}
            variant='contained' 
            sx={{ ...styles.button, ...styles.navigationButtons, ...(!canViewInventoryWorksheet && styles.hidden)}}>
            Inventory Worksheet
          </Button>
          <Button
            disabled={isLoading}
            variant='contained'
            data-testid='add-button'
            sx={{...styles.button, ...(!canAddInv && styles.hidden)}}
            onClick={() => setIsAddInventorySettingModalOpen(true)}>
            + Add Inventory
          </Button>
        </Box>
        <TableContainer sx={styles.tableContainer}>
          { getTableContent() }
        </TableContainer>
        <InventorySettingModal
          inventorySetting={{
            inventoryCollateral: {
              archive: false,
              invCollateralName: '',
              borrowerFk: parseInt(borrowerId as string),
              canDelete: 1,
              default: false,
              recordId: NON_EXISTING
            },
            inventoryInput: {
              recordId: NON_EXISTING,
              borrowerFk: parseInt(borrowerId as string),
              invCollateralFk: NON_EXISTING,
              invCollateralName: '',
              advanceRate: NON_EXISTING_PERCENT,
              nolvAdvanceRate: NON_EXISTING_PERCENT,
              nolvAppraisal: NON_EXISTING_PERCENT,
              nolvIneligibleAmount: NON_EXISTING_PERCENT,
              sublimit: NON_EXISTING_PERCENT,
              sublimitType: '',
              amountLimitType: '',
              current: true,
              archive: false,
            }
          }}
          isModalOpen={isAddInventorySettingModalOpen}
          isEditable='always'
          onCloseModal={() => setIsAddInventorySettingModalOpen(false)}
          onInventorySettingSave={(inventorySetting) => checkPermission(createInventorySetting, PERMISSIONS.ADD_INV, inventorySetting)}
          existingInvCollateralNames={inventorySettings.map(inventorySetting => inventorySetting.inventoryInput.invCollateralName)}
          archivedInvNames={archivedInvNames}
        />
      </Box>
    </>
  );
};

export default InventorySettingsTab;
