import { Box, TextField, Typography } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import styles from './styles'
import { CustomerVendorModalProps } from '..'
import { SelectedClientContext } from '../../../../context/selectedClientContext'
import { FormikProps } from 'formik'
import { IARCustomer } from '../../../../interfaces'
import { checkIfHasParentSuffix, trimOnBlur } from '../../../../utility/helper'
import HelperTextComponent from '../../../../components/common/helper-text-component'

interface CustomerInfoProps extends CustomerVendorModalProps {
  formik: FormikProps<IARCustomer>;
  isEditting: boolean;
  belowBreakPoint?: boolean;
}

const CustomerInfo = (props: CustomerInfoProps) => {
  const { createCustomer, formik, isEditting, belowBreakPoint } = props;

  const { selectedClient } = useContext(SelectedClientContext);

  const getHelperText = (formik: FormikProps<IARCustomer>, value: string) => {
    const hasError = formik.touched[value] && formik.errors[value];
    return hasError && <HelperTextComponent text={formik.errors[value]}/>;
  }

  const isUltimateParent = useMemo(() => selectedClient?.parentClient, [selectedClient]);

  const getParentFields = () => {
    if (isUltimateParent) {
      return (
        <>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-parent-name' sx={styles.label}>
            Parent Name
            {createCustomer && <span style={styles.asterisk}> *</span>}
          </Typography>
          <TextField
            id='text-field-parent-name'
            name='upcParentCustName'
            value={formik.values.upcParentCustName}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'upcParentCustName', formik.values.upcParentCustName);
            }}
            error={formik.touched.upcParentCustName && Boolean(formik.errors.upcParentCustName)}
            helperText={getHelperText(formik, 'upcParentCustName')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Parent Customer Name',
              'aria-labelledby': 'upcParentCustName',
              'data-testid': 'text-field-parent-name',
            }}
            disabled={!createCustomer}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-parent-id' sx={styles.label}>
            Parent ID
            {createCustomer && <span style={styles.asterisk}> *</span>}
          </Typography>
          <TextField
            id='text-field-parent-id'
            name='upcParentCustSrcId'
            value={formik.values.upcParentCustSrcId}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'upcParentCustSrcId', formik.values.upcParentCustSrcId);
            }}
            sx={styles.textfieldCustomerInfo}
            error={formik.touched.upcParentCustSrcId && Boolean(formik.errors.upcParentCustSrcId)}
            helperText={getHelperText(formik, 'upcParentCustSrcId')}
            size='small'
            inputProps={{
              'aria-label': 'Parent ID',
              'aria-labelledby': 'upcParentCustSrcId',
              'data-testid': 'text-field-parent-id',
            }}
            disabled={!createCustomer}
          />
        </Box>
        </>
      )
    } else {
      return (
        <>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-parent-name' sx={styles.label}>
            Parent Name
            {createCustomer && <span style={styles.asterisk}> *</span>}
          </Typography>
          <TextField
            id='text-field-parent-name'
            name='parentCustName'
            value={formik.values.parentCustName}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'parentCustName', formik.values.parentCustName);
            }}
            error={formik.touched.parentCustName && Boolean(formik.errors.parentCustName)}
            helperText={getHelperText(formik, 'parentCustName')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Parent Customer Name',
              'aria-labelledby': 'parentCustName',
              'data-testid': 'text-field-parent-name',
            }}
            disabled={!createCustomer}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-parent-id' sx={styles.label}>
            Parent ID
            {createCustomer && <span style={styles.asterisk}> *</span>}
          </Typography>
          <TextField
            id='text-field-parent-id'
            name='parentCustSrcId'
            value={formik.values.parentCustSrcId}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'parentCustSrcId', formik.values.parentCustSrcId);
            }}
            sx={styles.textfieldCustomerInfo}
            error={formik.touched.parentCustSrcId && Boolean(formik.errors.parentCustSrcId)}
            helperText={getHelperText(formik, 'parentCustSrcId')}
            size='small'
            inputProps={{
              'aria-label': 'Parent ID',
              'aria-labelledby': 'parentCustSrcId',
              'data-testid': 'text-field-parent-id',
            }}
            disabled={!createCustomer}
          />
        </Box>
        </>
      )
    }
  }

  return (
    <>
    <Box sx={{
      ...styles.customerInfoContainer,
      ...(belowBreakPoint && { width: '80%' })
    }}>
      <Typography variant='body1' sx={styles.sectionTitle}>
        CUSTOMER INFORMATION
      </Typography>
      <Box sx={styles.customerInputsContainer}>
        {getParentFields()}
        {!createCustomer &&
        <>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-customer-name' sx={styles.label}>
            Customer Name<span style={styles.asterisk}> *</span>
          </Typography>
          <TextField
            id='text-field-customer-name'
            name='custName'
            value={formik.values.custName}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custName', formik.values.custName);
            }}
            error={formik.touched.custName && Boolean(formik.errors.custName)}
            helperText={getHelperText(formik, 'custName')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Customer Name',
              'aria-labelledby': 'custName',
              'data-testid': 'text-field-customer-name',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-customer-id' sx={styles.label}>
            Customer ID<span style={styles.asterisk}> *</span>
          </Typography>
          <TextField
            id='text-field-customer-id'
            name='custSrcId'
            value={formik.values.custSrcId}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custSrcId', formik.values.custSrcId);
            }}
            error={formik.touched.custSrcId && Boolean(formik.errors.custSrcId)}
            helperText={getHelperText(formik, 'custSrcId')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Customer ID',
              'aria-labelledby': 'custSrcId',
              'data-testid': 'text-field-customer-id',
            }}
            disabled={!isEditting || checkIfHasParentSuffix(formik.values.custSrcId)}
          />
        </Box>
        </>}
      </Box>
    </Box>
    <Box sx={{
      ...styles.contactInfoContainer,
      ...(belowBreakPoint && { width: '80%' })
    }}>
      <Typography variant='body1' sx={styles.sectionTitle}>
        CONTACT INFORMATION
      </Typography>
      <Box sx={styles.customerInputsContainer}>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custCountry' sx={styles.label}>
            Country
          </Typography>
          <TextField
            id='text-field-custCountry'
            name='custCountry'
            value={formik.values.custCountry}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custCountry', formik.values.custCountry);
            }}
            error={formik.touched.custCountry && Boolean(formik.errors.custCountry)}
            helperText={getHelperText(formik, 'custCountry')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer Country',
              'aria-labelledby': 'custCountry',
              'data-testid': 'text-field-country',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custAddress1' sx={styles.label}>
            Address 1
          </Typography>
          <TextField
            id='text-field-custAddress1'
            name='custAddress1'
            value={formik.values.custAddress1}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custAddress1', formik.values.custAddress1);
            }}
            error={formik.touched.custAddress1 && Boolean(formik.errors.custAddress1)}
            helperText={getHelperText(formik, 'custAddress1')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer Address 1',
              'aria-labelledby': 'custAddress1',
              'data-testid': 'text-field-custAddress1',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custAddress2' sx={styles.label}>
            Address 2
          </Typography>
          <TextField
            id='text-field-custAddress2'
            name='custAddress2'
            value={formik.values.custAddress2}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custAddress2', formik.values.custAddress2);
            }}
            error={formik.touched.custAddress2 && Boolean(formik.errors.custAddress2)}
            helperText={getHelperText(formik, 'custAddress2')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer Address 2',
              'aria-labelledby': 'custAddress2',
              'data-testid': 'text-field-custAddress2',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custCity' sx={styles.label}>
            City
          </Typography>
          <TextField
            id='text-field-custCity'
            name='custCity'
            value={formik.values.custCity}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custCity', formik.values.custCity);
            }}
            error={formik.touched.custCity && Boolean(formik.errors.custCity)}
            helperText={getHelperText(formik, 'custCity')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer City',
              'aria-labelledby': 'custCity',
              'data-testid': 'text-field-custCity',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.stateAndZipContainer}>
          <Box sx={styles.inputContainer}>
            <Typography component='label' htmlFor='text-field-custState' sx={styles.label}>
              State
            </Typography>
            <TextField
              id='text-field-custState'
              name='custState'
              value={formik.values.custState}
              onChange={(event) => formik.handleChange(event)}
              onBlur={(event) => {
                trimOnBlur(event, formik, 'custState', formik.values.custState);
              }}
              error={formik.touched.custState && Boolean(formik.errors.custState)}
              helperText={getHelperText(formik, 'custState')}
              sx={styles.textFieldContactSmall}
              size='small'
              inputProps={{
                'aria-label': 'Customer State',
                'aria-labelledby': 'custState',
                'data-testid': 'text-field-custState',
              }}
              disabled={!isEditting}
            />
          </Box>
          <Box sx={styles.inputContainer}>
            <Typography component='label' htmlFor='text-field-custPostalCode' sx={styles.label}>
              Zip Code
            </Typography>
            <TextField
              id='text-field-custPostalCode'
              name='custPostalCode'
              value={formik.values.custPostalCode}
              onChange={(event) => formik.handleChange(event)}
              onBlur={(event) => {
                trimOnBlur(event, formik, 'custPostalCode', formik.values.custPostalCode);
              }}
              error={formik.touched.custPostalCode && Boolean(formik.errors.custPostalCode)}
              helperText={getHelperText(formik, 'custPostalCode')}
              sx={styles.textFieldContactSmall}
              size='small'
              inputProps={{
                'aria-label': 'Customer Zip Code',
                'aria-labelledby': 'custPostalCode',
                'data-testid': 'text-field-custPostalCode',
              }}
              disabled={!isEditting}
            />
          </Box>
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custPhone' sx={styles.label}>
            Phone Number
          </Typography>
          <TextField
            id='text-field-custPhone'
            name='custPhone'
            value={formik.values.custPhone}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custPhone', formik.values.custPhone);
            }}
            error={formik.touched.custPhone && Boolean(formik.errors.custPhone)}
            helperText={getHelperText(formik, 'custPhone')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer Phone',
              'aria-labelledby': 'custPhone',
              'data-testid': 'text-field-custPhone',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-custDescription' sx={styles.label}>
            Description
          </Typography>
          <TextField
            id='text-field-custDescription'
            name='custDescription'
            value={formik.values.custDescription}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'custDescription', formik.values.custDescription);
            }}
            error={formik.touched.custDescription && Boolean(formik.errors.custDescription)}
            helperText={getHelperText(formik, 'custDescription')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Customer Description',
              'aria-labelledby': 'custDescription',
              'data-testid': 'text-field-custDescription',
            }}
            disabled={!isEditting}
          />
        </Box>
      </Box>
    </Box>
    </>
  )
}

export default CustomerInfo