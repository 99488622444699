import { AlertColor, Autocomplete, AutocompleteRenderInputParams, Box, Button, CircularProgress, Divider, FormLabel, Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import { FieldArray, Form, Formik, FormikErrors, FormikProps, FormikTouched, getIn } from "formik";
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext";
import { HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect, useState } from "react";
import ConfirmModal from "../../../modals/confirm-modal";
import styles from "./styles";
import { CONTAINS_ALPHANUMERIC_ONLY, GET, POST, PUT } from "../../../../utility/constants";
import { IAddGroupModalProps, ISubGroup, ISubGroupPayload } from "../../../../interfaces/groupsInterface";
import { getLocalStorageItem, getOptionLabel, getUserOptionLabel, isOptionEqualToValue, isUserOptionEqualToValue } from "../../../../utility/helper";
import axiosInstance from "../../../../service/axiosInstance";
import api, { groupsAPI, rolePermissionAPI } from "../../../../service/api";
import { IRoleAPI, IUserAPI } from "../../../../interfaces/rolesPermissionInterface";
import Prompt from "../../../modals/prompt";
import groupSchema from "../../../../schemas/groupSchema";
import { IUser } from "../../../../interfaces/userInterface";
import { IOption } from "../../../../interfaces/comboBox";
import { IFormikValue } from "../../../../interfaces/reusableTableInterface";
import HelperTextComponent from "../../../common/helper-text-component";

interface IProps {
  groupDetail?: ISubGroup | null,
  setSelectedGroup: React.Dispatch<React.SetStateAction<ISubGroup | null>>,
  setShowAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>,
  setToasterMessage: (value: string) => void,
  setToasterSeverity: (value: AlertColor) => void,
  setToasterOpen: (value: boolean) => void,
  getAllGroups: () => void
}

const initialRoles: IRoleAPI[] = [
  {
    id: '',
    name: '',
    realmRoles: []
  }
];

const initialPayload: ISubGroupPayload = {
  id: '',
  parentGroupId: '',
  keycloakGroup: {
    id: '',
    name: '',
    path: '',
    users: [],
    parentGroupId: '',
    parentGroupName: '',
    parentGroupPath: ''
  },
  userIds: []
}

const AddGroupModal = (props: IProps) => {
  const { groupDetail, setShowAddGroupModal, setToasterMessage, setToasterOpen, setToasterSeverity, getAllGroups } = props;
  const { setIsDirty, showPrompt, setShowPrompt } = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const [roleList, setRoleList] = useState<IRoleAPI[]>(initialRoles);
  const [users, setUsers] = useState<IUserAPI[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUserAPI[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAddGroupLoading, setIsAddGroupLoading] = useState<boolean>(false);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<ISubGroupPayload>(initialPayload);

  /**
   * This useEffect hook retrieves all of the available roles and users upon showing the modal.
   */
  useEffect(() => {
    setGroup({ ...initialPayload });
    setSelectedUsers([]);

    getAllRoles();
    getAllUsers();

    if (groupDetail != null) {
      const group: ISubGroupPayload = {
        id: groupDetail.id,
        parentGroupId: groupDetail.parentGroupId,
        keycloakGroup: groupDetail,
        userIds: groupDetail.users.length > 0
          ? groupDetail.users.map(user => user.id)
          : []
      }
      setGroup({ ...group });
      setSelectedUsers(groupDetail.users);
    }
  }, []);

  /**
   * This function sets the dirty status of the form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node: any) => {
    if (node !== null) {
      setIsDirty(node.dirty)
    }
  };

  const handleClose = () => {
    setIsDirty(false);
    setShowPrompt(false);
    setShowAddGroupModal(false);
  };

  const getDisplayedError = (errors: FormikErrors<ISubGroupPayload>, touched: FormikTouched<ISubGroupPayload>, fieldName: string) => {
    const fieldError = getIn(errors, fieldName === "name" ? "keycloakGroup.name" : "parentGroupId");
    const isFieldTouched = getIn(touched, fieldName === "name" ? "keycloakGroup.name" : "parentGroupId");

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  /** 
   * This function calls the API endpoint for retrieving all of the roles.
   * @returns The available roles in the application. See IRoleAPI interface for more information.
   */
  const getAllRoles = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_ROLES,
        method: GET,
        headers: { token: token !== undefined ? token : '' }
      })
      const roles: IRoleAPI[] = response.data;
      const filteredRoles = roles.filter(role => role.name !== 'Super Admin');
      setRoleList(filteredRoles);
      return roles;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:', error);
    }
  }

  /**
   * This function calls the API endpoint for retrieving all users.
   * @returns TAll users. See IUserAPI interface for more information.
   */
  const getAllUsers = async () => {
    setIsUsersLoading(true);
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: api.ALL_USERS,
        method: GET,
        headers: {
          token: token !== undefined ? token : ''
        }
      });
      const responseUsers = response.data.filter(user => user.username != "superadmin");
      setUsers(responseUsers);
    } catch (e) {
      console.log(e)
      return [];
    } finally {
      setIsUsersLoading(false);
    }
  }

  const handleSubmit = async (values: ISubGroupPayload) => {
    setIsAddGroupLoading(true);
    try {
      const subgroupPayload = values;
      subgroupPayload.userIds = selectedUsers.map(user => user.id);
      const token = getLocalStorageItem('token');

      if (groupDetail != null) {
        await axiosInstance.request({
          url: groupsAPI.ALL_GROUPS,
          method: PUT,
          headers: {
            token: token !== undefined ? token : ''
          },
          params: { prevParentGroupId: values.keycloakGroup.parentGroupId },
          data: subgroupPayload
        });
      } else {
        await axiosInstance.request({
          url: groupsAPI.ALL_GROUPS,
          method: POST,
          headers: {
            token: token !== undefined ? token : ''
          },
          data: subgroupPayload
        });
      }

      await getAllGroups();

      setToasterMessage(`Group has been succesfully ${groupDetail != null ? 'Updated': 'Added'}`);
      setToasterSeverity('success');
      setToasterOpen(true);

      handleClose();
      setShowAddGroupModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsAddGroupLoading(false);
    }
  }

  /**
   * This function defines the paper component used for rendering dropdowns.
   *
   * @param props The props for the paper component.
   * @returns The paper component wrapped around the provided children.
   */
  const paperComponent = useCallback((props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdown}>{props.children}</Paper>), []);

  /**
    * This function defines the rendering of the dropdown input component.
    *
    * @param type The type of the dropdown.
    * @returns Object with renderInput function.
    */
  const getRenderInput = () => {
    return {
      renderInput: (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          placeholder={users.length > 0 ? undefined : 'Please Select'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isUsersLoading ?
                  <CircularProgress color='inherit' size={15} sx={styles.loadingIcon} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )
    }
  };

  /**
   * This function constructs event handlers for change and input events in multiple-select dropdown components.
   *
   * @returns Object with event handler functions.
   */
  const getMultipleChange = (formik: FormikProps<ISubGroupPayload>) => {
    return {
      onChange: (_: SyntheticEvent<Element, Event>, newVal: readonly IUserAPI[]) => setSelectedUsers([...newVal]),
      onInputChange: (_: SyntheticEvent<Element, Event>, newInput: string) => setUserInput(newInput)
    }
  };

  return (
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={group}
        validationSchema={groupSchema}
        innerRef={formikRef}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form style={styles.form}>
            <Prompt when={formik.dirty} isEditing={setIsEditing} />
            <Grid
              container
              rowSpacing={2}
              sx={styles.container}
            >
              <Grid item xs={12}>
                <FormLabel
                  component='label'
                  required={true}
                  sx={styles.label}
                  htmlFor='groupName'>
                  Name:
                </FormLabel>

                <Grid item xs={12}>
                  <TextField
                    id='group-name'
                    arial-label='Group Name Field'
                    name={`keycloakGroup.name`}
                    value={formik.values.keycloakGroup.name}
                    sx={styles.gridField}
                    onChange={(event) => { 
                      formik.handleChange(event);
                    }}
                    {...getDisplayedError(formik.errors, formik.touched, "name")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormLabel
                  component='label'
                  required={true}
                  sx={styles.label}
                  htmlFor='Role'>
                  Role:
                </FormLabel>
                <Grid item xs={12}>
                  <Select
                    displayEmpty
                    name={`parentGroupId`}
                    arial-label='roles'
                    onChange={(event) => { 
                      formik.handleChange(event);
                    }}
                    sx={{ ...styles.gridField, ...styles.gridSelect, ...styles.limitGridSelect, }}
                    {...getDisplayedError(formik.errors, formik.touched, "parentGroupId")}
                    value={formik.values.parentGroupId}>
                    {formik.values.parentGroupId === '' &&
                      <MenuItem value='' sx={{ ...styles.gridMenuItem, ...styles.hidden }} disabled>
                        Please Select
                      </MenuItem>
                    }
                    {
                      roleList.map((role, idx) =>
                        <MenuItem key={role.id} value={role.id} sx={styles.gridMenuItem}>
                          {role.name}
                        </MenuItem>
                      )
                    }
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormLabel
                  component='label'
                  required={true}
                  sx={styles.label}
                  htmlFor='users'>
                  Users:
                </FormLabel>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id={`user-dropdown`}
                    disablePortal
                    getOptionLabel={getUserOptionLabel}
                    // limitTags={1}
                    disableCloseOnSelect
                    isOptionEqualToValue={isUserOptionEqualToValue}
                    options={users}
                    value={selectedUsers}
                    loading={isUsersLoading}
                    inputValue={userInput}
                    {...getRenderInput()}
                    PaperComponent={paperComponent}
                    size='small'
                    componentsProps={{
                      popupIndicator: { 'aria-label': 'Dropdown icon', tabIndex: 0 },
                      clearIndicator: { 'aria-label': 'Clear icon', tabIndex: 0 }
                    }
                    }
                    {...getMultipleChange(formik)}
                    sx={styles.dropdown}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.bottomActionsButtonContainer}>
                <Button
                  variant='outlined'
                  sx={styles.cancelButton}
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!formik.isValid || isAddGroupLoading || (selectedUsers.length < 1)}
                  variant='contained'
                  type='submit'
                  sx={styles.addButton}
                >
                  {isAddGroupLoading ? <CircularProgress color="inherit" size={20} /> : "Save Group"}
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

export default AddGroupModal;
