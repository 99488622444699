import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Box, Button, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import request from '../../../service/request';
import { API_DOMAIN, GET } from '../../../utility/constants';
import ClientSettingsPaginationProvider from '../../../context/clientSettingsPaginationContext';
import ClientSettingsPagination from './tab-panel-contents/pagination';
import { ClientSettingsContext, IClientSettingsContext } from '../../../context/clientSettingsContext';
import AccountsReceivableSettingsTab, { getActiveChildCollaterals, getActiveCollaterals as getActiveARCollaterals } from './tab-panel-contents/accounts-receivable';
import InventorySettingsTab, { getActiveCollaterals as getActiveInventoryCollaterals } from './tab-panel-contents/inventory-settings';
import OtherLoansSettingsTable from './tab-panel-contents/other-loans';
import styles from './styles';
import ConfirmModal from '../../modals/confirm-modal';
import TabPanel from '../../common/tab-panel';
import { SelectedClientContext } from '../../../context/selectedClientContext';

const ClientSettingsTabs = ({ selectedTabIndex, onTabChange }) => {
  const {
    isDirty, setIsDirty,
    canViewAr, canViewInv, viewOtherLoan, arCollateralCount, setARCollateralCount, inventoryCount, setInventoryCount, otherLoansCount, setOtherLoansCount
  }                                                             = useContext(ClientSettingsContext) as IClientSettingsContext;
  const { selectedClient }                                      = useContext(SelectedClientContext);
  const { borrowerId }                                          = useParams();
  const navigate                                                = useNavigate();
  const [lastSelectedTabIndex, setLastSelectedTabIndex]         = useState<number>(0);
 
  const [isLoading, setIsLoading]                               = useState<boolean>(false);
  const [
    isConfirmNavigationModalOpen,
    setIsConfirmNavigationModalOpen
  ]                                                             = useState<boolean>(false);
  

  useEffect(() => {
    getAllTabCounts();
  }, [borrowerId]);

  const getAllTabCounts = async () => {
    setIsLoading(true);
    if (!hasSelectedBorrower()) {
      setARCollateralCount(0);
      setInventoryCount(0);
      setOtherLoansCount(0);
      setIsLoading(false);
      return;
    }
    await Promise.all([
      getAccountsReceivableCountFromAPI(),
      getInventoryCountFromAPI(),
      getOtherLoansCountFromAPI(),
    ]);
    setIsLoading(false);
  };

  const getAccountsReceivableCountFromAPI = async () => {
    const collaterals = selectedClient?.parentClient ? await getActiveChildCollaterals(selectedClient.recordId as number) : await getActiveARCollaterals(selectedClient?.recordId as number);
    setARCollateralCount(collaterals.length);
  };

  const getInventoryCountFromAPI = async () => {
    const nonNullBorrowerId = borrowerId as string;
    const numericBorrowerId = parseInt(nonNullBorrowerId);
    const collaterals = await getActiveInventoryCollaterals(numericBorrowerId);
    setInventoryCount(collaterals.length);
  };

  const getOtherLoansCountFromAPI = async () => {
    const response = await request({
      url: `${API_DOMAIN}/ol/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId, pageNo: 0, pageSize: 9999, isArchived: false }
    });
    setOtherLoansCount(response.data.totalElements);
  };

  const handleTabChange = (newValue: number) => {
    if (isDirty === true) {
      setIsConfirmNavigationModalOpen(true);
      setLastSelectedTabIndex(newValue);
      return;
    }
    onTabChange(newValue);
  };

  const handleConfirmNavigationModalClose = () => {
    onTabChange(lastSelectedTabIndex)
    setIsConfirmNavigationModalOpen(false)
    setIsDirty(false)
  };

  const renderTabs = () => {
    const tabs = [
      { index: 0, label: 'Accounts Receivable', count: canViewAr ? getTabCount(arCollateralCount) : '(0)'  },
      { index: 1, label: 'Inventory', count: canViewInv ? getTabCount(inventoryCount) : '(0)' },
      { index: 2, label: 'Other Loans', count: viewOtherLoan ? getTabCount(otherLoansCount) : '(0)' },
    ];
    return (
      <Tabs
        sx={styles.buttonTabs}
        value={selectedTabIndex}
        onChange={(_event, value) => handleTabChange(value)}
        aria-label='clients settings tab'
      >
        { tabs.map(tab => selectedClient?.parentClient && tab.index !== 0 ?
          <Tooltip title={`${tab.label} does not apply to the Parent Client level`} key={tab.label}>
            <span>
              <Tab disabled label={`${tab.label} ${tab.count}`} {...getTabProps(tab.index)} key={tab.label} />
            </span>
          </Tooltip> 
        :<Tab label={`${tab.label} ${tab.count}`} {...getTabProps(tab.index)} key={tab.label} />) }
      </Tabs>
    );
  };

  const hasSelectedBorrower = () => Boolean(borrowerId) && borrowerId !== 'undefined';

  const getTabProps = (index: number) => {
    return {
      id: `client-settings-tab-${index}`,
      'aria-controls': `client-settings-tabpanel-${index}`,
      tabIndex: 0,
    };
  };

  const getTabCount = (count: number) => {
    if (count > 99) return '(99+)';
    return `(${count})`;
  };

  return (
    <ClientSettingsPaginationProvider>
      <Grid container sx={styles.gridContainer}>
        <Box sx={styles.tabBox}>
          <Grid container sx={styles.gridTabsContainer}>
            { renderTabs() }
            <Box sx={styles.exchangeRateButtonContainer}>
              <Button
                variant='outlined'
                disabled={!hasSelectedBorrower() || Boolean(selectedClient?.parentClientFk)}
                onClick={() => navigate(`exchangeRate`)}
              >
                Exchange Rate
              </Button>
            </Box>
          </Grid>

          <TabPanel selectedTabIndex={selectedTabIndex} index={0}>
            <AccountsReceivableSettingsTab
              isLoading={isLoading}
              onSettingsChange={() => getAccountsReceivableCountFromAPI() }
            />
          </TabPanel>
          <TabPanel selectedTabIndex={selectedTabIndex} index={1}>
            <InventorySettingsTab
              isLoading={isLoading}
              onSettingsChange={() => getInventoryCountFromAPI() }
            />
          </TabPanel>
          <TabPanel selectedTabIndex={selectedTabIndex} index={2}>
            <OtherLoansSettingsTable
              onSettingsChange={() => getOtherLoansCountFromAPI()}
            />
          </TabPanel>
        </Box>

        <Box sx={styles.paginationContainer}>
          <ClientSettingsPagination />
        </Box>
      </Grid>
      <ConfirmModal
        open={isConfirmNavigationModalOpen}
        onConfirm={() => setIsConfirmNavigationModalOpen(false)}
        onClose={() => handleConfirmNavigationModalClose()}
        onButtonClose={() => setIsConfirmNavigationModalOpen(false)}
        promptChecker={true}
        title='Confirm Navigation'
        description='You have unsaved changes. Are you sure you want to leave this page?'
        yesButtonText='Keep Editing'
        noButtonText='Discard Changes'
        confirmOnly
      />
    </ClientSettingsPaginationProvider>
  );
};

export default ClientSettingsTabs;
