import { useContext, useState } from 'react';
import { TableCell, Radio, SxProps, Tooltip, IconButton, TableRow, Theme } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IInventorySetting } from '..';
import { NON_EXISTING_PERCENT } from '../../../../../../utility/constants';
import ConfirmModal from '../../../../../modals/confirm-modal';
import styles from '../styles';
import InventorySettingModal from '../modal';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../../context/clientSettingsContext';

interface IInventorySettingRowProps {
  inventorySetting: IInventorySetting;
  existingInvCollateralNames: string[];
  onInventorySettingChangeDefault: (inventorySetting: IInventorySetting) => void;
  onInventorySettingDelete: (inventorySetting: IInventorySetting) => void;
  onInventorySettingArchive: (inventorySetting: IInventorySetting) => void;
  onInventorySettingRestore: (inventorySetting: IInventorySetting) => void;
  onInventorySettingSave: (inventorySetting: IInventorySetting) => void;
  archivedInvNames: string[];
}

const InventorySettingRow: React.FC<IInventorySettingRowProps> = (props) => {
  const [isInventorySettingModalOpen, setIsInventorySettingModalOpen] = useState(false);
  const { canChangeDefaultInv, canArchiveDeleteInv } = useContext(ClientSettingsContext) as IClientSettingsContext; 
  const [actionConfirmModal, setActionConfirmModal] = useState({ isOpen: false, title: '', description: '', yesButtonText: '', isDeleting: false });

  const handleArchive = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Archive',
      description: 'You are about to archive this item. Are you sure?',
      yesButtonText: 'Archive', isDeleting: false
    });
  };

  const handleDelete = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Delete',
      description: 'You are about to delete this item. Are you sure?',
      yesButtonText: 'Delete', isDeleting: true
    });
  };

  const handleRestore = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Restore',
      description: 'You are about to restore this item. Are you sure?',
      yesButtonText: 'Restore', isDeleting: false
    });
  };

  const renderTableCellTextContent = (numberToConvert: number | undefined, type: 'currency' | 'percentage') => {
    if (numberToConvert === undefined || numberToConvert === NON_EXISTING_PERCENT) { return undefined; }
    if (type === 'currency') {
      const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
      return formatter.format(numberToConvert);
    }
    return `${(numberToConvert).toFixed(2)}%`;
  };

  const handleActionModalConfirm  = () => {
    switch (actionConfirmModal.title) {
      case 'Delete': props.onInventorySettingDelete(props.inventorySetting); break;
      case 'Archive': props.onInventorySettingArchive(props.inventorySetting); break;
      case 'Restore': props.onInventorySettingRestore(props.inventorySetting); break;
    }
  };

  const renderTableCellForDefaultRadioButton = () => {
    if (props.inventorySetting.inventoryCollateral.archive) { return null; }
    return (
      <TableCell
        component='th'
        scope='row'
        sx={{ ...styles.tableCell, ...styles.centerAlignedText }}
      >
        <Radio
          checked={props.inventorySetting.inventoryCollateral.default}
          onChange={() => props.onInventorySettingChangeDefault(props.inventorySetting)}
          value={props.inventorySetting.inventoryCollateral.recordId}
          name={`is-inv-record-id-${props.inventorySetting.inventoryCollateral.recordId}-default`}
          inputProps={{ 'aria-label': 'Set Inventory as Default' }}
          size='small'
          disabled={!canChangeDefaultInv}
        />
      </TableCell>
    );
  };

  const renderTableCell = (innerContent: any, tableCellStyle?: SxProps<Theme>) => {
    if (!innerContent) {
      return (
        <TableCell
          tabIndex={0}
          className='emptyCell'
          component='th'
          onClick={() => setIsInventorySettingModalOpen(true)}
          scope='row'
          sx={{ ...styles.tableCell, ...(tableCellStyle !== undefined && tableCellStyle) }}
        >
          -
        </TableCell>
      );
    }
    return (
      <TableCell
        tabIndex={0}
        component='th'
        onClick={() => setIsInventorySettingModalOpen(true)}
        scope='row'
        sx={{ ...styles.tableCell, ...(tableCellStyle !== undefined && tableCellStyle) }}
      >
        { innerContent }
      </TableCell>
    );
  };

  const getActionButtons = () => {
    const isInventorySettingActive = !props.inventorySetting.inventoryCollateral.archive;
    const isInventorySettingDeletable = props.inventorySetting.inventoryCollateral.canDelete === 1;
    const ViewCollateralActionButton = (
      <Tooltip title='View the collateral'>
        <IconButton
          aria-label={'View icon'}
          onClick={() => setIsInventorySettingModalOpen(true)}
          sx={styles.viewActionIconButton}
        >
          <PreviewIcon sx={styles.actionIcon} />
        </IconButton>
      </Tooltip>
    );
    if (canArchiveDeleteInv) {
      if (isInventorySettingActive && isInventorySettingDeletable) {
        return (
          <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
            { ViewCollateralActionButton }
            <Tooltip title='Delete the collateral'>
              <IconButton
                aria-label='Delete icon'
                onClick={handleDelete}
                sx={styles.actionIconButton}
              >
                <DeleteOutlineOutlinedIcon sx={styles.actionIcon} />
              </IconButton>
            </Tooltip>
          </TableCell>
        );
      }
      if (isInventorySettingActive) {
        return (
          <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
            { ViewCollateralActionButton }
            <Tooltip title='Archive the collateral'>
              <IconButton
                aria-label='Archive icon'
                onClick={handleArchive}
                sx={styles.actionIconButton}
              >
                <Inventory2OutlinedIcon sx={styles.actionIcon} />
              </IconButton>
            </Tooltip>
          </TableCell>
        );
      }
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
          <Tooltip title='Restore the collateral'>
            <IconButton
              aria-label='Restore icon'
              onClick={handleRestore}
              sx={styles.actionIconButton}
            >
              <HistoryIcon sx={styles.actionIcon} />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    } else if (isInventorySettingActive) {
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
          { ViewCollateralActionButton }
        </TableCell>
      )
    } else {
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }} />
      )
    }
  };

  return (
    <>
      <TableRow key={`row-${props.inventorySetting.inventoryCollateral.recordId}`} sx={styles.tableRow}>
        { renderTableCellForDefaultRadioButton() }
        { renderTableCell(props.inventorySetting.inventoryInput.invCollateralName) }
        { renderTableCell(
          renderTableCellTextContent(
            props.inventorySetting.inventoryInput.sublimit,
            props.inventorySetting.inventoryInput.amountLimitType === 'Percentage' ? 'percentage' : 'currency'
          ),
          styles.rightAlignedText
        ) }
        { renderTableCell(renderTableCellTextContent(props.inventorySetting.inventoryInput.advanceRate, 'percentage'), styles.rightAlignedText) }
        { renderTableCell(renderTableCellTextContent(props.inventorySetting.inventoryInput.nolvAdvanceRate, 'percentage'), styles.rightAlignedText) }
        { getActionButtons() }
      </TableRow>
      <InventorySettingModal
        key={`modal-${props.inventorySetting.inventoryCollateral.recordId}`}
        inventorySetting={props.inventorySetting}
        isModalOpen={isInventorySettingModalOpen}
        isEditable={props.inventorySetting.inventoryCollateral.archive ? 'not editable' : 'editable'}
        onCloseModal={() => setIsInventorySettingModalOpen(false)}
        onInventorySettingDelete={handleDelete}
        onInventorySettingArchive={handleArchive}
        onInventorySettingRestore={handleRestore}
        onInventorySettingSave={(inventorySetting) => props.onInventorySettingSave(inventorySetting)}
        existingInvCollateralNames={props.existingInvCollateralNames}
        archivedInvNames={props.archivedInvNames}
      />
      <ConfirmModal
        open={actionConfirmModal.isOpen}
        onClose={() => setActionConfirmModal({ ...actionConfirmModal, isOpen: false })}
        onConfirm={() => handleActionModalConfirm()}
        onButtonClose={() => setActionConfirmModal({ ...actionConfirmModal, isOpen: false })}
        alignment='left'
        title={actionConfirmModal.title}
        description={actionConfirmModal.description}
        yesButtonText={actionConfirmModal.yesButtonText}
        noButtonText='Cancel'
        errorButton= {actionConfirmModal.isDeleting}
       />
    </>
  );
};

export default InventorySettingRow;
