import { Box, Button, IconButton, Link, Tab, TablePagination, Tabs, Typography } from "@mui/material"
import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo } from "react"
import styles from "./styles"
import { useNavigate } from "react-router-dom";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import CustomerSettingsSearchBar from "./search-bar";
import TabPanel from "../../../components/common/tab-panel";
import { CloseOutlined, DeleteOutlined, HistoryOutlined } from "@mui/icons-material";
import { arCustomersAPI, arVendorsAPI } from "../../../service/api";
import { GET, POST } from "../../../utility/constants";
import axiosInstance from "../../../service/axiosInstance";
import { CustomerSettingsMainViewContext, ICustomerSettingsMainViewContext } from "../../../context/customerSettingsMainViewContext";
import CustomerVendorTable from "./customer-vendor-table";
import { getLabelDisplayedRows, getLabelRowsPerPage } from "../../../components/client-settings/tabs/tab-panel-contents/pagination";
import ConfirmModal from "../../../components/modals/confirm-modal";
import { archiveCustomers, archiveVendors, deleteCustomers, deleteVendors } from "../../../api/customer-settings";
import { clearAllNewCustomers, clearAllNewVendors } from "../../../utility/helper";
import WarningModal from "../../../components/file-import/modals/warning-modal";

export interface CustomerVendorParams {
  arCollateralId?: any,
  borrowerId?: any,
  pageNo?: any, 
  sortBy?: any,
  pageSize?: any,
  fieldName?: any,
  fieldValue?: any,
  vendorName?: any,
  vendorSrcId?: any,
  custName?: any,
  custSrcId?: any,
  parentCustName?: any,
  upcParentCustName?: any,
  parentCustSrcId?: any,
  upcParentCustSrcId?: any,
  isNew?: any,
  isSearchAll?: any,
  archive?: any,
}

export interface IHeadCell {
  id: string;
  name: string;
  type: string;
}

export interface ISortParams {
  sortBy: string;
  direction: string;
}

export interface ActionPanelProps {
  open: boolean;
  restore: boolean;
}

export const customerFilters = ['All', 'New', 'Customer Name', 'Customer ID', 'Parent Name', 'Parent ID'];
export const vendorFilters = ['All', 'New', 'Vendor Name', 'Vendor ID', 'Customer Name', 'Customer ID'];

const defCustParentHeaders: IHeadCell[] = [
  { id: 'custName',       name: 'Parent Name',    type: 'string' },
  { id: 'custSrcId',      name: 'Parent ID',      type: 'string' },
];

const defUpcCustParentHeaders: IHeadCell[] = [
  { id: 'custName',    name: 'Parent Name',    type: 'string' },
  { id: 'custSrcId',   name: 'Parent ID',      type: 'string' },
];

const defCustHeaders: IHeadCell[] = [
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'parentCustName',       name: 'Parent Name',    type: 'string' },
  { id: 'parentCustSrcId',      name: 'Parent ID',      type: 'string' },
];

const defUpcCustHeaders: IHeadCell[] = [
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'upcParentCustName',    name: 'Parent Name',    type: 'string' },
  { id: 'upcParentCustSrcId',   name: 'Parent ID',      type: 'string' },
];

const defVendorHeaders: IHeadCell[] = [
  { id: 'vendorName',           name: 'Vendor Name',    type: 'string' },
  { id: 'vendorSrcId',          name: 'Vendor ID',      type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
];

const defCustomerVendorHeaders: IHeadCell[] = [
  { id: 'custName',           name: 'Customer Name',    type: 'string' },
  { id: 'custSrcId',          name: 'Customer ID',      type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
];

const CustomerSettingsMainView: FC = () => {
  const navigate = useNavigate();

  // Contexts
  const { selectedClient } = useContext(SelectedClientContext);
  
  const {
    canEditParentChildRelationship,
    canViewCustomerList,
    canViewParentList,
    selectedARCollateral,
    setToaster,
    tabIndex, setTabIndex,
    subTabIndex, setSubTabIndex,
    page, setPage,
    rowsPerPage, setRowsPerPage,
    totalElements, setTotalElements,
    selectedValue, setSelectedValue,
    searchValue, setSearchValue,
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const {
    actionPanel, setActionPanel,
    clearModal, setClearModal,
    setSelectAll,
    customerVendorList, setCustomerVendorList,
    setCustomerVendorCount,
    actionModal, setActionModal,
    warningModal, setWarningModal,
    setDisplayNoTable,
    isFetching, setIsFetching,
    custSortParams, setCustSortParams,
    vendorSortParams, setVendorSortParams,
    triggerSearch, setTriggerSearch,
    triggerSelectAll, setTriggerSelectAll,
  } = useContext(CustomerSettingsMainViewContext) as ICustomerSettingsMainViewContext;

  // Memos
  const isInCustomerTab = useMemo(() => tabIndex === 0, [tabIndex]);

  // Effects
  useEffect(() => {
    fetchData();
  }, [
    tabIndex,
    subTabIndex,
    selectedClient,
    selectedARCollateral,
    selectedValue,
    triggerSearch,
    page,
    rowsPerPage,
    custSortParams,
    vendorSortParams
  ])

  // Functions
  const handleChangePage   = useCallback((_event: any, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const fetchData = async () => {
    setIsFetching(true);
    setDisplayNoTable(false);
    setCustomerVendorList([]);
    setCustomerVendorCount({});
    setActionPanel(({
      restore: subTabIndex !== 0,
      open: false,
    }));
    setSelectAll(false);

    if (selectedClient === null) {
      resetTable();
      setIsFetching(false);
      return;
    }

    if (!selectedClient.parentClient && selectedARCollateral === null) {
      resetTable();
      setIsFetching(false);
      return;
    }

    // Customer List = 0 tabIndex
    if (tabIndex === 0) {
      // View By Customer (Unarchived)
      if (subTabIndex === 0) {
        await fetchUnarchivedCustomers();
      }

      // View By Parent
      else if (subTabIndex === 1) {
        await fetchParentCustomers();
      }

      // Archived Customers
      else {
        await fetchArchivedCustomers();
      }
    }
    
    // Vendor List
    else {
      // View By Vendor (Unarchived)
      if (subTabIndex === 0) {
        await fetchUnarchivedVendors();
      }

      // View By Customer
      else if (subTabIndex === 1) {
        await fetchCustomersWithVendors();
      }

      // Archived Vendors
      else {
        await fetchArchivedVendors();
      }
    }

    setIsFetching(false);
  }

  const fetchUnarchivedCustomers = async () => {
    try {
      const customerParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        custName: selectedValue === 'Customer Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustName' : 'parentCustName'}`]: selectedValue === 'Parent Name' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId'}`]: selectedValue === 'Parent ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${custSortParams.sortBy},${custSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
      };

      const customerResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: { ...customerParams }
      });

      const customerList = customerResponse.data.content;

      if (customerList.length) {
        setCustomerVendorList(customerList);
        setTotalElements(customerResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING UNARCHIVED CUSTOMERS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const fetchParentCustomers = async () => {
    try {
      const customerParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        custName: selectedValue === 'Customer Name' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustName' : 'parentCustName'}`]: selectedValue === 'Parent Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId'}`]: selectedValue === 'Parent ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        isSearchAll: selectedValue === 'All' ? true : undefined,
        pageNo: page, 
        sortBy: `${custSortParams.sortBy},${custSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
      };

      const customerResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_PARENTS_BY_CRITERIA,
        method: GET,
        params: { ...customerParams }
      });
      
      const customerList = customerResponse.data.content;

      if (customerList.length) {
        const count = customerList.reduce((acc, item) => {
          acc[item.recordId] = item.childrenCount;
          return acc;
        }, {});

        setCustomerVendorCount(count);
        setCustomerVendorList(customerList);
        setTotalElements(customerResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        setCustomerVendorCount({});
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING PARENT CUSTOMERS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const fetchArchivedCustomers = async () => {
    try {
      const customerParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        custName: selectedValue === 'Customer Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustName' : 'parentCustName'}`]: selectedValue === 'Parent Name' ? searchValue : undefined,
        [`${selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId'}`]: selectedValue === 'Parent ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${custSortParams.sortBy},${custSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
        archive: true
      };

      const customerResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: { ...customerParams }
      });
      
      const customerList = customerResponse.data.content;

      if (customerList.length) {
        setCustomerVendorList(customerList);
        setTotalElements(customerResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING ARCHIVED CUSTOMERS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const fetchUnarchivedVendors = async () => {
    try {
      const vendorParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        vendorName: selectedValue === 'Vendor Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        vendorSrcId: selectedValue === 'Vendor ID' ? searchValue : undefined,
        custName: selectedValue === 'Customer Name' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${vendorSortParams.sortBy},${vendorSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
      };

      if (['custName', 'custSrcId'].includes(vendorSortParams.sortBy)) {
        vendorParams.sortBy = `arCustomer.${vendorSortParams.sortBy},${vendorSortParams.direction}`
      }

      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_BY_CRITERIA,
        method: GET,
        params: { ...vendorParams }
      });
      
      const vendorList = vendorResponse.data.content;

      if (vendorList.length) {
        setCustomerVendorList(vendorList);
        setTotalElements(vendorResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING UNARCHIVED VENDORS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const fetchCustomersWithVendors = async () => {
    try {
      const vendorParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        vendorName: selectedValue === 'Vendor Name'? searchValue : undefined,
        vendorSrcId: selectedValue === 'Vendor ID' ? searchValue : undefined,
        custName: selectedValue === 'Customer Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${custSortParams.sortBy},${custSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
        isSearchAll: selectedValue === 'All' ? true : undefined,
      };

      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_BY_LINKED_CUSTOMERS,
        method: GET,
        params: { ...vendorParams }
      });
      
      const customerList = vendorResponse.data.content;

      if (customerList.length) {
        const count = customerList.reduce((acc, item) => {
          acc[item.recordId] = item.childrenCount;
          return acc;
        }, {});

        setCustomerVendorCount(count);
        setCustomerVendorList(customerList);
        setTotalElements(vendorResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING CUSTOMERS WITH VENDORS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const fetchArchivedVendors = async () => {
    try {
      const vendorParams: CustomerVendorParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        vendorName: selectedValue === 'Vendor Name' || selectedValue === 'New' || selectedValue === 'All' ? searchValue : undefined,
        vendorSrcId: selectedValue === 'Vendor ID' ? searchValue : undefined,
        custName: selectedValue === 'Customer Name' ? searchValue : undefined,
        custSrcId: selectedValue === 'Customer ID' ? searchValue : undefined,
        isNew: selectedValue === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${vendorSortParams.sortBy},${vendorSortParams.direction}`,
        pageSize: rowsPerPage !== -1 ? rowsPerPage : undefined,
        archive: true
      };

      if (['custName', 'custSrcId'].includes(vendorSortParams.sortBy)) {
        vendorParams.sortBy = `arCustomer.${vendorSortParams.sortBy},${vendorSortParams.direction}`
      }

      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_BY_CRITERIA,
        method: GET,
        params: { ...vendorParams }
      });
      
      const vendorList = vendorResponse.data.content;

      if (vendorList.length) {
        setCustomerVendorList(vendorList);
        setTotalElements(vendorResponse.data.totalElements);
      } else {
        setDisplayNoTable(true);
        setCustomerVendorList([]);
        resetPages();
      }
    } catch (error) {
      console.log('FETCHING ARCHIVED VENDORS ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const handleEditViewClick = () => {
    if (isInCustomerTab) {
      navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/customers/edit`)
    } else {
      navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/vendors/edit`);
    }
  }
  
  const handleSearch = (_searchValue: string) => {
    setTriggerSearch(!triggerSearch);
  }

  const resetTable = () => {
    setDisplayNoTable(true);
    setCustomerVendorList([]);
    resetPages();
    resetTabs();
  }

  const resetPages = () => {
    setPage(0);
    setRowsPerPage(10);
    setTotalElements(0);
  }

  const resetTabs = () => {
    setTabIndex(0);
    setSubTabIndex(0);
  }

  const handleArchiveCustomers = async (arCustomerIds: number[], archive: boolean) => {
    try {
      await archiveCustomers(arCustomerIds, archive);

      setToaster({
        open: true,
        message: `Successfully ${!archive ? 'restored' : 'archived'} ${arCustomerIds.length} customer/s`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('ARCHIVE CUSTOMERS ', error);
      setToaster({ open: true, message: 'Failed to archive customers!', severity: 'error' });
    }
  }

  const handleDeleteCustomers = async (arCustomerIds: number[]) => {
    try {
      await deleteCustomers(arCustomerIds);

      setToaster({
        open: true,
        message: `Successfully deleted ${arCustomerIds.length} customer/s`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('DELETE CUSTOMERS ', error);
      setToaster({ open: true, message: 'Failed to delete customers!', severity: 'error' });
    }
  }

  const handleArchiveVendors = async (arVendorIds: number[], archive: boolean) => {
    try {
      await archiveVendors(arVendorIds, archive);

      setToaster({
        open: true,
        message: `Successfully ${!archive ? 'restored' : 'archived'} ${arVendorIds.length} vendor/s`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('ARCHIVE VENDORS ', error);
      setToaster({ open: true, message: 'Failed to archive vendors!', severity: 'error' });
    }
  }

  const handleDeleteVendors = async (arVendorIds: number[]) => {
    try {
      await deleteVendors(arVendorIds);

      setToaster({
        open: true,
        message: `Successfully deleted ${arVendorIds.length} vendor/s`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('DELETE VENDORS ', error);
      setToaster({ open: true, message: 'Failed to delete vendors!', severity: 'error' });
    }
  }

  const handleClearAllNewCustomers = async () => {
    try {
      await clearAllNewCustomers(selectedClient?.parentClient ? {
        borrowerId: selectedClient?.recordId,
      } : {
        arCollateralId: selectedARCollateral?.recordId,
      });

      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' });
      
      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('CLEAR ALL NEW CUSTOMERS: ', error);
      setToaster({ open: true, message: 'Failed to clear all customers!', severity: 'error' });
    }
  }

  const handleClearAllNewVendors = async () => {
    try {
      await clearAllNewVendors(selectedClient?.parentClient ? {
        borrowerId: selectedClient?.recordId,
      } : {
        arCollateralId: selectedARCollateral?.recordId,
      });

      setToaster({ open: true, message: 'Successfully cleared all vendors!', severity: 'success' });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      console.log('CLEAR ALL NEW VENDORS ', error);
      setToaster({ open: true, message: 'Failed to clear all vendors!', severity: 'error' });
    }
  }

  const handleDeleteAndArchiveCustomers = async (deleteCustomerIds: number[], archiveCustomerIds: number[]) => {
    try {
      await Promise.all([deleteCustomers(deleteCustomerIds), archiveCustomers(archiveCustomerIds, true)]);
      setToaster({
        open: true,
        message: `Successfully deleted ${deleteCustomerIds.length} customers and archived ${archiveCustomerIds.length} customers.`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      setToaster({
        open: true,
        message: `Failed to delete and archive customers.`,
        severity: 'error',
      });
      console.log('DELETE ARCHIVE CUSTOMERS ', error);
    }
  }

  const handleDeleteAndArchiveVendors = async (deleteVendorIds: number[], archiveVendorIds: number[]) => {
    try {
      await Promise.all([deleteVendors(deleteVendorIds), archiveVendors(archiveVendorIds, true)]);
      setToaster({
        open: true,
        message: `Successfully deleted ${deleteVendorIds.length} vendors and archived ${archiveVendorIds.length} vendors.`,
        severity: 'success',
      });

      if (page !== 0) {
        resetPages();
      } else {
        setTriggerSearch(!triggerSearch);
      }
    } catch (error) {
      setToaster({
        open: true,
        message: `Failed to delete and archive vendors.`,
        severity: 'error',
      });

      console.log('DELETE ARCHIVE VENDORS ', error);
    }
  }

  const getActionPanelText = (dataType: 'customer' | 'vendor') => {
    const itemsLength = [...customerVendorList].filter(data => data.selected).length;
    const typeLabel = dataType === 'customer' ? 'Customer' : 'Vendor';
    return `${itemsLength} ${typeLabel}/s on this page are selected.`;
  }

  const handleMultipleAction = (actionPanel: ActionPanelProps, dataType: 'customer' | 'vendor') => {
    const dataTypeLabel = dataType === 'customer' ? 'Customer' : 'Vendor';

    const actionData = [...customerVendorList].filter((data) => data.selected);

    // handle restore
    if (actionPanel.restore) {
      const restoreData = actionData.map((data) => (data.recordId!));

      setActionModal({
        recordIds: restoreData,
        dataType: dataType,
        actionType: 'restore',
        open: true,
        title: `Restore ${dataTypeLabel}s`,
        description: `You are about to restore ${dataType}s. Are you sure?`,
        yesButtonText: `Restore`,
        noButtonText: 'Cancel',
        errorButton: false,
      });

      return;
    }

    const deleteData = actionData
      .filter((data) => data.canDelete)
      .map((data) => (data.recordId!));

    const archiveData = actionData
      .filter((data) => !data.canDelete)
      .map((data) => (data.recordId!));

    if (deleteData.length > 0 && archiveData.length > 0) {
      setWarningModal({
        ...warningModal,
        deleteRecordIds: deleteData,
        archiveRecordIds: archiveData,
        dataType: dataType,
        open: true,
        description: `You are about to delete ${deleteData.length} ${dataType}s and archive the remaining ${archiveData.length} ${dataType}s since they are part of existing calculations. Are you sure you want to proceed?`
      });

      return;
    }

    // check if action is pure delete or archive
    const actionType = deleteData.length > 0 ? 'delete' : 'archive'
    const actionTypeLabel = actionType === 'delete' ? 'Delete' : 'Archive';

    setActionModal({
      recordIds: actionType === 'delete' ? deleteData : archiveData,
      dataType: dataType,
      actionType: actionType,
      open: true,
      title: `${actionTypeLabel} ${dataTypeLabel}s`,
      description: `You are about to ${actionType} ${dataType}s. Are you sure?`,
      yesButtonText: `${actionTypeLabel}`,
      noButtonText: 'Cancel',
      errorButton: actionType === 'delete',
    })
  }

  const getClearModalTitle = (type: string) => {
    if (type === 'customer') {
      return 'Clear All New Customers';
    }
    else if (type === 'vendor') {
      return 'Clear All New Vendor';
    }
    
    return 'Clear All New Accounts';
  }

  const getClearModalDescription = (type: string) => {
    if (type === 'customer') {
      return 'Are you sure you want to clear all new added customers?';
    }
    else if (type === 'vendor') {
      return 'Are you sure you want to clear all new added vendors?';
    }
    return '';
  }

  return (
    <>
    <Box sx={styles.navBox}>
      {canEditParentChildRelationship ?
        <Button
          onClick={handleEditViewClick}
          variant='contained'
          sx={{
            ...styles.button,
            ...styles.buttonText,
          }}
          disabled={!selectedARCollateral}
        >
          {isInCustomerTab ? 'Edit Parent/Child' : 'Edit Vendor/Customer'}
        </Button> :
        <Box sx={styles.emptyButtonBox} />
      }
      <CustomerSettingsSearchBar
        filters={isInCustomerTab ? customerFilters : vendorFilters}
        onSearch={handleSearch}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        disabled={!selectedARCollateral}
      />
    </Box>
    <Box sx={styles.tabBox}>
      <Box>
        <Tabs
          sx={styles.buttonTabs}
          value={tabIndex}
          onChange={(_e, value) => {
            setTabIndex(value)
            setCustomerVendorList([]);
            setCustomerVendorCount({});
            setActionPanel(({
              restore: false,
              open: false,
            }));
            setSelectAll(false);
            setSubTabIndex(0);
            resetPages();
          }}
        >
          <Tab
            tabIndex={0}
            label='Customer List'
            id='0'
            aria-controls='customer-list-view'
          />
          <Tab
            tabIndex={0}
            label='Vendor List'
            id='1'
            aria-controls='vendor-list-view'
          />
        </Tabs>
      </Box>
      <TabPanel selectedTabIndex={tabIndex} index={0}>
        { actionPanel.open ? (
          <Box sx={styles.boxActionPanel}>
            <Typography sx={{ marginLeft: '24px' }}>
              { getActionPanelText('customer') }
            </Typography>
            <Box>
              <Button 
                data-testid='delete-archive-multiple-button'
                startIcon={subTabIndex === 0 ? <DeleteOutlined /> : <HistoryOutlined />}
                onClick={() => {
                  handleMultipleAction(actionPanel, 'customer');
                }}
              >
                  { subTabIndex === 0 ? 'Delete' : 'Restore'}
              </Button>
              <IconButton
                onClick={() => {
                  setSelectAll(false);
                  setActionPanel({
                    restore: subTabIndex !== 0,
                    open: false,
                  });
                  setCustomerVendorList(customerVendorList.map((data) => ({
                    ...data,
                    selected: false,
                  })));
                  setTriggerSelectAll(!triggerSelectAll);
                }}
              >
                <CloseOutlined />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.boxSubTabs}>
            <Tabs
              sx={styles.buttonSubTabs}
              value={subTabIndex}
              onChange={(_e, value) => {
                setSubTabIndex(value);
                resetPages();
                setCustomerVendorList([]);
                setCustomerVendorCount({});
                setActionPanel(({
                  restore: subTabIndex !== 0,
                  open: false,
                }));
                setSelectAll(false);
              }}
            >
              <Tab
                tabIndex={0}
                label='View By Customer'
                id='0'
                aria-controls='customer-list-view'
              />
              <Tab
                tabIndex={0}
                label='View By Parent'
                id='1'
                aria-controls='vendor-list-view'
              />
              <Tab
                tabIndex={0}
                label='Archived Customer'
                id='2'
                aria-controls='archive-customer-list-view'
              />
            </Tabs>
            <Link 
              component={'button'} 
              sx={styles.clearButton}
              onClick={() => {
                setClearModal(prevState => ({
                  ...prevState,
                  isOpen: true,
                  type: 'customer',
                }));
              }}
            >
              {!isFetching && customerVendorList.length > 0 && 'Clear All New Customers'}
            </Link>
          </Box>
        )}
        <TabPanel selectedTabIndex={subTabIndex} index={0}>
          {
            canViewCustomerList ? (
              <CustomerVendorTable
                headers={selectedClient?.parentClient ? defUpcCustHeaders : defCustHeaders}
                sortParams={custSortParams}
                setSortParams={setCustSortParams}
                archive={false}
                type='customer'
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
        <TabPanel selectedTabIndex={subTabIndex} index={1}>
          {
            canViewParentList ? (
              <CustomerVendorTable
                headers={selectedClient?.parentClient ? defUpcCustParentHeaders : defCustParentHeaders}
                sortParams={custSortParams}
                setSortParams={setCustSortParams}
                archive={false}
                type='customer'
                viewByParent
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
        <TabPanel selectedTabIndex={subTabIndex} index={2}>
          {
            canViewCustomerList ? (
              <CustomerVendorTable
                headers={selectedClient?.parentClient ? defUpcCustHeaders : defCustHeaders}
                sortParams={custSortParams}
                setSortParams={setCustSortParams}
                archive={true}
                type='customer'
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
      </TabPanel>
      <TabPanel selectedTabIndex={tabIndex} index={1}>
        { actionPanel.open ? (
          <Box sx={styles.boxActionPanel}>
            <Typography sx={{ marginLeft: '24px' }}>
              { getActionPanelText('vendor') }
            </Typography>
            <Box>
              <Button 
                data-testid={`delete-archive-multiple-button`}
                startIcon={subTabIndex === 0 ? <DeleteOutlined /> : <HistoryOutlined />}
                onClick={() => {
                  handleMultipleAction(actionPanel, 'vendor');
                }}
              >
                  { subTabIndex === 0 ? 'Delete' : 'Restore'}
              </Button>
              <IconButton onClick={() => {
                setSelectAll(false);
                setActionPanel({
                  restore: subTabIndex !== 0,
                  open: false,
                });
                setCustomerVendorList(customerVendorList.map((data) => ({
                  ...data,
                  selected: false,
                })));
                setTriggerSelectAll(!triggerSelectAll);
              }}>
                <CloseOutlined />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.boxSubTabs}>
            <Tabs
              sx={styles.buttonSubTabs}
              value={subTabIndex}
              onChange={(_e, value) => {
                setSubTabIndex(value);
                resetPages();
                setCustomerVendorList([]);
                setCustomerVendorCount({});
                setActionPanel(({
                  restore: subTabIndex !== 0,
                  open: false,
                }));
                setSelectAll(false);
              }}
            >
              <Tab
                tabIndex={0}
                label='View By Vendor'
                id='0'
                aria-controls='vendor-list-view'
              />
              <Tab
                tabIndex={0}
                label='View By Customer'
                id='1'
                aria-controls='vendor-by-customer-list-view'
              />
              <Tab
                tabIndex={0}
                label='Archived Vendor'
                id='2'
                aria-controls='vendor-list-view'
              />
            </Tabs>
            <Link 
              component={'button'} 
              sx={styles.clearButton}
              onClick={() => {
                setClearModal(prevState => ({
                  ...prevState,
                  isOpen: true,
                  type: 'vendor',
                }));
              }}
            >
              {!isFetching && customerVendorList.length > 0 && 'Clear All New Vendors'}
            </Link>
          </Box>
        )}
        <TabPanel selectedTabIndex={subTabIndex} index={0}>
          {
            canViewCustomerList ? (
              <CustomerVendorTable
                headers={defVendorHeaders}
                sortParams={vendorSortParams}
                setSortParams={setVendorSortParams}
                archive={false}
                type='vendor'
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
        <TabPanel selectedTabIndex={subTabIndex} index={1}>
          {
            canViewParentList ? (
              <CustomerVendorTable
                headers={defCustomerVendorHeaders}
                sortParams={custSortParams}
                setSortParams={setCustSortParams}
                archive={false}
                type='customer'
                viewByCustomer
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
        <TabPanel selectedTabIndex={subTabIndex} index={2}>
          {
            canViewCustomerList ? (
              <CustomerVendorTable
                headers={defVendorHeaders}
                sortParams={vendorSortParams}
                setSortParams={setVendorSortParams}
                archive={true}
                type='vendor'
              />
            ) : (
              <Box tabIndex={0} sx={styles.noPermissionBox}>
                You do not have the permissions to view this tab.
              </Box>
            )
          }
        </TabPanel>
      </TabPanel>
    </Box>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
      component='div'
      colSpan={7}
      count={totalElements}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={getLabelDisplayedRows()}
      labelRowsPerPage={getLabelRowsPerPage()}
      backIconButtonProps={{'aria-label':'Previous page icon'}}
      nextIconButtonProps={{'aria-label':'Next page icon'}}
      SelectProps={{ 
        inputProps: {
          'aria-label': 'Expand below icon',
          'aria-labelledby': 'Expand below icon',
        },
        id:'expandBelowIcon'
      }}
    />
    <ConfirmModal 
      title={actionModal.title}
      description={actionModal.description}
      open={actionModal.open}
      onClose={() => {
        setActionModal({
          ...actionModal,
          open: false,
        });
      }}
      onConfirm={async () => {
        if (actionModal.actionType === 'restore') {
          if (actionModal.dataType === 'customer') {
            handleArchiveCustomers(actionModal.recordIds, false);
          } else {
            handleArchiveVendors(actionModal.recordIds, false);
          }
        }

        if (actionModal.actionType === 'delete') {
          if (actionModal.dataType === 'customer') {
            handleDeleteCustomers(actionModal.recordIds);
          } else {
            handleDeleteVendors(actionModal.recordIds);
          }
        }

        if (actionModal.actionType === 'archive') {
          if (actionModal.dataType === 'customer') {
            handleArchiveCustomers(actionModal.recordIds, true);
          } else {
            handleArchiveVendors(actionModal.recordIds, true);
          }
        }
      }}
      yesButtonText={actionModal.yesButtonText}
      noButtonText={actionModal.noButtonText}
      errorButton={actionModal.errorButton}
    />
    <ConfirmModal
      title={getClearModalTitle(clearModal.type)}
      description={getClearModalDescription(clearModal.type)} 
      open={clearModal.isOpen} 
      onClose={() => {
        setClearModal({
          ...clearModal,
          isOpen: false
        });
      }} 
      onConfirm={() => {
        if (clearModal.type === 'customer') {
          handleClearAllNewCustomers();
        } else {
          handleClearAllNewVendors();
        }
      }}        
      yesButtonText='Clear'
      noButtonText='Cancel'
    />
    <WarningModal
      open={warningModal.open}
      onClose={() => {
        setWarningModal({
          ...warningModal,
          open: false,
        });
      }}
      onConfirm={() => {
        if (warningModal.dataType === 'customer') {
          handleDeleteAndArchiveCustomers(warningModal.deleteRecordIds, warningModal.archiveRecordIds);
          return;
        }

        handleDeleteAndArchiveVendors(warningModal.deleteRecordIds, warningModal.archiveRecordIds);

      }}
      noDataIncluded
      issueType='warning'
      issueMessages={[warningModal.description]}
    />
    </>
  )
}

export default CustomerSettingsMainView