import * as Yup from 'yup';

const groupSchema = Yup.object({
  keycloakGroup: Yup.object({
    name: Yup.string().required('Group name is required').trim()
  }),
  parentGroupId: Yup.string().required('Role is required').trim(),
});

export default groupSchema;
