import { Box, Tab, TablePagination, Tabs } from "@mui/material"
import RolesAndPermission from "./roles-permission";
import TabPanel from "./tab-panel"
import UsersTable from "./users-table";
import styles from "../styles";
import { FC, useContext, useEffect, useState } from "react";
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../context/usersAndRolesContext";
import { PERMISSIONS } from "../../../utility/constants";
import { getPermissionsOfUser } from "../../../utility/helper";
import { AuthContext } from "../../../context/authContext";
import ConfirmModal from "../../modals/confirm-modal";
import { useNavigate } from "react-router-dom";
import GroupsTable from "./groups";

/**
 * This method generates the props for the tabs in the Users and Roles table.
 * @param index The index of the tab.
 * @returns The props for the tab.
 */
const tabProps = (index: number) => {
  return {
    id: `users-and-roles-tab-${index}`,
    'aria-controls': `users-and-roles-tabpanel-${index}`,
  };
};

/**
 * Component for showing the Users and Roles table.
 */
const UsersAndRolesTable: FC = () => {
  const {state}                           = useContext(AuthContext);
  const {isDirty, 
         setIsDirty,
         isAddRoleDirty, 
         setIsAddRoleDirty,
         canViewUsersAndRoles, 
         setCanViewUsersAndRoles,
         setCanAddRole,
         setCanUpdateRole,
         setCanDeleteRole}                = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const tabName                           = 'users-and-roles-tab';
  const [tabVal, setTabVal]               = useState<number>(0);
  const [page, setPage]                   = useState<number>(0);
  const [rowsPerPage, setRowsPerPage]     = useState<number>(10);
  const [totalElements, setTotalElements] = useState<number>(10);
  const [showPrompt, setShowPrompt]       = useState<boolean>(false);
  const [tempTab, setTempTab]             = useState<number>(0);

  const navigate = useNavigate();

  /**
   * This useEffect hook retrieves the permissions of the current user.
   */
  useEffect(() => {
    if (state.token !== null) {
      getPermissions(state.token)
    }
  }, [state.token])

  /**
   * This function retrieves the permissions of the user and sets their state.
   * @param token 
   */
  const getPermissions = async (token : string) => {
    const permissions = await getPermissionsOfUser(state.uid, token);
    
    if (permissions.includes(PERMISSIONS.VIEW_USERS_AND_ROLES)) {
      setCanViewUsersAndRoles(permissions.includes(PERMISSIONS.VIEW_USERS_AND_ROLES));
    } else {
      navigate('/forbidden');
      return;
    }

    setCanAddRole(permissions.includes(PERMISSIONS.ADD_ROLE));
    setCanUpdateRole(permissions.includes(PERMISSIONS.UPDATE_ROLE));
    setCanDeleteRole(permissions.includes(PERMISSIONS.DELETE_ROLE));
  };

  /**
   * This function handles the changing of active tabs.
   * @param _event The event generated upon clicking the tab.
   * @param val The value of the clicked tab.
   */
  const handleChangeTab = (_event: React.SyntheticEvent | null, val: number) => {
    if (isDirty || isAddRoleDirty) {
      setTempTab(val);
      setShowPrompt(true);
    } else {
      setTabVal(val);
    }
  };

  /**
   * This value confirms the changing of active tab.
   */
  const confirmChangeTab = () => {
    setTabVal(tempTab);
  };

  /**
   * This function handles the changing of page.
   * @param event The event generated upon clicking the page selector.
   * @param newPage The value of the new page nunber.
   */
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  /**
   * This function handles the changing of rows per page.
   * @param event The event generated upon clicking the rows per page selector.
   */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  /**
   * This function handles the closing of the Confirm Navigation modal.
   */
  const handleClose = () => {
    setIsDirty(false);
    setIsAddRoleDirty(false);
    setShowPrompt(false);
    confirmChangeTab();
  };

  return(
    <Box sx={{...styles.tabBox, ...(!canViewUsersAndRoles && styles.hidden)}}>
      <Box>
          <Tabs
            sx={styles.buttonTabs}
            value={tabVal}
            onChange={handleChangeTab}
            aria-label="clients settings tab"
            >
            <Tab label={"Users"}  {...tabProps(0)}/>
            <Tab label={"Roles & Permissions"} {...tabProps(1)}/>
            <Tab label={"Groups"} {...tabProps(2)}/>
          </Tabs>
      </Box>
      
      <TabPanel value={tabVal} index={0} tabName={tabName}>
          <UsersTable 
            rowsPerPage={rowsPerPage} 
            setRowsPerPage={setRowsPerPage} 
            totalElements={totalElements} 
            setTotalElements={setTotalElements} 
            page={page} 
            setPage={setPage}
          />
          <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '1rem'}}>
          <TablePagination
            colSpan={7}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{ 'aria-label':'Previous page icon' }}
            nextIconButtonProps={{ 'aria-label':'Next page icon' }}
          />
        </Box>
      </TabPanel>
      
      <TabPanel value={tabVal} index={1} tabName={tabName}>
        <RolesAndPermission/>
      </TabPanel>

      <TabPanel value={tabVal} index={2} tabName={tabName}>
        <GroupsTable
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage} 
          totalElements={totalElements} 
          setTotalElements={setTotalElements} 
          page={page} 
          setPage={setPage}
        />
        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '1rem'}}>
          <TablePagination
            colSpan={7}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{ 'aria-label':'Previous page icon' }}
            nextIconButtonProps={{ 'aria-label':'Next page icon' }}
          />
        </Box>
      </TabPanel>

      <ConfirmModal
        open={showPrompt}
        onClose={() => {handleClose();}}
        onConfirm={() => {setShowPrompt(false);}}
        onButtonClose={() => {setShowPrompt(false);}}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </Box>
  )
}

export default UsersAndRolesTable