import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Grid, TextField, Autocomplete, Chip } from '@mui/material';
import { GET, PERMISSIONS } from '../../../utility/constants';
import request from '../../../service/request';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { CreateReservesContext as CRContext } from '../../../context/createReservesContext';
import { ICreateReservesContext as ICRContextInterface, IClient } from '../../../interfaces/reservesInterface'
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import { checkUserPermissions, getLocalStorageItem } from '../../../utility/helper';
import { borrowerAPI } from '../../../service/api';
import { ClientContext } from '../../../context/clientContext';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const currencyFormat = (amountString: string) => {
  const amount = parseFloat(amountString);
  return formatter.format(amount);
};

const ReserveHeader: React.FC = () => {
  const { borrowerId: borrowerIdParam }                     = useParams();
  const navigate                                            = useNavigate();
  const { isDirty, setIsDirty }                             = useContext(NavigationWarningContext) as INavigationWarningContext;
  const {
    selectedClient: selectedClientContext,
    setSelectedClient: setSelectedClientContext
  }                                                         = useContext(SelectedClientContext);
  const {
    clients, setClients, selectedClient, setSelectedClient,
    totalAmount, lastSelectedClient, setLastSelectedClient,
    showPrompt, setShowPrompt
  }                                                         = useContext(CRContext) as ICRContextInterface;

  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    const canViewClientsRequest = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_CLIENT);
    if (canViewAssignedClientsOnly && !canViewClientsRequest) {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC', crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC'}
    }
    const token = getLocalStorageItem('token');
    request({
      url: borrowerAPI.FIND_BY_CRITERIA,
      method: GET,
      params: requestParams,
      headers: { token: token !== undefined ? token : '' }
    })
      .then((response) => {
        let selectedClientIndex = 0;
        const mappedClients: Array<IClient> = response.data.content.map((client: IClient, index: number) => {
          const currentClient = { recordId: client.recordId, borrowerName: client.borrowerName, parentClient: client.parentClient, parentClientFk: client.parentClientFk };
          if(borrowerIdParam && client.recordId === parseInt(borrowerIdParam)){ selectedClientIndex = index }
          return currentClient;
        });
        setClients(mappedClients);
        const selectedMappedClient = mappedClients[selectedClientIndex];
        setSelectedClientContext(selectedMappedClient);
        setLastSelectedClient(selectedMappedClient);
      })
      .catch(error => console.log('RESERVES GET CLIENTS ERROR: ', error));
  };

  const handleClientChange = (_event: any, newValue: IClient | null ) => {
    if (newValue === null) { return; }
    setLastSelectedClient(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedClient(newValue);
  };

  const handleDiscardChanges = () => {
    setSelectedClientContext(lastSelectedClient);
    setIsDirty(false);
    setShowPrompt(false);
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    const isClientUninitialized = selectedClient.recordId === -1 || selectedClient.recordId === undefined;
    const isClientAlreadySetAsParam = borrowerIdParam === selectedClient.recordId.toString();
    if (isClientUninitialized || isClientAlreadySetAsParam) { return; }
    navigate(`/clients/${selectedClient.recordId}/settings/reserves`);
    setSelectedClientContext(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClientContext === null || selectedClientContext === undefined || selectedClientContext.recordId === undefined || selectedClientContext.borrowerName === undefined) { return; }
    setSelectedClient({ recordId: selectedClientContext.recordId, borrowerName: selectedClientContext.borrowerName, parentClient: selectedClientContext.parentClient, parentClientFk: selectedClientContext.parentClientFk });
  }, [selectedClientContext]);

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    const parentClients = clients.filter(client => client.parentClient).map(client => client.borrowerName);
    return parentClients.includes(option);
  };

  return (
    <>
      <Box component='span' sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <GeneralBreadcrumbs
              selectedText='Create Reserves'
              breadcrumbLinks={[
                { linkText: 'Clients', route: '/clients' },
                { linkText: 'Client Settings', route: `/clients/${selectedClient.recordId}/settings` },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <Typography tabIndex={0} sx={styles.clientLabelStyle} component='label' htmlFor='combo-box-clients'>Client Name: </Typography>
              <Autocomplete
                id='combo-box-clients'
                data-testid='reserve-client-dropdown'
                aria-label='Client Name Dropdown'
                disablePortal
                options={clients.filter(client => client.parentClient !== true)}
                getOptionLabel={clientOption => clientOption.borrowerName}
                renderOption={(prop, option) => {
                  return (
                    <Box component='li' {...prop} sx={{fontSize:'14px'}} key={option.recordId}>
                      <Box tabIndex={0} sx={styles.labelChipContainer}>{option.borrowerName}</Box>
                      <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.borrowerName) && styles.hidden) }}>
                        <Chip size='small' sx={styles.parentIdentifierChip} />
                      </Box>
                    </Box>
                  );
                }}
                onChange={handleClientChange}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Client Name Dropdown' }} />}
                size='small'
                fullWidth
                sx={styles.clientComboBox}
                value={selectedClient}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.titleCard}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
          Create Reserves
        </Typography>
      </Box>
      <Box sx={styles.headerCard}>
        <Box sx={styles.totalAmountContainer}>
          <Typography tabIndex={0} sx={styles.labelTotalAmount}>
            Total Reserve Amount:
          </Typography>
          <Typography tabIndex={0} sx={styles.valueTotalAmount}>
            {currencyFormat(totalAmount.toString())}
          </Typography>
        </Box>
      </Box>
      <ConfirmModal
        open={showPrompt}
        onClose={() => { handleDiscardChanges(); }}
        onConfirm={() => { setShowPrompt(false); }}
        onButtonClose={() => { setShowPrompt(false); }}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </>
  )
}

export default ReserveHeader;