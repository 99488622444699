import { AlertColor, Box, Button, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material"
import LoaderTable from "../../loader-table";
import SearchIcon from '@mui/icons-material/Search';
import styles from "../../styles";
import { FC, useEffect, useState } from "react";
import { getLocalStorageItem } from "../../../../utility/helper";
import axiosInstance from "../../../../service/axiosInstance";
import { groupsAPI } from "../../../../service/api";
import { GET } from "../../../../utility/constants";
import { ISubGroup } from "../../../../interfaces/groupsInterface";
import GroupsTableRow from "./groups-table-row";
import { IGroupsTable } from "../../../../interfaces/rolesPermissionInterface";
import Toaster from "../../../toaster";
import PopUpModal from "../../../modals/pop-up-modal";
import AddGroupModal from "../../modals/add-group-modal";

const GroupsTable: FC<IGroupsTable> = (props) => {
  const { rowsPerPage, page, setTotalElements } = props;
  const headerTitles = ['Group Name', 'Members', 'Role', 'Actions'];

  const [toasterOpen, setToasterOpen] = useState<boolean>(false);
  const [toasterMessage, setToasterMessage] = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addGroupModal, setAddGroupModal] = useState<boolean>(false);
  const [subgroups, setSubgroups] = useState<ISubGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ISubGroup | null>(null);

  useEffect(() => {
    getAllGroups();
  }, [rowsPerPage, page])

  const getAllGroups = async () => {
    setIsLoading(true);
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: groupsAPI.ALL_GROUPS,
        method: GET,
        headers: {
          token: token !== undefined ? token : ''
        },
        params: { pageNo: page, pageSize: rowsPerPage }
      })
      const subgroupList: ISubGroup[] = response.data.content;
      setTotalElements(response.data.totalElements);
      setSubgroups(subgroupList);
    } catch (e) {
      console.log(e)
      return [];
    } finally {
      setIsLoading(false);
    }
  }

  /**
 * This function closes the add user modal if the form is not dirty. 
 * If the form is dirty, the confirm navigation modal is shown first.
 */
  const handleClose = () => {
    setAddGroupModal(false);
  };

  return (
    <Box sx={{ justifyItems: 'end' }}>
      <Grid item xs={10.5} md={4} sx={styles.searchStyle}>
        <Button variant='contained' color='primary' sx={{ ...styles.addButton }}
          onClick={() => { 
            setSelectedGroup(null);
            setAddGroupModal(true);
          }}
        >
          <Typography variant='button'>
            + Add Group
          </Typography>
        </Button>
      </Grid>

      <TableContainer component={Box}>
        {isLoading ?
          <LoaderTable /> :
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {
                  headerTitles.map((title, idx) => {
                    if (title === 'Actions') {
                      return (<TableCell
                        key={title}
                        sx={{
                          ...styles.tableCell,
                          ...styles.tableHeadCell,
                          textAlign: idx < 1 ? 'left' : 'center'
                        }}
                      >
                        {title}
                      </TableCell>)
                    } else {
                      return (<TableCell
                        key={title}
                        sx={{
                          ...styles.tableCell,
                          ...styles.tableHeadCell,
                          textAlign: idx < 1 ? 'left' : 'center'
                        }}
                      >
                        {title}
                      </TableCell>)
                    }
                  }
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {subgroups.map((subgroup, idx) =>
                <GroupsTableRow
                  key={subgroup.id}
                  group={subgroup}
                  idx={idx}
                  setSelectedGroup={setSelectedGroup}
                  setShowAddGroupModal={setAddGroupModal}
                  getAllGroups={getAllGroups}
                  setToasterMessage={setToasterMessage}
                  setToasterOpen={setToasterOpen}
                  setToasterSeverity={setToasterSeverity}
                />
              )}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => {
          setToasterOpen(false);
        }}
      />
      <PopUpModal
        open={addGroupModal}
        onClose={handleClose}
        isComponentEditable={false}
        isEditableTrue={true}
        title1={`${selectedGroup != null ? 'Edit' : 'Add'} Group`}
        width='500px'
      >
        <AddGroupModal
          groupDetail={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          setShowAddGroupModal={setAddGroupModal}
          setToasterMessage={setToasterMessage}
          setToasterOpen={setToasterOpen}
          setToasterSeverity={setToasterSeverity}
          getAllGroups={getAllGroups}
        />
      </PopUpModal>
    </Box>
  )
}

export default GroupsTable;